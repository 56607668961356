import React, { useEffect } from 'react';

export const RazorpayAffordabilityWidget = (value) => {
  const key = 'rzp_live_X3LkoOH3mjBza2'; // Replace it with your Test Key ID generated from the Dashboard
  let amount = Math.floor(value * 100);

  const script = document.createElement('script');
  script.src =
    'https://cdn.razorpay.com/widgets/affordability/affordability.js';
  script.async = true;

  if (amount) {
    script.onload = () => {
      const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      const widgetConfig = {
        key: key,
        amount: amount,
        theme: {
          color: '#f9e107',
        },
        display: {
          widget: {
            main: {
              link: {
                color: '#232ba1',
              },
              heading: {
                fontSize: '14px',
              },
            },
          },
        },
        width: `${screenWidth}px`,
      };

      // console.log(widgetConfig, "widgetConfig");
      const rzpAffordabilitySuite = new RazorpayAffordabilitySuite(
        widgetConfig
      );
      rzpAffordabilitySuite.render();
    };
  }

  document.head.appendChild(script);
};
