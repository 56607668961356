import React, { useState, useEffect } from 'react';
import { Image } from '@chakra-ui/react';
import { IncludeComaSeperator } from '../ProductDetailpage/pd';
import { get, post } from '../../services/api';

import ProductCardScroll from '../FilterPage/ProductCardScroll';
import ProductData from './ProductData';
import tickmark from '../../assets/sarath_IMG/tickmark.svg';
import crossmark from '../../assets/sarath_IMG/crossmark.svg';
import cartadv1 from '../../assets/sarath_IMG/Banner-1-Web21.jpg';
import { useHistory } from 'react-router-dom';
import { SignIn } from '../Login/LoginSignup';

import contactUs from '../../assets/sarath_IMG/Customer-care-banner-web-low.jpg';

export const CartNew = (props) => {
  let history = useHistory();
  const {
    cartItems,
    priceDts,
    saveItems,
    recentData,
    bestFitData,
    moveProduct,
    removeProd,
    changeQuantity,
    isOpenSuccess,
    setIsOpenSuccess,
  } = props;

  return (
    <>
      <div className="flex  w-full items-center justify-center py-7 lg:py-14 bg-white">
        <div className="px-4 md:w-full md:px-20 lg:px-24 mx-auto max-w-screen-2xl overflow-hidden">
          <p className="nxt_subheaders">
            {' '}
            {cartItems && cartItems.length === 0 ? 'CART IS EMPTY' : 'CART'}
          </p>
          <div className="ccr_divider"></div>

          {cartItems && cartItems.length === 0 ? (
            <div className="mt-28 mb-28 flex justify-center ">
              <button
                className="flex h-9 w-auto items-center rounded-lg bg-yellowB p-3 uppercase   text-blueB3   
                      shadow-bsCard focus:outline-none"
                onClick={() => history.push('/')}
              >
                EXPLORE
              </button>
            </div>
          ) : (
            <div className=" lg:flex lg:w-full lg:mb-14 lg:justify-between gap-5">
              <div
                className=" mt-7  flex flex-col gap-7 mb-8
                  lg:min-w-[528px] lg:w-[50vw] lg:max-w-[718px]
                   xl:min-w-[717px] xl:w-[56vw] xl:max-w-[918px]
                  2xl:w-[833px]
                   "
              >
                <ProductData
                  title={'CART'}
                  cartItems={cartItems}
                  change_qty={true}
                  removeProd={removeProd}
                  moveProduct={moveProduct}
                  changeQuantity={changeQuantity}
                  isOpenSuccess={isOpenSuccess}
                  setIsOpenSuccess={setIsOpenSuccess}
                />

                <div className="ccr_fontpart3 flex justify-center lg:flex lg:items-end lg:justify-end  ">
                  <button
                    className="flex items-center rounded-md bg-yellowB px-5 py-1.5 uppercase  text-blueB3 shadow-bsCard  focus:outline-none 
                      lg:justify-end lg:border lg:border-yellowB lg:bg-white  lg:px-5 font-bold"
                    type="submit"
                    onClick={() => history.push('/')}
                  >
                    BUY MORE
                  </button>
                </div>
              </div>

              <div
                className=" flex flex-col gap-3  lg:sticky lg:top-0 lg:h-max
                
                  lg:w-[27vw] lg:max-w-[400px]
                   xl:w-[27vw] xl:max-w-[442px]  2xl:w-[451px] lg:mt-7  lg:ml-auto"
              >
                <div className=" sm:flex sm:items-center sm:justify-center">
                  <div className="ccr_fontpart3  mt-2 mb-11 font-normal relative sm:w-[500px] ">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        props.CheckPromo(e.target[0].value);
                        props.checkIfAnonymous();
                      }}
                    >
                      <input
                        className="ccr_inputbox p-3 font-normal drop-shadow-dsInput"
                        name="name"
                        type="text"
                        defaultValue={props.promocode}
                        id={'promoInput'}
                        readOnly={props.isanonymous ? false : true}
                        onClick={props.checkIfAnonymous}
                        onChange={(e) => {
                          props.setpromoError('');
                        }}
                        placeholder="Enter Promocode"
                      />
                      <div className="absolute top-1.5 right-3 flex items-center">
                        {/* <img src="/assets/Common/tickmark.svg" alt="" /> */}
                        {props?.valid && (
                          <Image width={18} height={15} src={tickmark} alt="" />
                        )}
                        {props?.promoError ? (
                          <Image
                            className="mr-4"
                            width={15}
                            height={15}
                            src={crossmark}
                            alt=""
                            onClick={(e) => {
                              e.preventDefault();
                              props.CheckPromo('', 'removed'),
                                (document.getElementById('promoInput').value =
                                  '');
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {props.promoError == 'Promocode Applied' ? (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              props.CheckPromo('', 'removed'),
                                (document.getElementById('promoInput').value =
                                  '');
                            }}
                            className=" flex  items-center rounded-lg bg-yellowB p-1.5 px-4 font-bold uppercase   
                       text-blueB3 shadow-bsCard focus:outline-none lg:px-3 xl:px-3.5"
                            type="submit"
                          >
                            REMOVE
                          </button>
                        ) : (
                          <button
                            className=" flex  items-center rounded-lg bg-yellowB p-1.5 px-4 font-bold uppercase   
                       text-blueB3 shadow-bsCard focus:outline-none lg:px-3 xl:px-3.5"
                            type="submit"
                          >
                            CHECK
                          </button>
                        )}
                      </div>
                      <div>
                        <div
                          style={{ position: 'absolute', marginTop: '-4px' }}
                        >
                          {props.promoError == 'Promocode Applied' ? (
                            <span style={{ fontSize: '14px', color: 'green' }}>
                              {' '}
                              **{props.promoError}
                            </span>
                          ) : props.promoError ? (
                            <span style={{ fontSize: '14px', color: 'red' }}>
                              {' '}
                              **{props.promoError}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <p className="nxt_subheaders">PRICE DETAILS</p>
                <div className="block lg:hidden">
                  <div className="ccr_divider"></div>
                </div>
                <div className="cne_border mb-11  p-4 lg:mt-2  add_font18">
                  <div className="grid gap-y-3.5 md:text-xl">
                    <div className="flex justify-between">
                      <p>Total Items</p> <p>{priceDts.total_items}</p>
                    </div>
                    <div className="flex justify-between">
                      <p>Total Price</p>{' '}
                      <p>{IncludeComaSeperator(priceDts?.mrp_total)}</p>
                    </div>
                    <div className="flex justify-between">
                      <p>Discount (-)</p>{' '}
                      {/* <p>-{IncludeComaSeperator(priceDts?.discount)}</p> */}
                      <p>
                        {priceDts?.discount == 0
                          ? '0'
                          : `-${IncludeComaSeperator(priceDts?.discount)}`}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p>Delivery Charges (+)</p>{' '}
                      <p>{priceDts?.delivery_charge}</p>
                    </div>
                    <div className="flex justify-between">
                      <p>Delivery Discount(-)</p>{' '}
                      <p>{priceDts?.delivery_discount}</p>
                    </div>
                    <div className="flex justify-between">
                      <p>Promo Discount (-)</p>

                      <p className=" flex gap-7 lg:gap-3">
                        {priceDts?.promo_discount != 0 && (
                          <button
                            className="cne_fontpart3 flex h-6 items-center rounded-brBs  bg-greyB p-3 uppercase   
                       text-blueB3  focus:outline-none lg:py-1 lg:px-3 "
                            onClick={(e) => {
                              e.preventDefault();
                              props.CheckPromo('', 'removed'),
                                (document.getElementById('promoInput').value =
                                  '');
                            }}
                          >
                            REMOVE
                          </button>
                        )}
                        <p>
                          {priceDts?.promo_discount == 0
                            ? '0'
                            : `-${priceDts?.promo_discount}`}
                        </p>
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p>Total Discount (-)</p>{' '}
                      <p>-{IncludeComaSeperator(priceDts?.total_discount)}</p>
                    </div>

                    <div className="flex justify-between  font-bold md:text-2xl add_font20">
                      <p>Total Payable</p>{' '}
                      <p className="font-bold">
                        {IncludeComaSeperator(priceDts?.total)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="hidden lg:block">
                    <button
                      className=" w-full add_font20 large_button"
                      id="BeginCheckout"
                      onClick={() => props.updatePrice()}
                      style={{ cursor: 'pointer' }}
                    >
                      <p className="lg:font-bold"> SELECT ADDRESS</p>
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <p className="nxt_subheaders">
            {saveItems && saveItems.length === 0
              ? 'NO ITEMS SAVED FOR LATER'
              : 'SAVED FOR LATER'}
          </p>
          <div className="ccr_divider"></div>
          {saveItems && saveItems.length === 0 ? (
            <div className="mt-28 mb-28 flex justify-center">
              <button
                className="flex h-9 w-28 items-center justify-center rounded-lg bg-yellowB p-3 uppercase text-blueB3 shadow-bsCard focus:outline-none"
                onClick={() => history.push('/')}
              >
                EXPLORE
              </button>
            </div>
          ) : (
            <div className="mb-11 mt-7 flex flex-col gap-7 lg:px-10">
              <ProductData
                title={'SAVED FOR LATER'}
                type={'cart'}
                cartItems={saveItems}
                moveProduct={moveProduct}
                change_qty={true}
                removeProd={removeProd}
                changeQuantity={changeQuantity}
                isOpenSuccess={isOpenSuccess}
                setIsOpenSuccess={setIsOpenSuccess}
              />
            </div>
          )}
          <div
            className=" my-6 sm:my-16 block lg:hidden relative "
            onClick={() => history.push('/customercare')}
          >
            <Image className="w-full rounded-brB" src={contactUs} alt="" />
          </div>

          <div className="my-20 hidden lg:block">
            <Image className="w-full rounded-brB" src={cartadv1} alt="" />
          </div>
          {bestFitData.length != 0 && (
            <ProductCardScroll
              title={'BEST FIT FOR ITEMS IN CART AND SAVED LIST'}
              reViewed={bestFitData}
            />
          )}

          <ProductCardScroll title={'RECENTLY VIEWED'} reViewed={recentData} />

          <div className="my-16 hidden lg:block relative">
            <Image className="w-full rounded-brB" src={contactUs} alt="" />
            <button
              className="hpbtnAdDark absolute bottom-10 left-[32%] bg-black border-2 border-black text-white 
               hover:bg-transparent hover:text-black rounded-full cursor-pointer"
              onClick={() => history.push('/customercare')}
            >
              Contact Us
            </button>
          </div>
        </div>

        <div
          className="cne_fontpart2 visible fixed bottom-0 flex h-16 w-full items-center justify-center gap-2 bg-yellowB font-bold lg:invisible"
          onClick={() => props.updatePrice()}
        >
          {' '}
          <p className="font-bold"> SELECT ADDRESS</p>
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            ></path>
          </svg>
        </div>
      </div>

      <SignIn isOpen={props.isSignIn} onClose={props.closeSignIn} />
    </>
  );
};
