import React, { useEffect } from 'react';

import './LoginSignup.css';
import './styleguide.css';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../../services/api';
import { useHistory } from 'react-router-dom';

import { BiCheckCircle } from 'react-icons/bi';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { post } from '../../../services/api';
import { useState } from 'react';

import Timer from './timer';

import { useSelector, useDispatch } from 'react-redux';
import { setAccess, setToken, setUser } from '../../../stateManagement/user/userSlice';

export const LoginSignup = (props) => {
  const {
    welcomeBack,
    loremIpsumDolorSi,
    spanText1,
    spanText2,
    inputType1,
    inputPlaceholder1,
    inputType2,
    inputPlaceholder2,
    forgot,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    logIn,
    line18,
    or,
    requestOtp,
    newToBrakeupCrea,
    group31896Props,
  } = props;

  const { register, setValue, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });

  // let { token } = useParams();

  const [showError, setShowError] = useState(false);

  const [errMsg, setErrMsg] = useState('Wrong Credentials.');

  let history = useHistory();

  const dispatch = useDispatch();

  const onSubmit = (values, type) => {
    if (type == 'save') {
      if (values.hasOwnProperty('password') == false) {
        setErrMsg('Incorrect Password');
        setShowError(true);
        return;
      }
      values.type = 'Buyer';
      const token = JSON.parse(localStorage.getItem('token') || '{}');
      if (token?.data?.credAuthResult?.type == 'anonymous') {
        values.id = token?.data?.credAuthResult?.id;
      }
      post('/api/v1/user/login/', values)
        .then((res) => {
          setShowError(false);
          localStorage.setItem('token', JSON.stringify(res));
          // dispatch(setAccess(true));
          // dispatch(setToken(res));
          // dispatch(setUser({ name: res.data?.credAuthResult.name, mobile: res.data?.credAuthResult.mobile, token: res }));

          setTimeout(() => {
            props.onClose();
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          setErrMsg(err?.response?.data?.data?.message);
          setShowError(true);
        });
    } else {
      post('/api/v1/buyer/sendotplogin/', { text: values.text })
        .then((res) => {
          props.otpNav(values);
        })
        .catch((err) => {
          setShowError(true);
          setErrMsg(err?.response?.data?.data?.message);
        });
    }
  };
  useEffect(() => {
    setValue('text');
  }, []);

  const textInput = watch('text');
  const validatePhone = (number) => {
    // Phone number validations
    if (isNaN(number)) {
      var re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      return re.test(String(number).toLowerCase());
    } else {
      var re = /^[6-9]\d{9}$/;
      return re.test(number);
    }
  };
  const FlagImg = () => {
    if (!textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    }
    if (textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    } else if (textInput && errors && errors.text && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    } else {
      props.setPhone(textInput);
      return { color: '#38A169' };
    }
  };
  return (
    <div className='Loverlap-group'>
      <div className='Loverlap-group2'>
        <div className='Lwelcome-back' style={{ marginTop: '40px' }}>
          {welcomeBack}
        </div>
        <p className='Llorem-ipsum-dolor-si'>{loremIpsumDolorSi}</p>

        <lottie-player
          src={API_URL + '/api/v1/json/Jumping_Van.json'}
          style={{ mixBlendMode: 'multiply' }}
          speed='1'
          loop
          autoplay
        ></lottie-player>
      </div>

      <div className='Loverlap-group8'>
        <form>
          <div className='Lflex-col' style={{ marginTop: '30px' }}>
            <p className='Lsign-in-to-your-account' style={{ marginLeft: '51px' }}>
              <span className='manrope-normal-shark-14px'>{spanText1}</span>
              <span className='manrope-normal-shark-14px'>{spanText2}</span>
            </p>
            <div className='Loverlap-group1'>
              <input
                ref={register({
                  required: true,
                  validate: validatePhone,
                })}
                name='text'
                className='Lenter nunitosans-normal-silver-chalice-9px'
                placeholder={inputPlaceholder1}
                type='text'
                style={{ color: 'black' }}
              />
              <BiCheckCircle className='verifyicon' fontSize='18px' style={FlagImg()} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '216px',
              }}
            >
              <div style={{ height: '12px', fontSize: '10px' }}>
                {errors && errors.text && errors.text.type == 'required' && (
                  <span style={{ color: 'red' }}>Mobile-Email is required.</span>
                )}
                {errors && errors.text && errors.text.type == 'validate' && (
                  <span style={{ color: 'red' }}>Invalid input.</span>
                )}
              </div>
            </div>
            <div className='Loverlap-group3'>
              <input
                ref={register({
                  required: false,
                })}
                className='Lenter  nunitosans-normal-silver-chalice-9px'
                name='password'
                placeholder={inputPlaceholder2}
                type={inputType2}
                style={{ color: 'black', width: '155px' }}
              />
              <div className='Lforgot' onClick={() => props.setPage(5)}>
                {forgot}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '216px',
              }}
            >
              <div style={{ height: '12px', fontSize: '10px' }}>
                {errors && errors.password && errors.password.type == 'required' && (
                  <span style={{ color: 'red' }}>Password is required.</span>
                )}
              </div>
            </div>
            <p className='Lby-continuing-you-a' style={{ lineHeight: '14px' }}>
              <span
                className='manrope-normal-silver-chalice-10px'
                style={{ letterSpacing: '1px' }}
              >
                {spanText3}
              </span>
              <span
                className='manrope-normal-shark-8px'
                style={{ letterSpacing: '1px', cursor: 'pointer' }}
                onClick={() => history.push('/termsofuse')}
              >
                {spanText4}
              </span>
              <span
                className='manrope-normal-silver-chalice-10px'
                style={{ letterSpacing: '1px' }}
              >
                {spanText5}
              </span>
              <span
                className='manrope-normal-shark-8px'
                style={{ letterSpacing: '1px', cursor: 'pointer' }}
                onClick={() => history.push('/privacypolicy')}
              >
                {spanText6}
              </span>
            </p>
            <div style={{ marginTop: '20px', height: '12px', fontSize: '10px' }}></div>
            <div
              className='Lgroup-31912'
              onClick={handleSubmit((d) => onSubmit(d, 'save'))}
            >
              <div
                className='Llog-in nunitosans-normal-white-9px'
                style={{ fontSize: '11px', lineHeight: '20px' }}
              >
                {logIn}
              </div>
            </div>
            <img className='Lline-18' src={line18} />
            <div className='Lor'>{or}</div>
            <div
              className='Lgroup-31913'
              onClick={handleSubmit((d) => onSubmit(d, 'otp'))}
            >
              <div
                className='Lrequest-otp nunitosans-normal-white-9px'
                style={{ fontSize: '11px' }}
              >
                {requestOtp}
              </div>
            </div>
            <div
              style={{
                marginTop: '7px',
                height: '40px',
                fontSize: '10px',
                width: '216px',
                textAlign: 'center',
              }}
            >
              {showError && (
                <div>
                  <span style={{ color: 'red' }}>{errMsg}</span>
                </div>
              )}
            </div>
            <p className='Lnew-to-brakeup-crea' onClick={() => props.setPage(3)}>
              {newToBrakeupCrea}
            </p>
            <input
              style={{ display: 'none' }}
              id='createAccountWeb'
              onClick={() => props.setPage(3)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export const LoginOtp = (props) => {
  const {
    welcomeBack,
    loremIpsumDolorSi,
    spanText1,
    spanText2,
    inputType1,
    inputPlaceholder1,
    inputType2,
    inputPlaceholder2,
    forgot,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    logIn,
    line18,
    or,
    requestOtp,
    newToBrakeupCrea,
    group31896Props,
  } = props;

  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });

  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState('Wrong Credentials.');

  const [trigger, setTrigger] = useState(true);

  const onSubmit = (values, type) => {
    values.type = 'Buyer';
    values.text = props?.otpData?.text;
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    if (token?.data?.credAuthResult?.type == 'anonymous') {
      values.id = token?.data?.credAuthResult?.id;
    }
    post('/api/v1/buyer/otplogin/', values)
      .then((res) => {
        setShowError(false);
        localStorage.setItem('token', JSON.stringify(res));
        // dispatch(setAccess(true));
        // dispatch(setToken(res));
        // dispatch(setUser({ name: res.data?.credAuthResult.name, mobile: res.data?.credAuthResult.mobile, token: res }));
        setTimeout(() => {
          props.onClose();
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };
  const resendOtp = () => {
    post('/api/v1/buyer/sendotplogin/', props.otpData)
      .then((res) => {
        setShowError(false);
        setTrigger(true);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };
  const enableBtn = () => {
    setTrigger(false);
  };
  return (
    <div className='Loverlap-group'>
      <div className='Loverlap-group2'>
        <div className='Lwelcome-back'>{welcomeBack}</div>
        <p className='Llorem-ipsum-dolor-si'>{loremIpsumDolorSi}</p>

        <lottie-player
          src={API_URL + '/api/v1/json/Calm_Woman_OTP.json'}
          style={{ mixBlendMode: 'multiply' }}
          speed='1'
          loop
          autoplay
        ></lottie-player>
      </div>
      <div className='Loverlap-group8' style={{ justifyContent: 'flex-end' }}>
        <form>
          <div className='Lflex-col ' style={{ minHeight: '320px' }}>
            <div className='Lor' style={{ fontSize: '11px' }}>
              Please enter the OTP sent to{' '}
              <span style={{ color: 'black' }}> {props?.otpData?.text}</span>{' '}
            </div>
            <div className='Loverlap-group3'>
              <input
                ref={register({
                  required: true,
                })}
                className='Lenter nunitosans-normal-silver-chalice-9px'
                name='otp'
                placeholder={'Enter OTP'}
                type={'number'}
                style={{ color: 'black' }}
                maxlength='4'
              />
            </div>
            <div
              style={{
                height: '12px',
                fontSize: '10px',
                textAlign: 'left',
                width: '216px',
              }}
            >
              {/* {showError && <div >
                            <span style={{ color: "red" }}>Otp is required.</span>
                        </div>} */}
            </div>

            <Button
              style={{
                fontSize: '10px',
                height: '29px',
                marginTop: '20px',
                borderRadius: '5px',
              }}
              bgColor={trigger ? '#626262' : '#414bdb'}
              disabled={trigger ? true : false}
              cursor={trigger ? 'not-allowed' : 'pointer'}
              _hover={'none'}
              onClick={() => resendOtp()}
            >
              <div
                className='Llog-in nunitosans-normal-white-9px'
                style={{ fontSize: '11px', lineHeight: '14px' }}
              >
                Resend OTP
              </div>
            </Button>
            <div className='timer'>
              <Timer initialSeconds={5} enableBtn={enableBtn} trigger={trigger} />
            </div>

            <div style={{ height: '12px', marginTop: '30px', fontSize: '10px' }}>
              {errors && errors.otp && errors.otp.type == 'required' && (
                <span style={{ color: 'red' }}>Wrong Credentials.</span>
              )}

              {showError && <span style={{ color: 'red' }}>{errMsg}</span>}
            </div>
            <div
              className='Lgroup-31913'
              style={{ marginTop: '30px' }}
              onClick={handleSubmit((d) => onSubmit(d, 'otp'))}
            >
              <div
                className='Llog-in nunitosans-normal-white-9px'
                style={{ marginRight: '14px', fontSize: '11px' }}
              >
                Login
              </div>
            </div>
            <p className='Lnew-to-brakeup-crea' onClick={() => props.setPage(3)}>
              {newToBrakeupCrea}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

const SinForm1 = (props) => {
  const {
    inputPlaceholder1,
    inputType2,
    inputPlaceholder2,
    forgot,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    logIn,
    line18,
    or,
    requestOtp,
    newToBrakeupCrea,
    group31896Props,
  } = props;
  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });
  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState('Wrong Credentials.');
  const [trigger, setTrigger] = useState(false);
  const [status, setStatus] = useState(1);

  const textInput = watch('text');
  const validatePhone = (number) => {
    // Phone number validations
    var re = /^[6-9]\d{9}$/;
    return re.test(number);
  };

  const FlagImg = () => {
    if (!textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    }
    if (textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    } else if (textInput && errors && errors.text && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    } else {
      props.setPhone(textInput);
      return { color: '#38A169' };
    }
  };
  useEffect(() => {
    if (validatePhone(textInput) == true) {
      setStatus(1);
      setTrigger(false);
    } else {
      props.setEnable(false);
    }
  }, [textInput]);
  const resendOtp = () => {
    if (validatePhone(textInput) == false) {
      setShowError(true);
      return;
    }
    let input = {
      text: textInput,
      type: 'OTPRegistration',
    };
    post('/api/v1/buyer/resendotp/', input)
      .then((res) => {
        setShowError(false);
        props.setPhone(textInput);
        setTrigger(true);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };

  const onSubmit = (values) => {
    let input = {
      mobile: values.text,
    };
    post('/api/v1/buyer/mobile_register/', input)
      .then((res) => {
        setShowError(false);
        props.setPhone(textInput);
        setStatus(2);
        setTrigger(true);
        props.setEnable(true);

        // let data = {
        //     "mobile": textInput,
        //     "userId": res.data.userId
        // }
        // setTimeout(() => {
        //     post("/api/v1/buyer/promo_msg/", data).then(res => {
        //         console.log("prommomsg succesfull");
        //     }).catch(err => {
        //     })
        // }, 15000);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };
  const enableBtn = () => {
    setTrigger(false);
  };
  const ErrorLabel = () => {
    if (status == 1) {
      return 'Continue';
    } else if (status == 2) {
      return 'Resend OTP';
    }
  };
  return (
    <>
      <form>
        <p className='Lsign-in-to-your-account'>
          <span className='manrope-normal-shark-14px'>Create New Account</span>
        </p>
        <div className='Loverlap-group1'>
          <input
            ref={register({
              required: true,
              validate: validatePhone,
            })}
            name='text'
            className='Lenter nunitosans-normal-silver-chalice-9px'
            placeholder={'Enter Mobile number'}
            type={'number'}
            style={{ color: 'black' }}
          />
          <BiCheckCircle className='verifyicon' fontSize='18px' style={FlagImg()} />
        </div>

        <div style={{ height: '10px', fontSize: '10px' }}>
          {errors && errors.text && errors.text.type == 'required' && (
            <span style={{ color: 'red' }}>Mobile is required.</span>
          )}
          {errors && errors.text && errors.text.type == 'validate' && (
            <span style={{ color: 'red' }}>Invalid input.</span>
          )}
        </div>
        <div style={{ height: '50px', position: 'relative' }}>
          <Button
            style={{
              fontSize: '10px',
              height: '29px',
              borderRadius: '5px',
              position: 'absolute',
              top: '20%',
              margin: '0px',
              marginLeft: '30%',
            }}
            bgColor={trigger ? '#626262' : '#414bdb'}
            cursor={trigger ? 'not-allowed' : 'pointer'}
            disabled={trigger ? true : false}
            _hover={'none'}
            onClick={handleSubmit((d) => {
              if (status == 1) {
                onSubmit(d);
              } else if (status == 2) {
                resendOtp();
              }
            })}
          >
            <div
              className='Llog-in nunitosans-normal-white-9px'
              style={{ fontSize: '11px', lineHeight: '14px' }}
            >
              {ErrorLabel()}
            </div>
          </Button>
          {status == 2 && (
            <div
              style={{
                float: 'right',
                marginTop: '10%',
                marginRight: '3px',
              }}
              className='timer-forgot'
            >
              <Timer initialSeconds={5} enableBtn={enableBtn} trigger={trigger} />
            </div>
          )}
        </div>
        {showError && (
          <div
            style={{
              height: '12px',
              fontSize: '10px',
              position: 'relative',
              width: '216px',
              textAlign: 'center',
            }}
          >
            {
              <div style={{ fontSize: '10px' }}>
                <span style={{ color: 'red' }}>{errMsg}</span>
              </div>
            }
          </div>
        )}
      </form>
      {status == 2 && <div className='Lor'>OTP sent to {textInput}</div>}
    </>
  );
};
export const SinForm2 = (props) => {
  const {
    welcomeBack,
    loremIpsumDolorSi,
    spanText1,
    spanText2,
    inputType1,
    inputPlaceholder1,
    inputType2,
    inputPlaceholder2,
    forgot,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    logIn,
    line18,
    or,
    requestOtp,
    newToBrakeupCrea,
    group31896Props,
  } = props;

  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });

  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState('Wrong Credentials.');

  const onSubmit = (values, type) => {
    if (!props.phone) {
      setShowError(true);
      return;
    }
    values.mobile = props.phone;
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    if (token?.data?.credAuthResult?.type == 'anonymous') {
      values.id = token?.data?.credAuthResult?.id;
    }
    post('/api/v1/buyer/signup/', values)
      .then((res) => {
        setShowError(false);
        localStorage.setItem('token', JSON.stringify(res));
        // dispatch(setAccess(true));
        // dispatch(setToken(res));
        // dispatch(setUser({ name: res.data?.credAuthResult.name, mobile: res.data?.credAuthResult.mobile, token: res }));
        props.continueToFinalPage(props.phone);
        // window.location.reload();
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };
  return (
    <form>
      <div className='Loverlap-group3'>
        <input
          ref={register({
            required: true,
          })}
          className='Lenter nunitosans-normal-silver-chalice-9px'
          name='otp'
          placeholder={'Enter OTP'}
          type={'number'}
          style={{ color: 'black' }}
          autoComplete='new-otp'
          defaultValue={''}
        />
      </div>
      <div style={{ height: '12px', fontSize: '10px' }}>
        {errors && errors.otp && errors.otp.type == 'required' && (
          <span style={{ color: 'red' }}>OTP is required.</span>
        )}
      </div>
      <div className='Loverlap-group3'>
        <input
          ref={register({
            required: true,
          })}
          className='Lenter nunitosans-normal-silver-chalice-9px'
          name='password'
          placeholder={inputPlaceholder2}
          type={inputType2}
          style={{ color: 'black' }}
          autoComplete='new-password'
          defaultValue={''}
        />
      </div>
      <div style={{ height: '12px', fontSize: '10px' }}>
        {errors && errors.password && errors.password.type == 'required' && (
          <span style={{ color: 'red' }}>Password is required.</span>
        )}
      </div>
      <div
        style={{
          height: '24px',
          marginTop: '10px',
          fontSize: '10px',
          textAlign: 'center',
          width: '216px',
        }}
      >
        {showError && (
          <div style={{ fontSize: '10px', textAlign: 'center' }}>
            <span style={{ color: 'red' }}>{errMsg}</span>
          </div>
        )}
      </div>
      <Button
        colorScheme='blue'
        variant='solid'
        className='btn-css'
        style={{ marginRight: '67px', marginTop: '10px' }}
        onClick={handleSubmit((d) => onSubmit(d, 'save'))}
      >
        Continue
      </Button>
    </form>
  );
};
export const SignUp = (props) => {
  const { welcomeBack, welcomeToBrakeup, loremIpsumDolorSi } = props;

  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });
  const [phone, setPhone] = useState('');
  const [enable, setEnable] = useState(false);
  return (
    <div className='Loverlap-group'>
      <div className='Loverlap-group2'>
        <div className='Lwelcome-back'>{welcomeToBrakeup}</div>
        <p className='Llorem-ipsum-dolor-sir'>{loremIpsumDolorSi}</p>

        <lottie-player
          src={API_URL + '/api/v1/json/Waving_Woman.json'}
          style={{
            mixBlendMode: 'multiply',
            justifyContent: 'center',
            height: '233px',
          }}
          speed='1'
          loop
          autoplay
        ></lottie-player>
      </div>
      <div className='Loverlap-group8'>
        <div className='Lflex-col' style={{ marginTop: '37px' }}>
          <SinForm1 {...props} setPhone={setPhone} setEnable={setEnable} />
          {enable && (
            <SinForm2
              {...props}
              phone={phone}
              setPage={props.setPage}
              continueToFinalPage={props.continueToFinalPage}
            />
          )}
          <p className='Lnew-to-brakeup-crea' onClick={() => props.setPage(1)}>
            Already have an account? Log in!
          </p>
        </div>
      </div>
    </div>
  );
};

export const FinalPage = (props) => {
  const {
    welcomeBack,
    loremIpsumDolorSi,
    spanText1,
    spanText2,
    inputType1,
    inputPlaceholder1,
    inputType2,
    inputPlaceholder2,
    forgot,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    logIn,
    line18,
    or,
    requestOtp,
    newToBrakeupCrea,
    group31896Props,
  } = props;

  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });
  const [showError, setShowError] = useState(false);
  const onSubmit = (values) => {
    values.mobile = props.phone;
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    if (token?.data?.credAuthResult?.type == 'anonymous') {
      values.id = token?.data?.credAuthResult?.id;
    }
    if (!props.phone) {
      setShowError(true);
      return;
    }
    post('/api/v1/buyer/buyer_account/', values)
      .then((res) => {
        localStorage.setItem('token', JSON.stringify(res));
        // dispatch(setAccess(true));
        // dispatch(setToken(res));
        // dispatch(setUser({ name: res.data?.credAuthResult.name, mobile: res.data?.credAuthResult.mobile, token: res }));
        setShowError(false);
        setTimeout(() => {
          props.onClose();
        }, 1000);
        window.location.reload();
      })
      .catch((err) => {
        setShowError(true);
      });
  };
  const validateEmail = (mail) => {
    // Email validations
    const re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return re.test(String(mail).toLowerCase());
  };
  return (
    <div className='Loverlap-group'>
      <div className='Loverlap-group2'>
        <div className='Lwelcome-back'>{welcomeBack}</div>
        <p className='Llorem-ipsum-dolor-si'>{loremIpsumDolorSi}</p>

        <lottie-player
          src={API_URL + '/api/v1/json/Waving_Woman.json'}
          style={{ mixBlendMode: 'multiply', height: '233px' }}
          speed='1'
          loop
          autoplay
        ></lottie-player>
      </div>
      <div className='Loverlap-group8'>
        <div className='Lflex-col' style={{ marginTop: '30px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <span>Welcome</span>

            <span style={{ height: '30px', fontWeight: '400' }}>{props.phone} </span>
            <Button
              colorScheme='blue'
              variant='solid'
              className='btn-css'
              style={{}}
              onClick={() => {
                props.onClose(), window.location.reload();
              }}
            >
              Start Purchase
            </Button>
          </div>

          <div className='Lor' style={{ marginTop: '40px' }}>
            {or}
          </div>
          <form>
            <div className='Loverlap-group3' style={{ marginTop: '40px' }}>
              <input
                ref={register({
                  required: true,
                  validate: validateEmail,
                })}
                className='Lenter nunitosans-normal-silver-chalice-9px'
                name='email'
                placeholder={'Enter Email'}
                type={'text'}
                style={{ color: 'black' }}
              />
            </div>

            <div style={{ height: '12px', fontSize: '10px' }}>
              {errors && errors.email && errors.email.type == 'validate' && (
                <span style={{ color: 'red' }}>Invalid email.</span>
              )}
              {errors && errors.email && errors.email.type == 'required' && (
                <span style={{ color: 'red' }}>email is required.</span>
              )}
            </div>

            <div className='Loverlap-group3'>
              <input
                ref={register({
                  required: true,
                })}
                className='Lenter nunitosans-normal-silver-chalice-9px'
                name='name'
                placeholder={'Enter name'}
                type={'text'}
                style={{ color: 'black' }}
              />
              <br></br>
            </div>
            <div style={{ height: '12px', fontSize: '10px' }}>
              {errors && errors.name && (
                <span style={{ color: 'red' }}>name is required.</span>
              )}
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                colorScheme='blue'
                variant='solid'
                className='btn-css'
                style={{}}
                onClick={handleSubmit((d) => onSubmit(d, 'save'))}
              >
                Continue
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const ForgotForm1 = (props) => {
  const {
    inputPlaceholder1,
    inputType2,
    inputPlaceholder2,
    forgot,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    logIn,
    line18,
    or,
    requestOtp,
    newToBrakeupCrea,
    group31896Props,
  } = props;
  const { register, setValue, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });
  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState('Wrong Credentials.');
  const [trigger, setTrigger] = useState(false);
  const [status, setStatus] = useState(1);

  const textInput = watch('text');
  const validatePhone = (number) => {
    // Phone number validations
    var re = /^[6-9]\d{9}$/;
    return re.test(number);
  };
  const FlagImg = () => {
    if (!textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    }
    if (textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    } else if (textInput && errors && errors.text && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    } else {
      props.setPhone(textInput);
      return { color: '#38A169' };
    }
  };
  useEffect(() => {
    if (validatePhone(textInput) == true) {
      setStatus(1);
      setTrigger(false);
    } else {
      props.setEnable(false);
    }
  }, [textInput]);

  const resendOtp = () => {
    if (validatePhone(textInput) == false) {
      setShowError(true);
      return;
    }
    let input = {
      text: textInput,
      type: 'OTPForgetPassword',
    };
    post('/api/v1/buyer/resendotp/', input)
      .then((res) => {
        setShowError(false);
        setTrigger(true);
        props.setPhone(textInput);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };

  const enableBtn = () => {
    setTrigger(false);
  };

  const onSubmit = (values) => {
    let input = {
      text: values.text,
    };
    post('/api/v1/buyer/forgetpasswordotp/', input)
      .then((res) => {
        setShowError(false);
        setStatus(2);
        setTrigger(true);
        props.setPhone(textInput);
        props.setEnable(true);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };
  useEffect(() => {
    if (props.phone && validatePhone(props.phone)) {
      setValue('text', props.phone);
    }
  }, [props.phone]);

  const ErrorLabel = () => {
    if (status == 1) {
      return 'Send OTP';
    } else if (status == 2) {
      return 'Resend OTP';
    }
  };
  return (
    <>
      <form>
        <p className='Lsign-in-to-your-account'>
          <span className='manrope-normal-shark-14px'>Forgot password</span>
        </p>
        <div className='Loverlap-group1'>
          <input
            ref={register({
              required: true,
              validate: validatePhone,
            })}
            name='text'
            className='Lenter nunitosans-normal-silver-chalice-9px'
            placeholder={'Enter Mobile number'}
            type={'number'}
            style={{ color: 'black' }}
          />
          <BiCheckCircle className='verifyicon' fontSize='18px' style={FlagImg()} />
        </div>

        <div style={{ height: '12px', fontSize: '10px' }}>
          {errors && errors.text && errors.text.type == 'required' && (
            <span style={{ color: 'red' }}>Mobile is required.</span>
          )}
          {errors && errors.text && errors.text.type == 'validate' && (
            <span style={{ color: 'red' }}>Invalid input.</span>
          )}
        </div>
        <div style={{ height: '50px', position: 'relative' }}>
          <Button
            style={{
              fontSize: '10px',
              height: '29px',
              borderRadius: '5px',
              position: 'absolute',
              top: '20%',
              margin: '0px',
              marginLeft: '25%',
            }}
            bgColor={trigger ? '#626262' : '#414bdb'}
            cursor={trigger ? 'not-allowed' : 'pointer'}
            disabled={trigger ? true : false}
            _hover={'none'}
            onClick={handleSubmit((d) => {
              if (status == 1) {
                onSubmit(d);
              } else if (status == 2) {
                resendOtp();
              }
            })}
          >
            <div
              className='Llog-in nunitosans-normal-white-9px'
              style={{ fontSize: '11px', lineHeight: '14px' }}
            >
              {ErrorLabel()}
            </div>
          </Button>
          {status == 2 && (
            <div
              style={{
                float: 'right',
                marginTop: '10%',
                marginRight: '13px',
              }}
              className='timer-forgot'
            >
              <Timer initialSeconds={5} enableBtn={enableBtn} trigger={trigger} />
            </div>
          )}
        </div>
        {showError && (
          <div
            style={{
              height: '12px',
              fontSize: '10px',
              position: 'relative',
              textAlign: 'center',
            }}
          >
            {
              <div style={{ fontSize: '10px' }}>
                <span style={{ color: 'red' }}>{errMsg}</span>
              </div>
            }
          </div>
        )}
      </form>
      {status == 2 && <div className='otpsentto'>OTP sent to {textInput}</div>}
    </>
  );
};
export const ForgotForm2 = (props) => {
  const {
    welcomeBack,
    loremIpsumDolorSi,
    spanText1,
    spanText2,
    inputType1,
    inputPlaceholder1,
    inputType2,
    inputPlaceholder2,
    forgot,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    logIn,
    line18,
    or,
    requestOtp,
    newToBrakeupCrea,
    group31896Props,
  } = props;

  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });

  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState('Wrong Credentials.');

  const onSubmit = (values, type) => {
    if (!props.phone) {
      setShowError(true);
      return;
    }
    values.mobile = props.phone;
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    if (token?.data?.credAuthResult?.type == 'anonymous') {
      values.id = token?.data?.credAuthResult?.id;
    }
    post('/api/v1/buyer/resetpassword/', values)
      .then((res) => {
        localStorage.setItem('token', JSON.stringify(res));
        setShowError(false);
        // dispatch(setAccess(true));
        // dispatch(setToken(res));
        // dispatch(setUser({ name: res.data?.credAuthResult.name, mobile: res.data?.credAuthResult.mobile, token: res }));
        setTimeout(() => {
          props.onClose();
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };
  return (
    <form>
      <div className='Loverlap-group3' style={{ marginTop: '' }}>
        <input
          ref={register({
            required: true,
          })}
          className='Lenter nunitosans-normal-silver-chalice-9px'
          name='otp'
          placeholder={'Enter OTP'}
          type={'number'}
          style={{ color: 'black' }}
          autoComplete='new-otp'
        />
        <br></br>
      </div>
      <div style={{ height: '12px', fontSize: '10px' }}>
        {errors && errors.otp && errors.otp.type == 'required' && (
          <span style={{ color: 'red' }}>OTP is required.</span>
        )}
      </div>
      <div className='Loverlap-group3'>
        <input
          ref={register({
            required: true,
          })}
          className='Lenter nunitosans-normal-silver-chalice-9px'
          name='password'
          placeholder={inputPlaceholder2}
          type={inputType2}
          style={{ color: 'black' }}
          autoComplete='new-password'
        />
        <br></br>
        <div style={{ height: '14px', marginTop: '7px', fontSize: '10px' }}>
          {errors && errors.password && errors.password.type == 'required' && (
            <span style={{ color: 'red' }}>Password is required.</span>
          )}
        </div>
      </div>

      <div
        style={{
          height: '30px',
          marginTop: '20px',
          fontSize: '10px',
          textAlign: 'center',
          width: '216px',
        }}
      >
        {showError && (
          <div style={{ fontSize: '10px' }}>
            <span style={{ color: 'red' }}>{errMsg}</span>
          </div>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          colorScheme='blue'
          variant='solid'
          className='btn-css'
          onClick={handleSubmit((d) => onSubmit(d, 'save'))}
        >
          Continue
        </Button>
      </div>
    </form>
  );
};
export const ForGotPage = (props) => {
  const { welcomeBack, loremIpsumDolorSi } = props;

  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });
  const [phone, setPhone] = useState('');
  const [enable, setEnable] = useState(false);
  useEffect(() => {
    setPhone(props.phone);
  }, [props.phone]);

  return (
    <div className='Loverlap-group'>
      <div className='Loverlap-group2'>
        <div className='Lwelcome-back'>{welcomeBack}</div>
        <p className='Llorem-ipsum-dolor-si'>{loremIpsumDolorSi}</p>

        <lottie-player
          src={API_URL + '/api/v1/json/Gear_Drive.json'}
          style={{ mixBlendMode: 'multiply' }}
          speed='1'
          loop
          autoplay
        ></lottie-player>
      </div>
      <div className='Loverlap-group8'>
        <div className='Lflex-col' style={{ marginTop: '40px' }}>
          <ForgotForm1
            {...props}
            setPhone={setPhone}
            phone={phone}
            setEnable={setEnable}
          />
          {enable && <ForgotForm2 {...props} phone={phone} setPage={props.setPage} />}
          <p className='signuplogin'>
            <span className='signuploginhover' onClick={() => props.setPage(3)}>
              {' '}
              Signup{' '}
            </span>{' '}
            <span className='dot'></span>{' '}
            <span className='signuploginhover' onClick={() => props.setPage(1)}>
              Login{' '}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
