import { Button, Image, Input, useNumberInput } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';

import { useHistory } from 'react-router';
import Successmodal from '../../components/Modals/Successmodal';
import Notifymodal from '../../components/Modals/Notifymodal';
import Removemodal from '../../components/Modals/Removemodal';
import closemark from '../../assets/sarath_IMG/x.svg';
import { FixedtoZero, IncludeComaSeperator } from '../ProductDetailpage/pd';

function ProductData(props) {
  let history = useHistory();
  const { moveProduct, saveItems, removeProd, isOpenSuccess, setIsOpenSuccess, changeQuantity } =
    props;

  const [isOpenSuccess1, setIsOpenSuccess1] = useState(false);
  const [isOpenRemove, setIsOpenRemove] = useState(false);
  const [isOpenNotify, setIsOpenNotify] = useState(false);

  const [item, setItem] = useState({});

  const openDeleteWindow = (data) => {
    setItem(data);
    setIsOpenRemove(true);
  };

  const deleteProduct = () => {
    let type = '';
    if (props.title == 'CART') {
      type = 'cart';
    } else if (props.title == 'SAVED FOR LATER') {
      type = 'save';
    }
    removeProd(item, type);
    setIsOpenRemove(false);
  };

  const moveTo = () => {
    setIsOpenRemove(false);
    moveProduct(item, 'cart');
  };

  const ToProductDetailPage = (item) => {
    history.push(
      `/product/${item?.prod_id}${
        item.vehicle_details?.make_id
          ? '/' +
            item.vehicle_details?.make_id +
            '/' +
            item.vehicle_details?.model_id +
            '/' +
            item.vehicle_details?.year +
            (item.vehicle_details?.version ? '/' + item.vehicle_details?.version : '') +
            (item.vehicle_details?.fueltype ? '/' + item.vehicle_details?.fueltype : '')
          : ''
      }${item.vehicle_details?.type ? '/' + item.vehicle_details?.type : ''}`
    );
  };

  const Quantity = (props) => {
    const [value, setValue] = useState(props?.item?.quantity);

    //quantity select

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
      step: 1,
      defaultValue: value,
      min: 1,
      max: props?.item.category_quantity,
      onChange: (valueAsNumber) => handleChange(valueAsNumber),
    });

    const inc = getIncrementButtonProps();
    const dec = getDecrementButtonProps();
    const input = getInputProps({
      readOnly: true,
    });

    const handleChange = (quantity) => {
      let type = '';
      if (props.title == 'CART') {
        type = 'cart';
      } else if (props.title == 'SAVED FOR LATER') {
        type = 'save';
      }
      setValue(quantity);
      // console.log(props?.item, quantity, type);
      changeQuantity(props?.item, quantity, type);
    };
    const handleInputFocus = (e) => {
      e.preventDefault();
    };

    console.log(props.title);

    return (
      <>
        <button
          className='cne_quantitybutton'
          {...dec}>
          -
        </button>
        <div className='ml-1 ccr_fontpart3 '> QTY</div>
        <input
          className='cne_quantity'
          readOnly
          {...input}
          onFocus={handleInputFocus}
        />

        <button
          className='cne_quantitybutton'
          {...inc}>
          +
        </button>
      </>
    );
  };

  return (
    <div className='grid grid-flow-row auto-rows-max gap-7'>
      {props.cartItems &&
        props.cartItems.map((item, index) => (
          <div
            key={index}
            className='cne_border flex  px-3.5 pt-3 pb-4 md:col-start-1 relative '>
            <div className='items-start row-span-1 mt-2'>
              <Image
                className={`${
                  props.title !== 'ORDER SUMMARY' && props.title !== 'DELIVERY DETAILS'
                    ? 'cursor-pointer'
                    : ''
                } w-[5.40vw] max-w-[76px] min-w-[73px]  md:w-[5.62vw] md:min-w-[76px] md:max-w-[80px]`}
                onClick={() => {
                  if (
                    props.title == 'ORDER STATUS' ||
                    props.title == 'ORDER NOT PLACED' ||
                    props.title == 'CART' ||
                    props.title == 'SAVED FOR LATER'
                  ) {
                    ToProductDetailPage(item);
                  } else {
                    console.log(props.title, 'fbhskdjd');
                  }
                }}
                src={item?.image[0]}
                alt=''
                style={{ objectFit: 'cover' }}
              />
            </div>

            <div className=' ml-3.5  md:ml-5 mt-0.5 w-full h-full gap-1'>
              <div className='flex flex-col gap-1'>
                <p className='mr-8 uppercase ccr_fontpart2'>
                  <span
                    className={`${
                      props.title === 'ORDER STATUS' ||
                      props.title == 'ORDER NOT PLACED' ||
                      props.title === 'CART' ||
                      props.title === 'SAVED FOR LATER'
                        ? 'cursor-pointer'
                        : ''
                    }`}
                    onClick={() => {
                      if (
                        props.title === 'ORDER STATUS' ||
                        props.title == 'ORDER NOT PLACED' ||
                        props.title === 'CART' ||
                        props.title === 'SAVED FOR LATER'
                      ) {
                        ToProductDetailPage(item);
                      }
                    }}>
                    {item?.brand} {item?.prod_name}
                  </span>
                </p>

                {item?.vehicle_details?.make ? (
                  <div className='flex items-center gap-2 font-normal uppercase ccr_fontpart3 min'>
                    {item?.vehicle_details?.logo && (
                      <Image
                        src={item?.vehicle_details?.logo}
                        alt=''
                        width={30}
                        height={30}
                        onClick={() => {
                          if (
                            props.title === 'ORDER STATUS' ||
                            props.title === 'CART' ||
                            props.title === 'SAVED FOR LATER'
                          ) {
                            ToProductDetailPage(item);
                          }
                        }}
                        style={{
                          cursor:
                            props.title === 'ORDER STATUS' ||
                            props.title === 'CART' ||
                            props.title === 'SAVED FOR LATER'
                              ? 'pointer'
                              : 'auto',
                        }}
                      />
                    )}
                    <span
                      onClick={() => {
                        if (
                          props.title === 'ORDER STATUS' ||
                          props.title === 'CART' ||
                          props.title === 'SAVED FOR LATER'
                        ) {
                          ToProductDetailPage(item);
                        }
                      }}
                      style={{
                        cursor:
                          props.title === 'ORDER STATUS' ||
                          props.title === 'CART' ||
                          props.title === 'SAVED FOR LATER'
                            ? 'pointer'
                            : 'auto',
                      }}>
                      {' '}
                      <span>
                        {/* {item?.vehicle_details?.make}{' '} */}
                        {item?.vehicle_details?.model} {item?.vehicle_details?.year}{' '}
                      </span>
                      <span className='whitespace-nowrap'>
                        {' '}
                        {item?.vehicle_details?.version} {item?.vehicle_details?.fueltype}{' '}
                        {item?.vehicle_details?.axle}
                      </span>
                    </span>
                  </div>
                ) : (
                  <div className='h-3'></div>
                )}

                {props.change_qty && (
                  <div className='absolute inset-y-2 right-4 text-greyB3 '>
                    <button
                      autoFocus={false}
                      className='opacity-50 md:hover:opacity-100'
                      onClick={() => openDeleteWindow(item)}>
                      <Image
                        src={closemark}
                        alt=''
                        color='greyB5'
                        layout='responsive'
                        //  width={30}
                        //  height={25}
                        className='w-25 md:w-30 h-25'
                      />
                    </button>
                  </div>
                )}
              </div>

              <div className='flex justify-between mt-1 add_font12 '>
                {props.change_qty && item?.stock_status == 'instock' ? (
                  <div className='ccr_fontpart3 '>
                    {/* <div className='flex  items-center gap-1.5 '> */}
                    {/* <div className='ccr_fontpart3 '>
                          <p className=''>In Stock</p>
                        </div> */}
                    <div className='flex items-center  gap-0.5'>
                      <Quantity
                        item={item}
                        title={props.title}
                      />
                    </div>
                    {/* </div> */}
                    <div className=''>
                      <div
                        className={`mt-2 opacity-75 ${
                          props.title == 'SAVED FOR LATER' ? 'hidden ' : 'block'
                        }`}>
                        <span>Expected Delivery:</span>{' '}
                        <span className='whitespace-nowrap'>{item?.delivery_date}</span>
                      </div>

                      <div
                        className={`ccr_fontpart3 absolute left-3.5 bottom-3   px-3.5 md:px-7.5  ${
                          props.title == 'SAVED FOR LATER' ? 'block  mt-5 ' : 'hidden'
                        } md:left-1/2 md:bottom-7 `}>
                        <button
                          className='flex items-center justify-center p-3 py-2 font-bold uppercase border rounded-brBs border-yellowB bg-none text-blueB3 shadow-bsCard3 focus:outline-none md:px-2 lg:px-3'
                          type='submit'
                          onClick={() => {
                            moveProduct(item, 'save');
                          }}>
                          MOVE TO CART
                        </button>
                      </div>
                    </div>
                  </div>
                ) : item?.stock_status == 'instock' ? (
                  <div>
                    <p className='add_font12'>ORDER QTY {item.quantity}</p>
                    <div className='flex items-center gap-1 mt-1'>In Stock</div>
                  </div>
                ) : (
                  <div>
                    <span className='ccr_fontpart3 '>
                      <p className=' text-redB'>Out of stock</p>
                    </span>
                    {/* <div
                        className={`ccr_fontpart3 absolute left-3.5 bottom-1 px-3.5 md:px-7.5  ${
                          props.title == 'SAVED FOR LATER' ? 'block' : 'hidden'
                        } md:left-1/2 md:bottom-3 `}
                      >
                        <button
                          className='flex items-center justify-center rounded-brBs border border-yellowB bg-none px-6 py-1.5 uppercase tracking-wide   
                      text-blueB3 shadow-bsCard3  focus:outline-none font-bold mb-3.5 md:mb-3'
                          type='submit'
                          onClick={() => setIsOpenNotify(true)}
                        >
                          NOTIFY ME
                        </button>
                      </div> */}
                  </div>
                )}

                <div className='flex flex-col items-end justify-end text-right '>
                  <div className={` ${props.change_qty && 'mt-0'} price_font14-18 `}>
                    {item?.stock_status === 'instock' ? (
                      <div
                        className={` md:mt-1.5 ${
                          props.title === 'SAVED FOR LATER' ? ' mt-10 md:mb-0' : ''
                        }`}>
                        <div className='flex flex-col text-right whitespace-nowrap'>
                          <div className='text-right '>
                            {FixedtoZero(item?.discount_percentage) && (
                              <span className='uppercase text-greenB '>
                                {FixedtoZero(item?.discount_percentage)}
                              </span>
                            )}
                            {item?.discount_price !== 0 && (
                              <span className='ml-1.5 text-right'>
                                -{IncludeComaSeperator(item?.discount_price)}
                              </span>
                            )}
                          </div>

                          <div className='text-right'>
                            {item?.order_prod_price !== item?.total_mrp ? (
                              <div>
                                <span className='mr-2 line-through text-redB'>
                                  {IncludeComaSeperator(item?.total_mrp)}
                                </span>
                                <span className='font-bold mainprice_font16-20'>
                                  {IncludeComaSeperator(item?.order_prod_price)}
                                </span>
                              </div>
                            ) : (
                              <span className='font-bold mainprice_font16-20'>
                                {IncludeComaSeperator(item?.order_prod_price)}
                              </span>
                            )}

                            {/* {item?.discount_price === 0 && item?.discount_percentage === 0 ? (
                                    <span className='font-bold mainprice_font16-20'>
                                     Rs.{IncludeComaSeperator(item?.order_prod_price)}
                                    </span>
                                  ) : ( */}

                            {/* )} */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='col-start-5 col-end-6 mt-10 text-right md:mt-2 '>
                        <p className='font-bold mainprice_font16-20 text-greyB3'>
                          Rs.{IncludeComaSeperator(item?.total_mrp)}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

      <Removemodal
        isOpen={isOpenRemove}
        saveForLater={moveTo}
        setIsOpen={setIsOpenRemove}
        message={'Remove Item?'}
        remove={deleteProduct}
        type={props.title}
      />
      <Successmodal
        isOpen={isOpenSuccess ?? isOpenSuccess1}
        setIsOpen={setIsOpenSuccess ?? setIsOpenSuccess1}
        message={'Item moved'}
      />
      <Notifymodal
        isOpen={isOpenNotify}
        setIsOpen={setIsOpenNotify}
        message={'Notify Me'}
      />
    </div>
  );
}
export default ProductData;
