import { Box, Button, Divider, Flex, Text, Image } from '@chakra-ui/react';
import React, { useRef, useEffect, useState } from 'react';
import { FaChevronRight } from "react-icons/fa";
import RecentItemDisplay from './RecentItemDisplay';

import NoRecentAdWeb from "../../assets/sarath_IMG/Recently viewed Adwebsite.jpg";
import NoRecentAdMob from "../../assets/sarath_IMG/Recently viewed AdMob.jpg";
import { useHistory } from 'react-router-dom';


function RecentView(props) {

    const [ShowMoreYellowIcon, setShowMoreYellowIcon] = useState(false);
    const [recentCount, setrecentCount] = useState(false);

    let history=useHistory();

    const ShowMoreIcon = () => {

        if (window.innerWidth >= 1920) {
            if (props.reViewed.length >= 6) {
                setShowMoreYellowIcon(true)
            }
        }
        if (window.innerWidth >= 1366 && window.innerWidth <= 1919) {
            if (props.reViewed.length >= 4) {
                setShowMoreYellowIcon(true)
            }
        }
        if (window.innerWidth >= 1280 && window.innerWidth <= 1365) {
            if (props.reViewed.length >= 4) {
                setShowMoreYellowIcon(true)
            }
        }
        if (window.innerWidth >= 1024 && window.innerWidth <= 1279) {
            if (props.reViewed.length >= 3) {
                setShowMoreYellowIcon(true)
            }
        }
        if (window.innerWidth >= 800 && window.innerWidth <= 1023) {
            if (props.reViewed.length >= 6) {
                setShowMoreYellowIcon(true)
            }
        }

    }

    


    const RecentItemsCount = () => {
        if (props.reViewed.length == 0) {
            setrecentCount(true)
        }
        else {
            setrecentCount(false)
        }

    }
    useEffect(() => {
        ShowMoreIcon()
        RecentItemsCount()
    }, [props.reViewed]);




    const ref = useRef('');
    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    };
    let data = []
    const onClickAds = (datas) => {

        datas.map((element) => {

            if (element.name == "make") {
                data.make = element
            }
            if (element.name == "model") {
                data.model = element
            }
            if (element.name == "category") {
                data.category = element
            }
            if (element.name == "brand") {
                data.brand = element
            }
            // history.push(`/filter?category=${value.value}`);
            // localStorage.setItem("filter", JSON.stringify(data));
            // history.push(`/filter`);
        })
        createURL(data)
    }

    const createURL = (data) => {

        // console.log(data , data.length, "onClickAdsonClickAds");
        let url = "/filter"

        if (data.make) {
            url = `${url}?make=${data.make.label}&make_id=${data.make.value}`
        }
        if (data.model) {
            url = `${url}&model=${data.model.label}&model_id=${data.model.value}`
        }
        if (data.category) {
            let seperator = data.make ? "&" : "?"
            url = `${url}${seperator}category=${data.category.value}`
        }
        if (data.brand) {
            let seperator = !data.make && !data.category ? "?" : "&"
            url = `${url}${seperator}brand=${data.brand.value}`
        }

        if (location.pathname.includes("filter")) {
            history.push(url);
            window.location.reload();
        } else {
            history.push(url);
        }

    }
    

    return (

        <Box className='recent_section_box' pt={10} width='95vw' >
            {recentCount ?
                <>
                    <Box className='desktop_only'
                        mt="5vw"
                         width="95%"
                        boxShadow="0px 0px 99px #00000026"
                        position={'relative'}
                    >
                        <Image src={NoRecentAdWeb} loading="lazy" />
                        <Button className="hpbtnAdDark" _hover={{ bgColor: "transparent", color: "#000000", border: '2px', borderColor: '#000000' }} position='absolute' bottom='5%' right="62%" bgColor='#014cff' color='#fff' borderRadius='3xl'
                        onClick={() => onClickAds([{value: "KTEK", name: "brand", label: "KTEK"}])}>Shop Now</Button>
                    </Box>
                    <Box className='mobile_only'
                        mt="5vw"
                        borderRadius={window.location.pathname.includes("/filter") ? "16px" : "0px"}
                        boxShadow= { window.location.pathname.includes("/filter") ? "0px 1px 1px #00000026" : "0px 0px 99px #00000026"}
                    >
                        <Image borderRadius={window.location.pathname.includes("/filter") ? "16px" : "0px"} src={NoRecentAdMob} loading="lazy" />
                        
                    </Box>
                </>
                :
                <div>
                    <Text className='font_Manrope recent_font' textTransform='capitalize' fontWeight='bold'>Recently viewed <span className='desktop_only'>items</span></Text>

                    <Divider className='recent_divider desktop_only' mt='3vh' borderColor="#c5c1c1" />
                    <Flex className='recent_scrollbox' justifyContent='space-between' alignItems='center' >

                        <Flex flexFlow='row' width='100%' height="max-content" flexWrap='nowrap' overflowX="scroll" overflowY='hidden' >

                            <RecentItemDisplay reViewed={props.reViewed} />

                        </Flex>

                        {ShowMoreYellowIcon ?
                            <Box className='desktop_only' p={3} display='flex' justifyContent='center' alignItems='center'>

                                <FaChevronRight fontSize='50px' color='#F9E107' onClick={() => props.onRectClick()} />

                            </Box>
                            : ""
                        }

                    </Flex>
                </div>
            }
        </Box>



    );
}

export default RecentView;