import React, { useEffect, useState } from 'react';
import { Image } from '@chakra-ui/react';
import successimage from '../../assets/sarath_IMG/Tick_mark.png';
import { IncludeComaSeperator } from '../ProductDetailpage/pd';

import AddressData from '../Address/AddressData';
import ProductData from '../cart/ProductData';

import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Meta, UUID } from '../../services/Meta-Pixel';
import loadFacebookPixels from '../../services/loadFacebookPixel';
import { get } from '../../services/api';

export const OrderConfirmed = (props) => {
  let history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const orderId = params.get('id');

  if (orderId) {
    const [orderDetails, setOrderDetails] = useState([]);

    useEffect(() => {
      if (orderId) {
        get(`/api/v1/order/order_summary/?order_id=${orderId}`)
          .then((res) => {
            console.log(res, 'OrderConfirmed');
            setOrderDetails(res);
          })
          .catch((err) => {
            history.push(`/order-summary`);
          });
      } else history.push(`/order-summary`);
    }, []);

    let selctedAdress = [];
    selctedAdress.push(orderDetails?.user_details);

    return (
      <>
        <div className='max-w-full bg-white py-7 md:px-0'>
          <div className='px-4 mx-auto max-w-screen-2xl lg:px-16 md:px-14'>
            <div className='pb-4 '>
              <div>
                <p className='nxt_subheaders'>ORDER STATUS</p>
                <div className='ccr_divider'></div>
                <div className='flex flex-col items-center mt-8 mb-16'>
                  <Image
                    // className="lg:min-w-[17vw]  lg:max-w-[19vw] min-w-[12vw]"
                    src={successimage}
                    alt=''
                    minWidth={125}
                    maxWidth={200}
                    style={{ width: '20vw' }}
                  />

                  <p className='font-bold ord_font16-24'>ORDER PLACED SUCCESSFULLY</p>
                  <button
                    type='button'
                    className='hidden px-4 py-2 mt-16 ModalyellowBtnBorder ord_font16-24 lg:px-6 md:block'
                    onClick={() => history.push('/')}>
                    HOME
                  </button>
                </div>
                <p className='nxt_subheaders'>DELIVERY ADDRESS</p>
                <div className='ccr_divider'></div>

                <div className='flex flex-col gap-3 mt-7 md:mt-7 xl:px-60 lg:px-40 md:px-28 md:items-center'>
                  <AddressData
                    title={'DELIVERY ADDRESS'}
                    addresses={selctedAdress}
                    center={true}
                    isSelectable={false}
                  />
                </div>
                <div>
                  <p className='nxt_subheaders mt-14 md:text-xl'> PAYMENT DETAILS</p>
                  <div className='ccr_divider'></div>
                  <div className=' xl:px-60 lg:px-40 md:px-28 mt-7'>
                    <div className='p-4 cne_border mb-11 add_font18'>
                      <div className='grid gap-y-3.5'>
                        <div className='flex justify-between'>
                          <p>Payment Method</p> <p>{orderDetails?.payment_mode}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p>Total Items</p> <p>{orderDetails?.total_items}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p>Total Price</p> <p>{IncludeComaSeperator(orderDetails?.mrp_total)}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p>Discount (-)</p>
                          <p>{orderDetails?.discount ? '-' + orderDetails?.discount : 0}</p>
                        </div>
                        {orderDetails?.payment_mode == 'COD' && (
                          <div className='flex justify-between'>
                            <p>COD Charges (+)</p> <p>{orderDetails?.total_cod_charges}</p>
                          </div>
                        )}
                        <div className='flex justify-between'>
                          <p>Delivery Charges (+)</p> <p>{orderDetails?.delivery_charge}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p>Delivery Discount(-)</p> <p>{orderDetails?.delivery_discount}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p>Promo Discount (-)</p>
                          <p>
                            {orderDetails?.promo_discount == 0
                              ? '0'
                              : `-${orderDetails?.promo_discount}`}
                          </p>
                        </div>
                        <div className='flex justify-between'>
                          <p>Total Discount (-)</p>
                          <p>
                            {orderDetails?.total_discount ? '-' + orderDetails?.total_discount : 0}
                          </p>
                        </div>
                        <div className='flex justify-between font-bold add_font20'>
                          <p>Total Amount</p>{' '}
                          <p className='font-bold'>
                            {IncludeComaSeperator(orderDetails?.order_total)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <p className=' nxt_subheaders mt-7'>PRODUCT DETAILS</p>
                  <div className='block ccr_divider'></div>
                  <div className='flex flex-col mt-7 mb-14 gap-7 xl:px-60 lg:px-40 md:px-28 '>
                    <ProductData
                      title={'ORDER STATUS'}
                      cartItems={orderDetails?.product_details}
                      change_qty={false}
                    />
                  </div>
                </div>
              </div>
              <div className='hidden md:block ord_font16-24'>
                <div className='justify-center gap-20 mt-20 md:flex'>
                  <div className='text-center'>
                    <button
                      type='button'
                      className='px-2 py-2 ModalyellowBtn mb-14'
                      onClick={() => history.push('/')}>
                      CONTINUE SHOPING
                    </button>
                  </div>

                  <div className='text-center'>
                    <button
                      type='button'
                      className='px-5 py-2 ModalyellowBtn mb-14'
                      onClick={() => history.push('/myprofile/4')}>
                      ORDER HISTORY
                    </button>
                  </div>
                </div>
              </div>
              <div className='block text-center md:hidden ord_font16-24'>
                <button
                  type='button'
                  className='px-5 py-3 mb-6 ModalyellowBtn'
                  onClick={() => history.push('/myprofilemobile/4')}>
                  ORDER HISTORY
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className='fixed bottom-0 flex items-center justify-center w-full h-16 gap-2 text-lg sm:text-xl bg-yellowB md:hidden'
          onClick={() => history.push('/')}>
          {' '}
          <p className='font-bold'> CONTINUE SHOPING</p>
        </div>
      </>
    );
  } else {
    const selectedAddress = useSelector((state) => state.address.SelectedAddress);
    const paymentType = useSelector((state) => state.payment.paymentType);
    const priceDts = useSelector((state) => state.payment.paymentPriceDetails);
    const orderDetails = useSelector((state) => state.payment.orderDetails);
    const cartItems = useSelector((state) => state.payment.cartItems);

    let selctedAdress = [];
    selctedAdress.push(selectedAddress);

    useEffect(() => {
      if (paymentType == '' || orderDetails.length == 0) {
        history.push('/cart');
        // console.log(paymentType, orderDetails?.order_id, "OrderConfirmed");
      }
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      let isMounted = true;
      const eventID = UUID();

      // Meta Pixel Code 01JUN2023
      if (typeof window !== 'undefined' && Object.keys(priceDts).length > 0 && paymentType != '') {
        loadFacebookPixels();
        if (window.fbq != null) {
          window.fbq(
            'track',
            'Purchase',
            {
              value: priceDts?.total,
              currency: 'INR',
              contents:
                cartItems &&
                cartItems.map((item) => ({
                  id: item?.brakeup_part_no,
                  quantity: item?.quantity,
                })),
            },
            { eventID: eventID }
          );
        }
        let data = {
          currency: 'INR',
          value: priceDts?.total,
          contents:
            cartItems &&
            cartItems.map((item) => ({
              id: item?.brakeup_part_no,
              quantity: item?.quantity,
              delivery_category: 'home_delivery',
            })),
        };
        isMounted && Meta('Purchase', data, eventID);
        if (window.gtag != null) {
          // //     // Google tag (gtag.js) 12JUN2023 //
          window.gtag('event', 'purchase', {
            transaction_id: orderDetails?.order_id ?? null,
            value: priceDts?.total,
            currency: 'INR',
            items:
              cartItems &&
              cartItems.map((item) => ({
                item_id: item?.brakeup_part_no,
                item_name: item?.prod_name,
                affiliation: 'Brakeup',
                discount: item?.discount_price,
                item_brand: item?.brand,
                item_category: item?.category,
                item_list_name:
                  item?.vehicle_details?.make +
                  ' ' +
                  item?.vehicle_details?.model +
                  ' ' +
                  item?.vehicle_details?.year,
                price: item?.mrp,
                quantity: item?.quantity,
              })),
          });
        }
      }
      return () => {
        isMounted = false;
      };
    }, [priceDts]);

    return (
      <>
        <div className='max-w-full bg-white py-7 md:px-0'>
          <div className='px-4 mx-auto max-w-screen-2xl lg:px-16 md:px-14'>
            <div className='pb-4 '>
              <div>
                <p className='nxt_subheaders'>ORDER STATUS</p>
                <div className='ccr_divider'></div>
                <div className='flex flex-col items-center mt-8 mb-16'>
                  <Image
                    // className="lg:min-w-[17vw]  lg:max-w-[19vw] min-w-[12vw]"
                    src={successimage}
                    alt=''
                    minWidth={125}
                    maxWidth={200}
                    style={{ width: '20vw' }}
                  />

                  <p className='font-bold ord_font16-24'>ORDER PLACED SUCCESSFULLY</p>
                  <button
                    type='button'
                    className='hidden px-4 py-2 mt-16 ModalyellowBtnBorder ord_font16-24 lg:px-6 md:block'
                    onClick={() => history.push('/')}>
                    HOME
                  </button>
                </div>
                <p className='nxt_subheaders'>DELIVERY ADDRESS</p>
                <div className='ccr_divider'></div>

                <div className='flex flex-col gap-3 mt-7 md:mt-7 xl:px-60 lg:px-40 md:px-28 md:items-center'>
                  <AddressData
                    title={'DELIVERY ADDRESS'}
                    addresses={selctedAdress}
                    center={true}
                    isSelectable={false}
                  />
                </div>
                <div>
                  <p className='nxt_subheaders mt-14 md:text-xl'> PAYMENT DETAILS</p>
                  <div className='ccr_divider'></div>
                  <div className=' xl:px-60 lg:px-40 md:px-28 mt-7'>
                    <div className='p-4 cne_border mb-11 add_font18'>
                      <div className='grid gap-y-3.5'>
                        <div className='flex justify-between'>
                          <p>Payment Method</p> <p>{paymentType}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p>Total Items</p> <p>{priceDts.total_items}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p>Total Price</p> <p>{IncludeComaSeperator(priceDts?.mrp_total)}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p>Discount (-)</p> <p>-{IncludeComaSeperator(priceDts?.discount)}</p>
                        </div>
                        {paymentType == 'COD' && (
                          <div className='flex justify-between'>
                            <p>COD Charges (+)</p> <p>{priceDts?.cod_charges}</p>
                          </div>
                        )}
                        <div className='flex justify-between'>
                          <p>Delivery Charges (+)</p> <p>{priceDts?.delivery_charge}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p>Delivery Discount(-)</p> <p>{priceDts?.delivery_discount}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p>Promo Discount (-)</p>
                          <p>
                            {priceDts?.promo_discount == 0 ? '0' : `-${priceDts?.promo_discount}`}
                          </p>
                        </div>
                        <div className='flex justify-between'>
                          <p>Total Discount (-)</p>{' '}
                          <p>-{IncludeComaSeperator(priceDts?.total_discount)}</p>
                        </div>
                        <div className='flex justify-between font-bold add_font20'>
                          <p>Total Amount</p>{' '}
                          <p className='font-bold'>{IncludeComaSeperator(priceDts?.total)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <p className=' nxt_subheaders mt-7'>PRODUCT DETAILS</p>
                  <div className='block ccr_divider'></div>
                  <div className='flex flex-col mt-7 mb-14 gap-7 xl:px-60 lg:px-40 md:px-28 '>
                    <ProductData
                      title={'ORDER STATUS'}
                      cartItems={cartItems}
                      change_qty={false}
                    />
                  </div>
                </div>
              </div>
              <div className='hidden md:block ord_font16-24'>
                <div className='justify-center gap-20 mt-20 md:flex'>
                  <div className='text-center'>
                    <button
                      type='button'
                      className='px-2 py-2 ModalyellowBtn mb-14'
                      onClick={() => history.push('/')}>
                      CONTINUE SHOPING
                    </button>
                  </div>

                  <div className='text-center'>
                    <button
                      type='button'
                      className='px-5 py-2 ModalyellowBtn mb-14'
                      onClick={() => history.push('/myprofile/4')}>
                      ORDER HISTORY
                    </button>
                  </div>
                </div>
              </div>
              <div className='block text-center md:hidden ord_font16-24'>
                <button
                  type='button'
                  className='px-5 py-3 mb-6 ModalyellowBtn'
                  onClick={() => history.push('/myprofilemobile/4')}>
                  ORDER HISTORY
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className='fixed bottom-0 flex items-center justify-center w-full h-16 gap-2 text-lg sm:text-xl bg-yellowB md:hidden'
          onClick={() => history.push('/')}>
          {' '}
          <p className='font-bold'> CONTINUE SHOPING</p>
        </div>
      </>
    );
  }
};
