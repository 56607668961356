import React, { useState,useEffect, } from 'react';
import { SavedListMob } from "./SavedListMob";
import { GiftCouponsMob } from "./Gift&CouponsMob";
import { MyGarageMob } from "./MyGarageMob";
import { NotificationsMob } from "./NotificationsMob";
import { MyProfileMobileLayout } from './MyProfileMobileLayout';
import { AddressBookMob } from './AddressBookMob';
import { OrderHistoryMob } from './OrderHistoryMob';
import { ProfileMob } from './ProfileMob';
import { useHistory } from "react-router-dom";
import { useParams} from "react-router-dom";

import { get } from "../../../services/api";



function MyProfileMobile() {

    let history = useHistory();

    const [activeSection, setactiveSection] = useState("");
    let { id } = useParams();
    useEffect(()=>{
        if(id){
            setactiveSection(id);
        }
    }, [id])


        //recently_viewed

        const [reViewed, setReViewed] = useState([]);


        useEffect(() => {
            let recentUrl = '/api/v1/product/recently_viewed/';
            recentlyViewed(recentUrl);
        }, []);
    
        const recentlyViewed = (url) => {
            get(url).then(res => {
                let data = [...reViewed, ...res.data];
                setReViewed(data);
            }).catch(err => {
                setReViewed([]);
            })
    
        }
    
        const onRectClick = () => {
            let url = '/api/v1/product/recently_viewed/';
            if (reViewed) {
                const lastItem = reViewed[reViewed.length - 1];
                url = `${url}?previousId=${lastItem["_id"]["$oid"]}`;
                url = `${url}&limit=10`;
                url = `${url}&order=1`;
            }
            recentlyViewed(url)
        }
        const ToProductDetailPage = (products) => {

            history.push(`/product/${products?.prod_id}${products.vehicle_details?.make_id ? "/" + products.vehicle_details?.make_id + "/" + products.vehicle_details?.model_id + "/" + products.vehicle_details?.year + (products.vehicle_details?.version ? "/" + products.vehicle_details?.version : '') + (products.vehicle_details?.fueltype ? "/" + products.vehicle_details?.fueltype : '') : ""}${products.vehicle_details?.type ? "/" + products.vehicle_details?.type : ""}`)
        
          }

    return (     
            <MyProfileMobileLayout activeSection={activeSection} setactiveSection={setactiveSection} onRectClick={onRectClick} reViewed={reViewed}>
                <SavedListMob />
                <AddressBookMob/>
                <NotificationsMob />
                <GiftCouponsMob />
                <OrderHistoryMob ToProductDetailPage={ToProductDetailPage} />
                <MyGarageMob /> 
                <ProfileMob/>
            </MyProfileMobileLayout>
    );
}

export default MyProfileMobile;