import axios from 'axios';
import accessToken from './jwt-token-access/accessToken';
import { useSelector, useDispatch } from 'react-redux';
import { setAccess, setToken, setUser } from '../stateManagement/user/userSlice';

//pass new generated access token here

const newToken = JSON.parse(localStorage.getItem('token') || '{}');
// console.log(newToken?.data?.credAuthResult?.access_token, 'newTokennewTokennewToken', newToken)

// const token = "Bearer " + newToken?.data?.credAuthResult?.access_token;

const token = accessToken;

//apply base url for axios
export const API_URL = 'https://api.brakeup.in';
// const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common['Authorization'] = token;
// axiosApi.defaults.headers.common["Access-Control-Allow-Origin"] = "http://localhost:3000"
// axiosApi.defaults.headers.common["Control-Allow-Origin"] = "*"

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = { headers: { Authorization: token } }) {
  return await axiosApi.get(url, { ...config }).then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config }).then((response) => response.data);
}

// const dispatch = useDispatch()

const refreshAccessToken = async () => {
  // console.log("refresh_anonymous");

  const newToken1 = JSON.parse(localStorage.getItem('token') || '{}');
  if (newToken1.data?.credAuthResult?.type == 'anonymous') {
    return get('/api/v1/user/refresh_anonymous/?user_id=' + newToken1?.data?.credAuthResult?.id)
      .then((res) => {
        // console.log(newToken1.data?.credAuthResult?.type, res, "refresh_anonymous2");
        // dispatch(setToken(res));

        localStorage.setItem('token', JSON.stringify(res));
        return res;
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  } else return newToken1;
};

axiosApi.interceptors.request.use(
  function (config) {
    // Spinning Start To Show
    // UPDATE: Add This Code To Show Global Loading Indicator
    // Document.body.classList.add('is-load-remove');

    let loaderExceptions = [
      '/api/v1/products/cartitemlist/',
      'api/v1/products/address/',
      'api/v1/products/cart/price_details/',
      'api/v1/search/buyer_search/?q=Homepage_search&',
      'api/v1/products/cart/bestfit/',
      'api/v1/products/pincode/address/',
      'api/v1/product/recently_viewed/',
    ];

    let loaderInclusions = [
      'api/v1/user/login/',
      'api/v1/products/updatecart/',
      'api/v1/vehiclemake/',
      'api/v1/search/buyer_search/?q=Homepage_filter&',
      'api/v1/products/addtocart/',
      'api/v1/products/cart/price_details/',
      'api/v1/products/savelater/',
      'api/v1/products/product_buynow/',
      'api/v1/products/address/',
      'api/v1/products/sendcod_otp/',
      'api/v1/cod_resendotp/',
      'api/v1/payment/signature_check/',
      'api/v1/user/profileupdate/',
      'api/v1/products/savelater/',
      'api/v1/listnotifications/',
      'api/v1/order/replacementbutton/',
      'api/v1/order/orderprodlist/',
      'api/v1/updatenotifications/',
      'api/v1/products/buyer/buyer_replace_request/',
      'api/v1/password_change/',
      'api/v1/payment/phonepe_paymentverification/',
    ];

    if (loaderExceptions.some((el) => config.url.includes(el))) return config;
    else {
      // console.log("is-load-add-is-load-add1");

      if (
        loaderInclusions.some((el) => config.url.includes(el)) &&
        document.getElementById('is-load-remove')
      ) {
        document.getElementById('is-load-remove').style.display = 'flex';
      }
      return config;
    }
  },
  function (error) {
    // console.log("is-load-remove-is-load-remove1");
    if (document.getElementById('is-load-remove')) {
      document.getElementById('is-load-remove').style.display = 'none';
    }
    return Promise.reject(error);
  }
);

axiosApi.interceptors.response.use(
  (response) => {
    // console.log("is-load-remove-is-load-remove2");
    if (document.getElementById('is-load-remove')) {
      document.getElementById('is-load-remove').style.display = 'none';
    }

    return response;
  },
  async function (error) {
    // console.log(error.response.status, error.config, "refresh_anonymousis-lerror.response.status");
    const originalRequest = error.config;
    if (
      error.response.status === 403 &&
      !originalRequest?._retry &&
      originalRequest.url != '/api/v1/user/refresh_anonymous/'
    ) {
      // console.log(error.response.status, "refresh_anon.response.status");
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      originalRequest.headers['Authorization'] =
        'Bearer ' + access_token?.data?.credAuthResult?.access_token;
      // console.log(access_token, "refresh_anonymousis");
      // dispatch(setToken(res));
      // console.log(access_token, "refresh_anonymousis222");
      return axiosApi(originalRequest);
    } else {
      // console.log("refresh_anonymousis-load-remove-is-load-remove3");
      if (document.getElementById('is-load-remove')) {
        document.getElementById('is-load-remove').style.display = 'none';
      }
    }
    return Promise.reject(error);
  }
);
