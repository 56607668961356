import React from 'react';
import { useEffect, useRef, useState } from 'react';

import MobAdPdp from '../../assets/sarath_IMG/Bought Together AdMob.jpg';
import AdPdp from '../../assets/sarath_IMG/Bought Together AdWeb.jpg';

import { Image } from '@chakra-ui/image';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import ProductCardScroll from '../FilterPage/ProductCardScroll';

import { get, post } from '../../services/api';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { SignIn } from '../Login/LoginSignup';

import { Meta, UUID } from '../../services/Meta-Pixel';
import { Add2CartGtag, Save4LaterGtag } from '../../services/G-Tag';
import loadFacebookPixels from '../../services/loadFacebookPixel';
import { RazorpayAffordabilityWidget } from './RazorpayAffordabilityWidget';

export function IncludeComaSeperator(price) {
  if (price && price != '') {
    const parsePrice = parseInt(price).toLocaleString();
    return parsePrice;
  }
}

export function FixedtoZero(price) {
  if (price && price != '') {
    const parsePrice = price.toFixed(0);
    if (parsePrice == 0) {
      return;
    } else return parsePrice + '% OFF';
  }
}

const Product_Detail = () => {
  const token = JSON.parse(localStorage.getItem('token')).data.credAuthResult;

  const [productDetails, setProductDetails] = useState({});
  const [combVehcleDtls, setCombVehcleDtls] = useState([]);

  const [sellerData, setSellerData] = useState({});
  const [sellers, setSellers] = useState([]);

  const [pincodeLocation, setPincodeLocation] = useState('');
  const [pinLoader, setPinLoader] = useState(false);

  const [isInvalidPincode, setIsInvalidPincode] = useState(false);

  const [discount, setdiscount] = useState(1);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [largeImage, setlargeImage] = useState('');
  const [modalLargeImage, setModalLargeImage] = useState('');
  const [recentData, setRecentData] = useState([]);

  const [displayInputPincode, setdisplayInputPincode] = useState(false);
  const [pincode, setpincode] = useState('');

  const [productNotAvailable, setproductNotAvailable] = useState(false);

  let history = useHistory();

  let { id, make, model, year, version, fuel, type } = useParams();

  loadFacebookPixels();

  useEffect(() => {
    if (id) {
      get(`/api/v1/product/productdetail/?id=${id}`)
        .then((res) => {
          // if (res.category == "Brake Fluid"  && make) {
          //   window.location.replace(`${window.location.origin}/product/${id}`)
          //   // window.history.pushState(null, '', `/product/${id}`);
          // }

          setProductDetails(res);

          // console.log(res, "productdetailproductdetail");
          if (make && model) {
            const combVehDtls =
              res &&
              res.compatible_vehicles.filter((items) => {
                return (
                  items.compatible_vehicle_make_id == make &&
                  items.compatible_vehicle_model_id == model
                );
              });
            // console.log(combVehDtls, "combVehDtls");
            setCombVehcleDtls(combVehDtls ? combVehDtls[0] : []);
            document.title = `Product Description - Buy online ${res.brand} ${res.prod_name} ${
              combVehDtls[0]?.compatible_vehicle_make ? 'for' : ''
            } ${combVehDtls[0]?.compatible_vehicle_make ?? ''} ${
              combVehDtls[0]?.compatible_vehicle_model ?? ''
            } at Lowest Price - Brakeup! `;
          } else {
            document.title = `Product Description - Buy online ${res.brand} ${res.prod_name} at Lowest Price - Brakeup! `;
          }
          getPincode();
        })
        .catch((err) => {
          setProductDetails({});
          setproductNotAvailable(true);
        });
      getRecentlyViewed();
    }
    window.scrollTo(0, 0);
  }, [id]);

  const getAddress = (pincode) => {
    setPinLoader(true);

    // console.log(pincode, "getAddressgetAddress");

    fetch(`https://api.postalpincode.in/pincode/${pincode}`, {
      method: 'get',
    })
      .then((response) => response.json())
      .then((res) => {
        // console.log(res[0].PostOffice[0].District, "getTopSellingProductsgetTopSellingProducts");

        setPincodeLocation(res[0].PostOffice[0].District);
        setPinLoader(false);
        setdisplayInputPincode(false);
      })
      .catch((err) => {
        setPinLoader(false);
        // setIsInvalidPincode(true);
      });

    get(`/api/v1/product/vendor_extimated_delivery/?id=${id}&delivery_postcode=${pincode}`)
      .then((res) => {
        if (res.pincode_loc != 'Invalid Pincode.') {
          // console.log(res.pincode_loc, "valid Pincode.");
          setpincode(pincode);
          localStorage.setItem('pincode', JSON.stringify(pincode));
          setIsInvalidPincode(false);
        } else {
          setdisplayInputPincode(true);
          setIsInvalidPincode(true);
          setpincode('');
        }

        setSellerData(res.sellers[0]);
        setSellers(res.sellers);

        // setIsLoading(false);
      })
      .catch((err) => {
        // setIsLoading(false);

        setSellerData([]);
        setSellers([]);
      });
    // }
  };

  const ifNoPincode = () => {
    // setIsLoading(true);
    setpincode('');
    get(`/api/v1/product/vendor_extimated_delivery/?id=${id}&delivery_postcode=110001`)
      .then((res) => {
        // setpostalAddress(res);
        // setsellerLocation(res.sellers[0].seller_location);
        // setsellingPrice(res.sellers[0].selling_price);
        // setestimatedDelivery(res.sellers[0].etd);
        // setdiscount(res.sellers[0].discount);
        // setSellerId(res.sellers[0].vend_id);
        // // setIsLoading(false);

        setSellerData(res.sellers[0]);
        setSellers(res.sellers);
        setdisplayInputPincode(true);
      })
      .catch((err) => {
        // setpostalAddress({});
      });
  };

  const getPincode = () => {
    // setIsLoading(true);

    const tokenpin = JSON.parse(localStorage.getItem('pincode'));
    if (tokenpin != null) {
      getAddress(tokenpin.toString());
    } else {
      get('api/v1/products/pincode/address/')
        .then((res) => {
          if (res.address_pincode.pincode != []) {
            getAddress(res.address_pincode.pincode.toString());
          } else if (res.order_pincode.pincode != []) {
            getAddress(res.order_pincode.pincode.toString());
          } else {
            ifNoPincode();
          }
        })
        .catch((err) => {
          ifNoPincode();
        });
    }
  };

  useEffect(() => {
    if (Object.keys(sellerData).length > 0) {
      setTimeout(() => {
        postRecentlyViewed();
      }, 3000);
    }
  }, [sellerData]);

  const postRecentlyViewed = () => {
    const token = JSON.parse(localStorage.getItem('token')).data.credAuthResult;
    let recentdata = {
      prod_id: id,
      make_id: make ?? '',
      model_id: model ?? '',
      year: year ?? '',
      user_id: token.id,
      vend_id: sellerData.vend_id,
      fueltype: fuel ?? '',
      version: version ?? '',
    };
    post(`/api/v1/product/recently_viewed/`, recentdata)
      .then((res) => {})
      .catch((err) => {});
  };
  const getRecentlyViewed = () => {
    get(`/api/v1/product/recently_viewed/`)
      .then((res) => {
        setRecentData(res.data);
      })
      .catch((err) => {
        setRecentData([]);
      });
  };

  const handleScroll = () => {
    if (window.scrollY >= 250) {
      setlargeImage('');
      onClose();
    }
  };

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setlargeImage('');
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('scroll', handleScroll, true);
      document.addEventListener('click', handleClickOutside, true);
    };
  }, []);

  const ImageData = productDetails.image ?? [];

  const EnlargeImage = (image) => {
    if (window.innerWidth <= 767) {
      if (largeImage === image) {
        setlargeImage('');
      } else {
        setlargeImage(image);
      }
    } else {
      setModalLargeImage(image);
      onOpen();
    }
  };

  const nextSlide = () => {
    const curIndex = ImageData.indexOf(modalLargeImage);
    const newIndex = curIndex === ImageData.length - 1 ? 0 : curIndex + 1;
    setModalLargeImage(ImageData[newIndex]);
  };
  const prevSlide = (item) => {
    const curIndex = ImageData.indexOf(modalLargeImage);
    const newIndex = curIndex === 0 ? ImageData.length - 1 : curIndex - 1;
    setModalLargeImage(ImageData[newIndex]);
  };

  document.onkeydown = ArrowkeysOnModal;

  function ArrowkeysOnModal(e) {
    e = e || window.event;
    if (isOpen) {
      if (e.key == 'ArrowLeft') {
        prevSlide();
      } else if (e.key == 'ArrowRight') {
        nextSlide();
      }
    }
  }

  const [isSignIn, setIsSignIn] = useState(false);
  const closeSignIn = () => {
    setIsSignIn(!isSignIn);
  };

  //Toast

  const Swal = require('sweetalert2');

  const Toast2 = (title, theme, color, iconColor) => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      autofocus: false,
      toast: true,
      animation: true,
      timer: 5000,
      // timerProgressBar: true,
      iconColor: iconColor,
      icon: theme,
      title: title,
      color: color,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  };

  const differenceInSellingPrices = (selling_price) => {
    let calc = selling_price - sellerData?.selling_price;
    if (calc > 0) {
      return <p className='ml-1 text-redB'>(+){IncludeComaSeperator(calc)}</p>;
    } else if (calc < 0) {
      return <p className='ml-1 text-greenB'>(-){IncludeComaSeperator(Math.abs(calc))}</p>;
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const eventID = UUID();
      if (
        Object.keys(productDetails).length > 0 &&
        productDetails.total_stock > 0 &&
        Object.keys(sellerData).length > 0
      ) {
        RazorpayAffordabilityWidget(sellerData?.selling_price);

        let data = {
          currency: 'INR',
          value: sellerData?.selling_price,
          contents: [
            {
              id: productDetails.brakeup_part_no,
              quantity: 1,
              delivery_category: 'home_delivery',
            },
          ],
        };
        Meta('ViewContent', data, eventID);

        // Meta Pixel Code 1Jun2023
        window.fbq(
          'track',
          'ViewContent',
          {
            value: sellerData?.selling_price,
            currency: 'INR',
            content_ids: productDetails.brakeup_part_no,
            content_type: productDetails.category,
          },
          { eventID: eventID }
        );
      } else if (Object.keys(productDetails).length > 0 && productDetails.total_stock <= 0) {
        RazorpayAffordabilityWidget(productDetails?.MRP);

        let data = {
          currency: 'INR',
          value: productDetails?.MRP,
          contents: [
            {
              id: productDetails?.brakeup_part_no,
              quantity: 1,
              delivery_category: 'home_delivery',
            },
          ],
        };
        Meta('ViewContent', data, eventID);

        // Meta Pixel Code 1Jun2023
        window.fbq(
          'track',
          'ViewContent',
          {
            value: productDetails.MRP,
            currency: 'INR',
            content_ids: productDetails.brakeup_part_no,
            content_type: productDetails.category,
          },
          { eventID: eventID }
        );
      }
    }
  }, [productDetails, sellerData?.selling_price]);

  const addToCart = (type) => {
    let data = {
      product: [
        {
          vend_id: sellerData.vend_id,
          prod_id: id,
          make_id: make ?? '',
          model_id: model ?? '',
          year: year ?? '',
          fueltype: fuel ?? '',
          version: version ?? '',
          delivery_date: sellerData.etd,
          quantity: 1,
        },
      ],
      pincode: pincode == '' ? '110001' : pincode,
    };
    let url = '/api/v1/products/product_buynow/';
    if (type == 'cart') {
      url = '/api/v1/products/addtocart/';
    }
    const eventID = UUID();
    const token = JSON.parse(localStorage.getItem('token')).data.credAuthResult;
    setIsSignIn(false);
    post(url, data)
      .then((res) => {
        if (type == 'cart') {
          history.push('/cart');
        } else if (token.type == 'Access') {
          history.push(`/address/${res.cart_id}`);
        } else if (window.innerWidth >= 800) {
          setIsSignIn(true);
        } else {
          Toast2('Please Sign in !', 'info', '#414bdb', '#414bdb');
          history.push('/loginsignup');
        }
        window.fbq(
          'track',
          'AddToCart',
          {
            value: productDetails?.vendor_products?.selling_price,
            currency: 'INR',
            contents: [
              {
                id: productDetails?.brakeup_part_no,
                quantity: 1,
              },
            ],
          },
          { eventID: eventID }
        );

        let data2 = {
          currency: 'INR',
          value: productDetails?.vendor_products?.selling_price,
          contents: [
            {
              id: productDetails?.brakeup_part_no,
              quantity: 1,
              delivery_category: 'home_delivery',
            },
          ],
        };
        let make1 = combVehcleDtls?.compatible_vehicle_make
          ? combVehcleDtls?.compatible_vehicle_make
          : '';

        let model1 = combVehcleDtls?.compatible_vehicle_model
          ? combVehcleDtls?.compatible_vehicle_model
          : '';

        let year1 = year ? year : '';

        let vehicle = make1 + ' ' + model1 + ' ' + year1;
        Meta('AddToCart', data2, eventID);
        Add2CartGtag(productDetails, vehicle);
      })
      .catch((err) => {
        Toast2(err?.response?.data.message, 'error', '#f22020');
      });
  };

  const saveForLater = () => {
    const token = JSON.parse(localStorage.getItem('token')).data.credAuthResult;
    const eventID = UUID();

    let data = {
      product: [
        {
          vend_id: sellerData?.vend_id ?? '',
          prod_id: id,
          make_id: make ?? '',
          model_id: model ?? '',
          year: year ?? '',
          fueltype: fuel == undefined ? '' : fuel,
          version: version == undefined ? '' : version,
          quantity: 1,
        },
      ],
    };
    if (token.type == 'Access') {
      post('/api/v1/products/savelater/productdetail/', data)
        .then((res) => {
          Toast2('Item moved to Save for Later!', 'success', '#089520');
          let data = {
            currency: 'INR',
            value: productDetails?.MRP,
            contents: [
              {
                id: productDetails?.brakeup_part_no,
                quantity: 1,
                delivery_category: 'home_delivery',
              },
            ],
          };
          let make1 = combVehcleDtls?.compatible_vehicle_make
            ? combVehcleDtls?.compatible_vehicle_make
            : '';

          let model1 = combVehcleDtls?.compatible_vehicle_model
            ? combVehcleDtls?.compatible_vehicle_model
            : '';

          let year1 = year ? year : '';

          let vehicle = make1 + ' ' + model1 + ' ' + year1;

          Meta('AddToWishlist', data, eventID);
          Save4LaterGtag(productDetails, vehicle);

          window.fbq(
            'track',
            'AddToWishlist',
            {
              value: productDetails?.MRP,
              currency: 'INR',
              contents: [
                {
                  id: productDetails?.brakeup_part_no,
                  quantity: 1,
                },
              ],
            },
            { eventID: eventID }
          );
        })
        .catch((err) => {
          Toast2(err?.response?.data.message, 'error');
        });
    } else if (window.innerWidth >= 800) {
      setIsSignIn(true);
    } else {
      Toast2('Please Sign in !', 'info', '#414bdb', '#414bdb');
      history.push('/loginsignup');
    }
  };

  return (
    <div className='relative bg-white font-manrope'>
      {productNotAvailable ? (
        <div className='mx-auto max-w-screen-2xl'>
          <div className='w-screen h-screen '>
            <div className='sticky text-center top-1/2 '>
              Product is not available right now. Go{' '}
              <span>
                <button className='font-bold text-blueB1' onClick={() => history.goBack()}>
                  back
                </button>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className='mx-auto max-w-screen-2xl'>
          <div className='py-5 mx-4 md:mx-16 '>
            {ImageData.length === 1 && window.innerWidth <= 767 ? (
              <div className='p-2 rounded-brB shadow-bsCard2'>
                <Image src={ImageData[0]} alt='' objectFit='cover' />
              </div>
            ) : (
              <div>
                {largeImage ? (
                  <div
                    ref={ref}
                    className='p-2 rounded-brB shadow-bsCard2'
                    onClick={() => setlargeImage('')}>
                    <Image src={largeImage} alt='' />
                  </div>
                ) : (
                  <div className='grid grid-cols-2 gap-2 lg:gap-8 md:grid-cols-4 xl:gap-11 md:mx-2 lg:mx-16 xl:mx-36'>
                    <div
                      className='relative p-2 cursor-pointer rounded-tl-brB md:rounded-brB shadow-bsCard2'
                      onClick={() => EnlargeImage(ImageData[0])}>
                      <Image src={ImageData[0]} alt='' objectFit='contain' />
                    </div>
                    {ImageData[1] && (
                      <div
                        className='relative p-2 cursor-pointer rounded-tr-brB md:rounded-brB shadow-bsCard2'
                        onClick={() => EnlargeImage(ImageData[1])}>
                        <Image src={ImageData[1]} alt='' />
                      </div>
                    )}
                    {ImageData[2] && (
                      <div
                        className='relative p-2 cursor-pointer rounded-bl-brB md:rounded-brB shadow-bsCard2'
                        onClick={() => EnlargeImage(ImageData[2])}>
                        <Image src={ImageData[2]} alt='' />
                      </div>
                    )}
                    {ImageData[3] && (
                      <div
                        className='relative p-2 cursor-pointer rounded-br-brB md:rounded-brB shadow-bsCard2'
                        onClick={() => EnlargeImage(ImageData[3])}>
                        <Image src={ImageData[3]} alt='' />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {/* <section className='flex items-center justify-center h-full '>
               
       <div className='focus:z-1 absolute justify-center backdrop-filter  backdrop-blur-2.5px w-full md:bg-greyB1  h-full flex flex-col items-center font-bold'>
       <span className='hidden md:block'>
       <div className='grid grid-cols-4 gap-6 px-6 mb-20'>
        
      
                <input
                  className="p-3 font-normal rounded-brBs drop-shadow-dsInput"
                  name="name"
                  type="text"
                  placeholder="VOLKSWAGEN"
                />
                 <input
                  className="p-3 font-normal rounded-brBs drop-shadow-dsInput"
                  name="VENTO"
                  type="text"
                  placeholder="VENTO"
                /> 
                 <input
                  className="p-3 font-normal text-black rounded-brBs drop-shadow-dsInput"
                  name="SELECT YEAR"
                  type="number"
                  placeholder="SELECT YEAR"
                />
                 <input
                  className="p-3 font-normal rounded-brBs drop-shadow-dsInput"
                  name="SELECT VERSION"
                  type="text"
                  placeholder="SELECT VERSION"
                />
                </div>
                </span>
                  <p >Selected product is incompatible for the car</p>
                   <p > Please select another product</p>
                   <div className="mt-8 text-center">
                  <button
                    type="button"
                    className="px-4 py-3 ModalyellowBtn "
                  >
                    VIEW PRODUCTS
                  </button>
                </div>
        </div>
        </section> */}

            <div className='my-2 md:flex md:justify-between md:items-center md:mx-2 lg:mx-16 xl:mx-36'>
              <div className='my-8 text-center md:text-left '>
                <p className='font-bold uppercase pd_font20' id='product_name'>
                  {productDetails.brand} {productDetails.prod_name}
                </p>

                <div className='flex items-center justify-center my-2 uppercase pd_font14 pd_vehiclespec md:justify-start'>
                  <Image
                    className='mr-1'
                    width={30}
                    src={combVehcleDtls?.compatible_vehicle_make_image}
                    alt=''
                  />
                  <p>{combVehcleDtls?.compatible_vehicle_model}</p>
                  <p>{year}</p>
                  <p>{version}</p>
                  <p>{fuel}</p>
                </div>
                <div className='flex items-center justify-center font-normal md:justify-start'>
                  <p className='mr-2 line-through text-redB pd_font12'>
                    {Object.keys(sellerData).length == 0
                      ? ''
                      : sellerData?.selling_price == productDetails?.MRP
                      ? ''
                      : 'Rs. ' + IncludeComaSeperator(productDetails?.MRP)}
                  </p>
                  <p className='font-bold pd_font18 '>
                    Rs.{' '}
                    {Object.keys(sellerData).length == 0
                      ? IncludeComaSeperator(productDetails?.MRP)
                      : sellerData?.selling_price != 0
                      ? sellerData?.selling_price
                      : IncludeComaSeperator(productDetails.MRP)}
                  </p>
                  {FixedtoZero(sellerData?.discount) && (
                    <p className='ml-2 text-greenB pd_font12'>
                      {FixedtoZero(sellerData?.discount)}
                    </p>
                  )}
                </div>
              </div>

              {productDetails?.total_stock > 0 ? (
                <div className='hidden md:block'>
                  <div className='items-center justify-center gap-10 md:flex pd_font16'>
                    <div className='text-center'>
                      <button
                        type='button'
                        id='add_to_cart'
                        disabled={Object.keys(sellerData).length > 0 ? false : true}
                        // disabled={true}
                        // (Object.keys(sellerData).length > 0)
                        className={`ModalyellowBtnBorder  px-5 py-2 ${
                          Object.keys(sellerData).length > 0 ? '' : 'cursor-progress'
                        } `}
                        onClick={() => addToCart('cart')}>
                        ADD TO CART
                      </button>
                    </div>

                    <div className='text-center'>
                      <button
                        type='button'
                        disabled={Object.keys(sellerData).length > 0 ? false : true}
                        className={`ModalyellowBtn px-8 py-2 ${
                          Object.keys(sellerData).length > 0 ? '' : 'cursor-progress'
                        }`}
                        onClick={() => addToCart('buy')}>
                        BUY NOW
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='hidden md:block'>
                  <div className='items-center justify-center gap-10 md:flex pd_font16'>
                    <div className='font-bold text-center uppercase pd_font18 text-redB '>
                      Out of stock
                    </div>

                    <div className='text-center'>
                      <button
                        type='button'
                        className='px-8 py-2 ModalyellowBtn'
                        id='save_for_later'
                        onClick={() => saveForLater()}>
                        Save for Later
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className={`lg:mx-16 xl:mx-36 ${
                productDetails?.total_stock > 0 ? 'block' : 'hidden'
              }`}>
              <div id='razorpay-affordability-widget'></div>
            </div>

            {productDetails?.total_stock > 0 ? (
              <>
                <p className='mt-3 nxt_subheaders'>DELIVERY DETAILS</p>
                <div className='ccr_divider'></div>
                <div className='md:flex md:justify-between md:px-0.5 lg:px-8'>
                  <div className='md:w-1/2 lg:w-2/5'>
                    <div className='flex items-center h-8 mt-4'>
                      <p className='font-bold pd_font14'>Your Location:</p>
                      {displayInputPincode ? (
                        <div className='relative w-1/3 ml-2 pd_font14 '>
                          <input
                            // className="px-2 py-1 font-bold ccr_inputbox shadow-bsCard"
                            className={`ccr_inputbox px-3 py-1 font-bold
                        ${isInvalidPincode ? 'drop-shadow-dsInputError' : 'drop-shadow-dsInput'}`}
                            name='name'
                            type='number'
                            placeholder='PINCODE'
                            maxLength={6}
                            defaultValue={pincode}
                            onKeyDown={(e) => {
                              e.key === 'Enter' && e.target.value.length == e.target.maxLength
                                ? getAddress(pincode)
                                : '';
                            }}
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                              else {
                                setpincode(e.target.value);
                                // getAddress(e.target.value);
                                setIsInvalidPincode(false);
                              }
                            }}
                          />
                          <div className='absolute right-2.5 bottom-2 flex'>
                            {pinLoader && (
                              <div
                                className='inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.125em] text-yellowB motion-reduce:animate-[spin_1.5s_linear_infinite]'
                                role='status'>
                                {/* <span
                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                      >Loading...</span> */}
                              </div>
                            )}
                          </div>
                          {isInvalidPincode && (
                            <div className='relative'>
                              <p className='absolute w-4/5 text-sm text-center opacity-50 whitespace-nowrap text-redB '>
                                Invalid Pincode
                              </p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <p className='ml-1 font-bold uppercase pd_font14'>{pincodeLocation}</p>
                      )}
                      {displayInputPincode ? (
                        <button
                          className='px-3 py-1 mx-1 text-xs uppercase border rounded-brBs border-greyB2 focus:outline-none'
                          onClick={() => getAddress(pincode)}>
                          Check
                        </button>
                      ) : (
                        <button
                          className='px-3 py-1 mx-3 uppercase border rounded-brBs border-greyB2 pd_font12 focus:outline-none'
                          onClick={() => setdisplayInputPincode(true)}>
                          Change
                        </button>
                      )}
                    </div>

                    <div className='flex-col justify-between my-5'>
                      <p className='font-bold pd_font14'>
                        Expect Delivery: <span className=''>{sellerData?.etd}</span>
                      </p>
                      <p className='my-6 font-bold capitalize pd_font14'>
                        Seller Location: <span className=''>{sellerData?.seller_location}</span>
                      </p>
                    </div>
                  </div>
                  <div>
                    {sellers && sellers.length >= 2 && (
                      <div className='grid grid-cols-2 gap-4 mb-5 md:grid-cols-2 xl:grid-cols-3'>
                        {sellers &&
                          sellers.map((data, index) => (
                            <div
                              className={`pd_font12 rounded-brBs px-4 py-2 text-center cursor-pointer ${
                                sellerData.vend_id == data.vend_id && sellers.length >= 2
                                  ? 'shadow-bsCardFilter'
                                  : 'shadow-bsCard2'
                              } `}
                              key={index}
                              onClick={() => setSellerData(data)}>
                              <p className='font-bold uppercase'>
                                GET ON {moment(data.etd).format('DD MMM')}
                              </p>
                              <p className='my-2 capitalize text-greyB5'>
                                Seller location: {data.seller_location}
                              </p>
                              <div className='flex justify-center font-normal'>
                                <p>Rs. {IncludeComaSeperator(data.selling_price)}</p>
                                {differenceInSellingPrices(data.selling_price)}
                                {/* <p className="ml-1 text-redB ">(-){IncludeComaSeperator(sellerData.selling_price - data.selling_price)}</p> */}
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className='mb-10 font-bold text-center uppercase pd_font18 text-redB md:hidden'>
                Out of stock
              </div>
            )}
            <div>
              <p className='nxt_subheaders'>product specification</p>
              <div className='ccr_divider'></div>
              <div className='mx-4 mb-10 font-bold uppercase pd_font14 pd_detailHeight md:gap-x-16 gap-x-20 lg:gap-x-64 whitespace-nowrap md:grid md:grid-cols-2 '>
                {productDetails?.brand && (
                  <div className='flex justify-between pd_detailHeight md:items-center'>
                    <p>BRAND</p>
                    <div className='hidden w-4/12 h-px mx-5 min-w-1rem bg-greyB2 md:block'></div>
                    <p className='md:text-right'>{productDetails?.brand}</p>
                  </div>
                )}
                {productDetails?.axle && (
                  <div className='flex justify-between md:items-center'>
                    <p>AXLE</p>
                    <div className='hidden w-4/12 h-px mx-5 min-w-1rem bg-greyB2 md:block'></div>
                    <p className='md:text-right'>{productDetails?.axle}</p>
                  </div>
                )}
                {productDetails?.category && (
                  <div className='flex justify-between md:items-center '>
                    <p>CATEGORY</p>
                    <div className='hidden w-4/12 h-px mx-5 min-w-1rem bg-greyB2 md:block'></div>
                    <p className='md:text-right'>{productDetails?.category}</p>
                  </div>
                )}
                {productDetails?.type && (
                  <div className='flex justify-between md:items-center '>
                    <p>ROTOR TYPE</p>
                    <div className='hidden w-4/12 h-px mx-5 min-w-1rem bg-greyB2 md:block'></div>
                    <p className='md:text-right'>{productDetails?.type}</p>
                  </div>
                )}
                {productDetails?.wear_indicator && (
                  <div className='flex justify-between md:items-center '>
                    <p>WEAR INDICATOR</p>
                    <div className='hidden w-4/12 h-px mx-5 min-w-1rem bg-greyB2 md:block'></div>
                    <p className='md:text-right'>{productDetails?.wear_indicator}</p>
                  </div>
                )}
                {productDetails?.brakeup_part_no && (
                  <div className='flex justify-between md:items-center '>
                    <p>Brakeup Part No.</p>
                    <div className='hidden w-4/12 h-px mx-5 min-w-1rem bg-greyB2 md:block'></div>
                    <p className='md:text-right'>{productDetails?.brakeup_part_no}</p>
                  </div>
                )}
                {productDetails?.unit_per_box && productDetails?.unit_per_box != 0 ? (
                  <div className='flex justify-between md:items-center '>
                    <p>Units Per Box</p>
                    <div className='hidden w-4/12 h-px mx-5 min-w-1rem bg-greyB2 md:block'></div>
                    <p className='md:text-right'>{productDetails?.unit_per_box}</p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {productDetails?.description && (
              <div>
                <p className='nxt_subheaders'>product description</p>
                <div className='ccr_divider'></div>
                <p className='mx-4 mb-10 text-justify pd_font14'>{productDetails?.description}</p>
              </div>
            )}
            {productDetails?.product_height && productDetails?.product_height != 0 && (
              <div>
                <p className='nxt_subheaders'>Technical Details</p>
                <div className='ccr_divider'></div>

                <div className='mx-4 mb-10 font-bold uppercase pd_font14 pd_detailHeight md:gap-x-4 gap-x-20 lg:gap-x-72 whitespace-nowrap md:grid md:grid-cols-2 '>
                  {productDetails?.product_width && productDetails?.product_width != 0 ? (
                    <div className='flex justify-between pd_detailHeight md:items-center'>
                      <p>WIDTH</p>
                      <div className='hidden w-4/12 h-px mx-5 min-w-1rem bg-greyB2 md:block'></div>
                      <p className='md:text-right'>{productDetails?.product_width}mm</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {productDetails?.product_diameter && productDetails?.product_diameter != 0 ? (
                    <div className='flex justify-between md:items-center md:gap-4'>
                      <p>DIAMETER</p>
                      <div className='hidden w-4/12 h-px min-w-1rem bg-greyB2 md:block'></div>
                      <p className='md:text-right'>{productDetails?.product_diameter}mm</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {productDetails?.product_thickness && productDetails?.product_thickness != 0 ? (
                    <div className='flex justify-between md:items-center md:gap-4'>
                      <p>THICKNESS</p>
                      <div className='hidden w-4/12 h-px min-w-1rem bg-greyB2 md:block'></div>
                      <p className='md:text-right'>{productDetails?.product_thickness}mm</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {productDetails?.product_height && productDetails?.product_height != 0 ? (
                    <div className='flex justify-between md:items-center md:gap-4'>
                      <p>HEIGHT</p>
                      <div className='hidden w-4/12 h-px min-w-1rem bg-greyB2 md:block'></div>
                      <p className='md:text-right'>{productDetails?.product_height}mm</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {productDetails?.product_width && productDetails?.product_width != 0 ? (
                    <div className='flex justify-between md:items-center md:gap-4'>
                      <p>WIDTH</p>
                      <div className='hidden w-4/12 h-px min-w-1rem bg-greyB2 md:block'></div>
                      <p className='md:text-right'>{productDetails?.product_width}mm</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {productDetails?.min_thickness && productDetails?.min_thickness != 0 ? (
                    <div className='flex justify-between md:items-center md:gap-4'>
                      <p>MINIMUM THICKNESS</p>
                      <div className='hidden w-4/12 h-px min-w-1rem bg-greyB2 md:block'></div>
                      <p className='md:text-right'>{productDetails?.min_thickness}mm</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {productDetails?.no_of_holes && productDetails?.no_of_holes != 0 ? (
                    <div className='flex justify-between md:items-center md:gap-4'>
                      <p>NO. OF HOLES</p>
                      <div className='hidden w-4/12 h-px min-w-1rem bg-greyB2 md:block'></div>
                      <p className='md:text-right'>{productDetails?.no_of_holes}</p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
            <div>
              <p className='nxt_subheaders'>PRODUCT HIGHLIGHTS</p>
              <div className='ccr_divider'></div>
              <div className='m-4 mb-10 font-bold pd_font14'>
                {productDetails?.highlights &&
                  productDetails?.highlights.map((data, index) => (
                    <div className='flex justify-between my-4' key={index}>
                      <p>{data}</p>
                    </div>
                  ))}
              </div>
            </div>

            <div className='my-8'>
              <Image
                className='block hpAdsImg md:hidden'
                src={MobAdPdp}
                loading='lazy'
                alt='brakeup'
              />
              <Image
                className='hidden hpAdsImg md:block'
                src={AdPdp}
                loading='lazy'
                alt='brakeup'
              />
            </div>

            <ProductCardScroll title={'RECENTLY VIEWED'} reViewed={recentData} />
          </div>

          <div className='fixed bottom-0 flex items-center w-full text-base font-bold text-center pd_font16 h-14 md:hidden'>
            {productDetails?.total_stock > 0 ? (
              <>
                <button
                  className='flex items-center justify-center w-full h-full bg-white shadow-bsFooterMob'
                  disabled={Object.keys(sellerData).length > 0 ? false : true}
                  id='add_to_cart'
                  onClick={() => addToCart('cart')}>
                  <p>ADD TO CART</p>
                </button>
                <button
                  className='flex items-center justify-center w-full h-full bg-yellowB shadow-bsFooterMob'
                  disabled={Object.keys(sellerData).length > 0 ? false : true}
                  onClick={() => addToCart('buy')}>
                  <p>BUY NOW</p>
                </button>
              </>
            ) : (
              <button
                className='flex items-center justify-center w-full h-full bg-yellowB shadow-bsFooterMob'
                id='save_for_later'
                onClick={() => saveForLater()}>
                <p>SAVE FOR LATER</p>
              </button>
            )}
          </div>
        </div>
      )}
      <Modal blockScrollOnMount={false} onClose={onClose} isOpen={isOpen} isCentered size={'xl'}>
        <ModalOverlay />
        {/* <div className='fixed left-0 font-white'> ///////////// </div> */}
        <ModalContent className='relative'>
          <div className='absolute top-[calc(50%_-_2rem)] left-0 -ml-12 font-white'>
            <Button _hover={{ opacity: '0.5' }} variant='unstyled' onClick={(e) => prevSlide()}>
              <ArrowLeftIcon color='#EDF2F7' />
            </Button>
          </div>
          <div className='absolute top-[calc(50%_-_2rem)] right-0 -mr-12  font-white'>
            <Button _hover={{ opacity: '0.5' }} variant='unstyled' onClick={(e) => nextSlide()}>
              <ArrowRightIcon color='#EDF2F7' />
            </Button>
          </div>

          {/* <ModalCloseButton /> */}
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Image src={modalLargeImage} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <SignIn isOpen={isSignIn} onClose={closeSignIn} />
    </div>
  );
};

export default Product_Detail;
