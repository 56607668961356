import React, { useEffect, useState } from "react";

import { Box, Button, Image, Flex, InputGroup, InputLeftElement, Text, useDisclosure, Radio, RadioGroup, Stack, VStack, useToast, Heading, Textarea, Divider } from "@chakra-ui/react";




export const Shipping = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    return (

        <Flex mt="100px" mb="100px" className="refundPolicy font_NunitoA" textAlign='justify' mr="15vw" ml="15vw" flexDirection='column'>
            <Text textTransform='uppercase' alignSelf='center'>
                <p><b>Shipping Policy</b></p>
            </Text>

            <Text mt="30px" mb='10px'>
                <p>This page sets out the shipping policy that applies to buyers that make a purchase at brakeup.in. If you have any questions, please contact our customer service team on+ 917025802580 or care@brakeup.in</p>
            </Text>

            <Text textTransform='uppercase' mt="30px" mb='10px'>
                <p>Shipping Options & Delivery Costs</p>
            </Text>

            <p>One among several shipping providers are selected individually for delivery of each order according to various factors like shipping costs, location of buyer etc. Therefore shipping rates will vary on each order. Selection of shipping service is solely done by computer systems at Brakeup and charges may or may not be actual charged by shipping service provider.</p>

            <Text textTransform='uppercase' mt="30px" mb='10px'>
                <p>Order Processing Time</p>
            </Text>
            <p>All orders placed at Brakeup are processed and dispatched within 5 days. However, it maybe sent early according to various sellers.</p>

            <Text textTransform='uppercase' mt="30px" mb='10px'>
                <p>Delivery Address</p>
            </Text>
            <p>Please note that we are unable to modify the delivery address once you have placed your order. No international orders are accepted.</p>

            <Text textTransform='uppercase' mt="30px" mb='10px'>
                <p>Tracking Your Order</p>
            </Text>
            <p>Once your order has been dispatched, we will communicate to you via email/sms/call with tracking information. You will be able to track your package on our website.</p>

            <Text textTransform='uppercase' mt="30px" mb='10px'>
                <p>Returns, Refunds, and Exchanges</p>
            </Text>

            <p>We want you to be pleased with your purchase - please read our return & refund policy for detailed information about our processes.</p>

        </Flex>
    )
}