import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";

import { Box, Button, color, Flex, InputGroup, InputLeftElement, Text, useDisclosure, Radio, RadioGroup, Stack, VStack, useToast } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, AlertDialogFooter, CloseButton } from '@chakra-ui/react';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,

} from "@chakra-ui/react";
import { API_URL, get, post } from "../../../services/api";

import { FiEdit } from "react-icons/fi";
import { CgClose } from "react-icons/cg";

import Input, { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import { AddressForm } from "./AddressForm";

//toast
import { FaInfoCircle, FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

export const AddressBook = (props) => {

  //toast
  const Swal = require('sweetalert2')

  const Toast2 = (title, theme, color, iconColor) => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      autofocus: false,
      toast: true,
      animation: true,
      timer: 5000,
      // timerProgressBar: true,
      iconColor:iconColor,
      icon: theme,
      title: title,
      color : color,
      
      

      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
    })
  }


  const { isOpen, onOpen, onClose } = useDisclosure()
  const [address, setAddress] = useState([]);
  const [deleteAdd, setdeleteAdd] = useState({})


  //Alert Dialog PopUp
  const {
    isOpen: isOpenDeleteAddress,
    onOpen: onOpenDeleteAddress,
    onClose: onCloseDeleteAddress
  } = useDisclosure()


  const openAlertBox = (id, user_id) => {
    setdeleteAdd({ "id": id, "user_id": user_id })
    onOpenDeleteAddress()
  }

  const submitEdit = () => {
    onCloseDeleteAddress();
    setEditItems(deleteAdd);
    onOpen();
  }

  //jsonImage


  const { priceDts } = props;

  //form
  const getAddress = () => {
    get("/api/v1/products/address/").then(res => {
      setAddress(res.data);
    }).catch(err => {
      setAddress([])
    })
  }
  const deleteAddress = () => {
    // console.log(deleteAdd, "deleteAdd");
    post("/api/v1/products/deleteaddress/", { "address_id": deleteAdd.id, "user_id": deleteAdd.user_id }).then(res => {
      onCloseDeleteAddress();
      
      Toast2("Address Removed !", "error", "#f22020")

      setdeleteAdd({})
      getAddress();

    }).catch(err => {

    })
  }
  useEffect(() => {
    getAddress();
  }, [])


  const onSubmit = (data, value) => {
    // console.log(data, value, "onSubmittttttt");
    // data.address_type = value;
    let url = "/api/v1/products/address/"
    if (value) {
      data.address_id = value;
      url = "/api/v1/products/updateaddress/"
    }
    post(url, data).then(res => {
      getAddress()
      
      Toast2("Address added Successfully !", "success", "#089520")
      onClose()

    }).catch(err => {
      
      Toast2("Address already exists !", "error", "#f22020")
    })


  }

  //radio buttons



  //pincode 
  const [editItems, setEditItems] = useState({})


  return (

    <Box className="cart-items">

      {address.length == 0 ?
        <Flex alignSelf='center' flexDirection='column' alignItems='center' className="flexbox_Empty_State" >
          <Box className="savederrorjson">
            <lottie-player
              src={API_URL + "/api/v1/json/Brakeup_Empty_State.json"}
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </Box>

          <Flex className='textbox_Empty_State' flexDir='column' alignItems='center' >
            <Text className="bold_text_Empty_State nunitosans-bold-shark " whiteSpace='nowrap' textAlign='center'  > No shipping address</Text>
            <Text className="text2_Empty_State nunitosans-normal-melanzane" whiteSpace='nowrap' textAlign='center'>Please add your shipping address </Text>
            <button className="btn_empty_state nunitosans-bold-shark" onClick={() => { onOpen(); setEditItems({}) }} >ADD ADDRESS</button>

          </Flex>
        </Flex>
        :
        <>

          {address && address.map(items => {
            // lottie display data

            const lottieData = () => {
              if (items.address_type == "home")
                return ({
                  "url": API_URL + "/api/v1/json/Home_Address.json",
                  "tag": "HOME"
                })

              else if (items.address_type == "office")
                return ({
                  "url": API_URL + "/api/v1/json/Office_Address.json",
                  "tag": "OFFICE"
                })

              else
                return ({
                  "url": API_URL + "/api/v1/json/Location_Address.json",
                  "tag": "OTHER"
                })
            };
            return (
              <Flex className="address" alignItems='center'>

                <Flex width="15%" flexDirection="column" alignItems='center'>
                  <div className="lottie_address">

                    <lottie-player src={lottieData().url}
                      background="transparent"
                      speed="1"
                      loop autoplay
                    >
                    </lottie-player>
                  </div>
                  <Text
                    className="lottie_name">{lottieData().tag}</Text>

                </Flex>


                <Flex width="70%" flexDirection='column' className="home-address">
                  <Flex className="nunitosans-normal-melanzane" >
                    <Text >{items.name}</Text>
                    <Text marginLeft='20px' >{items.mobile}</Text>
                  </Flex>
                  <Flex wrap='wrap' className="nunitosans-normal-melanzane">

                    <Flex whiteSpace='nowrap'>
                      <Text>{items.address}</Text>
                      <Text marginLeft='5px'>{items.address2}</Text>

                    </Flex >

                    <Flex whiteSpace='nowrap'>
                      <Text>{items.landmark}</Text>
                      <Text marginLeft='5px'></Text>

                    </Flex>
                  </Flex>
                  <Flex className="nunitosans-normal-melanzane">
                    <Text> {items.district} </Text>
                    <Text marginLeft='5px'> {items.state}</Text>
                    <Text marginLeft='5px'> {items.pincode}</Text>
                  </Flex>
                </Flex>

                <Flex width="10%" height="max-content" minHeight='120px' justifyContent='flex-end'>
                  <FiEdit style={{ color: '#ACACAC', cursor: "pointer" }} onClick={() => { onOpen(); setEditItems(items) }} />
                  <CgClose style={{ marginLeft: "15px", color: '#ACACAC', cursor: "pointer" }} onClick={() => { openAlertBox(items?._id["$oid"], items?.user_id["$oid"]); setEditItems(items) }} />
                </Flex>

              </Flex>)
          })}

          <Flex width="100%" justifyContent="flex-end" marginTop='40px'>
            <Button onClick={() => { onOpen(); setEditItems({}) }} className="add_address">
              ADD ADDRESS
            </Button>

          </Flex>


        </>
      }


      {/* ADD ADDRESS MODAL */}
      <AddressForm onSubmit={onSubmit} onClose={onClose} isOpen={isOpen} editItems={editItems} />
      {/* // delete popup */}
      <AlertDialog
        motionPreset='slideInBottom'
        onClose={onCloseDeleteAddress}
        isOpen={isOpenDeleteAddress}
        isCentered
        size='md'
        autoFocus={false}

      >
        <AlertDialogOverlay />

        <AlertDialogContent bgColor="var(--white-rock-2)">
          <AlertDialogCloseButton />
          <Flex justifyContent="space-evenly" padding='20px'>
            <Flex width='100px'>

              <lottie-player src={API_URL + "/api/v1/json/Delete_Basket.json"} background="transparent" speed="1" autoplay loop></lottie-player>

            </Flex>
            <Flex flexDirection='column'>
              <AlertDialogBody padding='20px'>

                <Text textAlign='flex-end' fontWeight='bolder' className="nunitosans-normal-shark-21px" mb="20px">Delete this Address?</Text>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button className="nunitosans-normal-shark-14px"

                  onClick={() => submitEdit()}
                  bgColor="transparent"
                  border="2px solid"
                  borderRadius='5px'
                  width='100px'
                  boxShadow="0px 3px 6px #00000029">
                  EDIT
                </Button>


                <Button className="nunitosans-normal-white-14px"
                  ml={3}
                  borderRadius="5px"
                  boxShadow={"0px 3px 6px #330d2f29"}
                  bgColor={"var(--royal-blue)"}
                  _hover={{ bgColor: "#727be5" }}
                  onClick={() => {
                    deleteAddress()
                  }}
                >

                  DELETE

                </Button>


              </AlertDialogFooter>
            </Flex>
          </Flex>
        </AlertDialogContent>
      </AlertDialog>



    </Box>


  )
}