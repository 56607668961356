import { Box, Flex, Input, Text } from '@chakra-ui/react';
import { FaChevronDown } from 'react-icons/fa';
import React, { useState, useEffect, useRef } from 'react';

function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
}

const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            }, { rootMargin }
        );

        element.current && observer?.observe(element.current);

        return () => observer?.unobserve(element.current);
    }, []);

    return isVisible;
};



const CustomSelect = (props) => {

    // console.log(props, "CustomSelectCustomSelect");

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const [textInput, settextInput] = useState("");
    const [isSelected, setisSelected] = useState(false);
    const [selectedValue, setselectedValue] = useState(props.value);



    let options = []
    props.options && props.options.forEach((element) => {

        if (element?.label?.toLowerCase().includes(textInput)) {
            options.push(
                <Text p={2.5} className="customselectFont" key={element.id}
                    fontWeight='bold' _hover={{ bgColor: '#ECE9D2' }} cursor={"pointer"}
                    bgColor={props.value == element.label ? "#ECE9D2" : ""} textTransform={props.name == "fuel" ? "capitalize" : "none"}
                    onClick={() => {
                        // props.selectedFilter(element.id,props.name,);
                        props.onChange(element.id, props.name, element.label);
                        setisSelected(true);
                        setselectedValue(element.label);
                        settextInput("")
                    }} >
                    {element.label}
                </Text>
            )
        }
    }
    )


    // const ref2 = useRef();
    // // const inViewport = useIntersection(ref2, '0px'); // Trigger as soon as the element becomes visible
    // const inViewport = useIntersection(ref2, '-350px'); // Trigger if 200px is visible from the element

    // if (inViewport) {
    //     console.log('in viewport:', ref2.current);
    // }

    return (

        <Box className='font_Nunito' textTransform='none' position='relative'  >

            {isComponentVisible ?

                <Flex bgColor='white' borderRadius={5} boxShadow='0px 1px 1px #00000029' cursor={'pointer'}
                    className={props.errorFlexboxstyle == 1 ? 'alerts_border' : ''}
                    style={props.flexboxStyle} pr={3} alignItems='center' ref={ref} id={props.id}
                    onClick={() => { setIsComponentVisible(true); }} >

                    <input autoFocus={isComponentVisible} style={props.inputStyle} className="customselectFont"
                        readOnly={props.readOnly}
                        onChange={e => { settextInput(e.target.value.toLowerCase());}}
                        placeholder={props.placeholder}
                        //  value={props.value ?? ""}
                        value={textInput ?? ""}
                        id={props.id}
                        type="text" />
                    {/* <FaChevronDown color='#9D9EA4' d={isComponentVisible ? "none" : "block"} /> */}

                </Flex>

                :
                <Flex bgColor='white' borderRadius={5} boxShadow='0px 1px 1px #00000029' cursor={'pointer'}
                    className={props.errorFlexboxstyle == 1 ? 'alerts_border' : ''}
                    style={props.flexboxStyle} pr={3} alignItems='center' ref={ref}
                    onClick={() => { setIsComponentVisible(true); setisSelected(false) }} >

                    <input style={props.inputStyle} className="customselectFont"
                       readOnly={props.readOnly}
                        // onChange={e => { settextInput(e.target.value.toLowerCase()); console.log(e.target.value,"e.target.value.toLowerCase()"); }}
                        placeholder={props.placeholder}
                        value={props.value ?? ""}
                        // value={selectedValue}
                        id={props.id}

                        type="text" />
                    <FaChevronDown className='FaChevronDownsize' color='#9D9EA4' d={isComponentVisible ? "none" : "block"} onClick={() => { setIsComponentVisible(false); }} />

                </Flex>

            }

            <Box
                style={props.name == "brand" ? { top: "-230px", minHeight: "220px" } : {}}
                position='absolute' zIndex={5} width="100%" mt={0} bgColor='white'
                boxShadow='0 4px 8px 0 rgba(0, 0, 0, 0.2)' borderRadius={5}
                d={isComponentVisible ? "flex" : "none"} maxHeight={props.boxheight ?? "220px"} flexDirection='column'
                overflowX='hidden' overflowY='scroll'>

                {options}


            </Box>
        </Box>
    );
}

export default CustomSelect;