import { configureStore } from '@reduxjs/toolkit'
import paymentDetails from './payment/paymentDetails'
import cartCountSlice from './user/cartCountSlice'
import categoryFilterSlice from './user/categoryFilterSlice'
import showAddressSlice from './user/showAddressSlice'
import signInIsOpenSlice from './user/signInIsOpenSlice'
import userSlice from './user/userSlice'
export const store = configureStore({
  reducer: {
    user: userSlice,
    count: cartCountSlice,
    address: showAddressSlice,
    categoryFilter: categoryFilterSlice,
    isSignInOpen: signInIsOpenSlice,
    payment: paymentDetails
  },
})