import React, { useState, useEffect, useRef, createClass } from 'react';
import { Fade, ScaleFade, Slide, SlideFade, ListItem, UnorderedList, Collapse, Box, Button, Grid, GridItem, Input, Menu, MenuButton, MenuList, MenuItem, NumberInput, NumberInputField, Flex, Spinner, VStack, Text, EditableInput } from '@chakra-ui/react';
import { API_URL } from "../services/api";

export const Loader = (props) => {
    return (
        <>
            <Flex width="100%" height='100%' 
            backdropFilter='brightness(70%) blur(2px)' 
            inset='auto' top={0} position='absolute' zIndex={10}
            id="is-load-remove">
                <Box height='7%' maxHeight='300px' margin=' 56vh auto 0'  >
                    <lottie-player
                        src={API_URL + "/api/v1/json/Brakeup_Loader_Yellow.json"}
                        background="transparent"
                        speed="1"
                        loop
                        autoplay
                    ></lottie-player>
                </Box>
            </Flex>
        </>
    )
}