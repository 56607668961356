import React from 'react';
import { Image } from '@chakra-ui/image';
import { useHistory } from 'react-router';
import admob from '../../assets/Ads/Online-Payement-Discount-Mobile-LW 1.svg';
import adWeb from '../../assets/Ads/Online-Payement-Discount-Web-LW 1.svg';

const PaymentOptions = (props) => {
  // let history = useHistory();
  return (
    // <div className=" max-w-full pt-7 pb-4 min-h-[91vh] relative bg-white font-manrope">
    //   <div className='px-4 md:px-14 lg:px-16 relative'>
    //     <p className="nxt_subheaders ">PAYMENT OPTIONS</p>
    //     <div className="ccr_divider"></div>
    //     <div className=" mt-8 flex flex-col items-center md:px-20 relative">
    //       <Image
    //         className="lg:min-w-[45vw]  lg:max-w-[48vw] min-w-[60vw] hidden md:block"
    //         src={adWeb}
    //         alt=""

    //       />
    //       <Image
    //         className="lg:min-w-[45vw]  lg:max-w-[48vw] min-w-[60vw] sm:max-h-[50vw] block md:hidden"
    //         src={admob}
    //         alt=""

    //       />

    //       <div className="mt-[5vh] flex justify-center ">
    //         <button
    //           className="pay_font14-20 flex   items-center justify-center rounded-lg bg-yellowB px-4  md:px-5  py-2 uppercase
    //           font-bold text-blueB3 shadow-bsCard focus:outline-none hover:opacity-50"
    //           type="submit"
    //           onClick={() => props.onlinePayment()}
    //         >
    //           <span>
    //             <p className='mb-1.5 font-bold'>{props.IncludeComaSeperator(props.priceDts.shiprocket_total)} Rs</p>
    //             <p className='mb-1.5 font-bold px-5'>PAY ONLINE</p>
    //           </span>
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    //   {/* innerHeight */}
    //   <div className={`${window.innerWidth <= 675 ? window.innerHeight <= 675 ? "relative" : "absolute" : window.innerHeight >= 600 ? "absolute" : "relative"} bottom-0 w-full mt-4`} >

    //     <div className="flex flex-col items-center justify-center text-xs sm:text-sm">
    //       {!props.isCODdisabled &&
    //         <button
    //           className="pay_font14-20 items-center justify-center rounded-lg bg-none px-4 md:px-5  py-2 uppercase
    //           font-bold text-blueB3 shadow-bsCard focus:outline-none mb-5 hover:opacity-50"
    //           type="submit"
    //            onClick={() => {
    // props.setPaymentMethod(1), props.TriggerCODClick();
    // }}
    //         >
    //           <div className='flex flex-col align-center'>
    //             <p className=" mb-1.5 font-bold">{props.IncludeComaSeperator(props.priceDts.shiprocket_cod_total_amount)} Rs</p>
    //             <p className=" mb-1.5 font-bold">PAY ON DELIVERY</p>
    //           </div>
    //         </button>}
    //       <p className="text-greyB3 mb-2">
    //         Additional cash handling charges apply
    //       </p>
    //     </div>
    //   </div>

    // </div>

    /* Design 2 */

    <div className='pt-7 pb-4 min-h-[91vh] relative bg-white font-manrope'>
      <div className='mx-auto max-w-screen-2xl  px-4 md:px-14 lg:px-16 relative'>
        <p className='nxt_subheaders'>PAYMENT OPTIONS</p>
        <div className='ccr_divider'></div>
        <div className=' mt-[17vh] flex flex-col items-center md:px-20 relative'>
          <div className='mt-14 flex justify-center '>
            <button
              className='pay_font14-20 flex   items-center justify-center rounded-lg bg-yellowB px-4  md:px-5  py-2 uppercase   
              font-bold text-blueB3 shadow-bsCard focus:outline-none hover:opacity-50'
              type='submit'
              onClick={() => props.onlinePayment()}
              //  onClick={() => history.push(`/order-success`)}
            >
              <span>
                <p className=' mb-1.5 font-bold'>
                  {props.IncludeComaSeperator(props.priceDts.shiprocket_total)}{' '}
                  Rs
                </p>
                <p className='mb-1.5 font-bold px-5'>PAY ONLINE</p>
              </span>
            </button>
          </div>
        </div>
      </div>
      {/* innerHeight */}
      <div
        className={`${
          window.innerWidth <= 675
            ? window.innerHeight <= 675
              ? 'relative'
              : 'absolute'
            : window.innerHeight >= 600
            ? 'absolute'
            : 'relative'
        } bottom-0 w-full mt-40 mb-20`}
      >
        <div className='flex flex-col items-center justify-center text-xs sm:text-sm  '>
          {props.isCODdisabled ? (
            <button
              disabled
              className='add_font14 items-center justify-center rounded-lg bg-greyB px-4 md:px-5  py-2 uppercase   
              font-bold text-greyB4 shadow-bsCard focus:outline-none mb-2 sm:mb-5 hover:opacity-50 cursor-not-allowed'
            >
              <div className='flex flex-col align-center'>
                {/* <p className=" mb-1.5 font-bold">
                  {props.IncludeComaSeperator(
                    props.priceDts.shiprocket_cod_total_amount
                  )}{" "}
                  Rs
                </p> */}
                <p className='font-bold'>PAY ON DELIVERY</p>
              </div>
            </button>
          ) : (
            <button
              className='pay_font14-20 items-center justify-center rounded-lg bg-none px-4 md:px-5  py-2 uppercase   
              font-bold text-blueB3 shadow-bsCard focus:outline-none mb-2 sm:mb-5 hover:opacity-50'
              type='submit'
              onClick={() => {
                props.setPaymentMethod(1), props.TriggerCODClick();
              }}
            >
              <div className='flex flex-col align-center'>
                <p className=' mb-1.5 font-bold'>
                  {props.IncludeComaSeperator(
                    props.priceDts.shiprocket_cod_total_amount
                  )}{' '}
                  Rs
                </p>
                <p className=' mb-1.5 font-bold'>PAY ON DELIVERY</p>
              </div>
            </button>
          )}

          <p className='text-greyB3 mb-2'>
            {props.isCODdisabled
              ? 'Pay on Delivery is not available for this order'
              : 'Additional cash handling charges apply'}
          </p>
        </div>
      </div>
    </div>
  );
};
export default PaymentOptions;
