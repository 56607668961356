import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Image,
  Flex,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
  VStack,
  useToast,
  Heading,
  Textarea,
  Divider,
} from '@chakra-ui/react';

export const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex
      mt='100px'
      mb='100px'
      className='refundPolicy font_NunitoA'
      textAlign='justify'
      mr='15vw'
      ml='15vw'
      flexDirection='column'>
      <Text alignSelf='center'>
        <p>
          <b>TERMS OF SERVICE AGREEMENT</b>
        </p>
      </Text>

      <Text alignSelf={'end'} mt='30px' mb='10px'>
        <p>Revised on: Never</p>
      </Text>

      <p>
        Welcome to brakeup.in. This website is owned and operated by Brakeup Internet Technologies
        Private Limited, 42 High Street, 100 Feet Road, Indiranagar, Bangalore, Karnataka-560038. By
        visiting our website and accessing the information, resources, services, products and tools
        we provide, you understand and agree to accept and adhere to the following terms and
        conditions as stated in this policy (hereinafter referred to as the ‘Agreement’), along with
        the terms and conditions as stated in our Privacy Policy (please refer to the Privacy Policy
        section below for more information). We reserve the right to change this agreement from time
        to time with or without notice. You acknowledge and agree that it is your responsibility to
        review this Agreement periodically to familiarize yourself with any modifications. Your
        continued use of this site after such modifications will constitute acknowledgment and
        agreement of the modified terms and conditions.
      </p>

      <Text mt='30px' mb='20px'>
        <p>
          PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS WEBSITE OR ORDERING
          PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF
          THIS AGREEMENT.
        </p>
      </Text>
      <p>
        This Agreement governs your use of this website brakeup.in (hereinafter referred to as the
        ‘Website’), Brakeup Internet Technologies Private Limited's offer of Products and Services
        for purchase on this Website, or your purchase of Products and Services available on this
        Website. This Agreement includes and incorporates this reference, the policies and
        guidelines referred below. Brakeup Internet Technologies Private Limited reserves the right
        to change or revise the terms and conditions of this Agreement at any time by posting any
        changes or a revised Agreement on this Website. Brakeup Internet Technologies Private
        Limited will or will not alert you that changes or revisions have been made by indicating on
        the top of this Agreement the date it was last revised. The changed or revised Agreement
        will be effective immediately after it is posted on this Website. Your use of the Website
        following the posting of any such changes or of a revised Agreement will constitute your
        acceptance of any such changes or revisions. Brakeup Internet Technologies Private Limited
        encourages you to review this Agreement whenever you visit the Website to make sure that you
        understand the terms and conditions governing use of the Website. This Agreement does not
        alter in any way the terms or conditions of any other written agreement you may have with
        Brakeup Internet Technologies Private Limited for other products or services.
      </p>
      <p>
        If you do not agree to this Agreement (including any referenced policies or guidelines),
        please immediately terminate your use of the Website.
      </p>

      <Text textTransform='uppercase' mt='40px' mb='10px'>
        <p>Responsible Use And Conduct</p>
      </Text>
      <p>
        In order to access our Resources or Products and Services, you may be required to provide
        certain information about yourself (such as identification, email, phone number, contact
        details, etc.) as part of the registration process, or as part of your ability to use the
        Resources. You agree that any information you provide will always be accurate, correct, and
        up to date. You are responsible for maintaining the confidentiality of any login information
        associated with any account you use to access our Resources/ Products and Services.
        Accordingly, you are responsible for all activities that occur under your accounts.
        Accessing (or attempting to access) any of our Resources or Products and Services by any
        means other than through the means we provide, is strictly prohibited. You specifically
        agree not to access (or attempt to access) any of our Resources or Products and Services
        through any automated, unethical or unconventional means. Engaging in any activity that
        disrupts or interferes with our Resources or Products and Services, including the servers
        and or networks to which our Resources / Products and Services are located or connected, is
        strictly prohibited. Attempting to copy, duplicate, reproduce, sell, trade, or resell our
        Resources or Products and Services is strictly prohibited. You are solely responsible for
        any consequences, losses, or damages that we may directly or indirectly incur or suffer due
        to any unauthorized activities conducted by you, as explained above, and may incur criminal
        or civil liability.
      </p>
      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Privacy:</p>
      </Text>
      <p>
        Brakeup Internet Technologies Private Limited believes strongly in protecting user privacy,
        which is why a separate Privacy Policy has been created in order to explain in detail how we
        collect, manage, process, secure, and store your private information. Please refer to
        Brakeup Internet Technologies Private Limited's privacy policy, incorporated by reference
        herein, that is posted on the Website.
      </p>
      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Products and Services-Terms of Offer:</p>
      </Text>
      <p>
        This Website offers for sale certain Products and Services (hereinafter referred to as ‘
        Products and Services’). By placing an order for Products and Services through this Website,
        you agree to the terms in this Agreement.
      </p>
      <p>
        Customer Solicitation: Unless you notify our third party call center representatives or
        direct Brakeup Internet Technologies Private Limited representatives, while they are calling
        you, of your desire to opt out from further direct company communications and solicitations,
        you are agreeing to continue to receive further emails and call solicitations from Brakeup
        Internet Technologies Private Limited and its designated in house or third party call
        team(s).
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Opt Out Procedure:</p>
      </Text>
      <p>We provide 3 easy ways to opt out of from future solicitations</p>
      <p>- You may use the opt out link found in any email solicitation that you may receive.</p>
      <p>- You may also choose to opt out, via sending your email address to: care@brakeup.in.</p>
      <p>
        - You may send a remove request to Brakeup Internet Technologies Private Limited
        representatives, while they are calling you, of your desire to opt out from further direct
        company communications and solicitations. You are agreeing to continue to receive further
        emails and call solicitations from Brakeup Internet Technologies Private Limited, 42 High
        Street, 100 Feet Road, Indiranagar, Bangalore, Karnataka-560038.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Proprietary Rights:</p>
      </Text>
      <p>
        Brakeup Internet Technologies Private Limited has proprietary rights and trade secrets in
        the Products and Services. You may not copy, reproduce, resell or redistribute any product
        manufactured and or distributed by Brakeup Internet Technologies Private Limited. Brakeup
        Internet Technologies Private Limited also has rights to all trademarks and trade dress and
        specific layouts of this webpage, including calls to action, text placement, images and
        other information.
      </p>
      <p>
        Taxes: If you purchase any Products and Services, you will be responsible for paying the
        applicable taxes (GST).
      </p>
      <p>Content, Intellectual Property, Third Party Links:</p>
      <p>
        Besides making Products and services available, this Website also offers information and
        marketing materials. This Website also offers information, both directly and through
        indirect links to third-party websites, about (information). Brakeup Internet Technologies
        Private Limited does not always create the information offered on this Website; instead the
        information is often gathered from other sources. To the extent that Brakeup Internet
        Technologies Private Limited does create the content on this Website, such content is
        protected by intellectual property laws of India, foreign nations, and international bodies.
        Unauthorized use of the material may violate copyright, trademarks, and or other laws. You
        acknowledge that your use of the content on this Website is for personal, non-commercial
        use. Any links to third-party websites are provided solely as a convenience to you. Brakeup
        Internet Technologies Private Limited does not endorse the contents on any such third-party
        websites. Brakeup Internet Technologies Private Limited is not responsible for the content
        of or any damage that may result from your access to or reliance on these third-party
        websites. If
      </p>
      <p>If You link to third-party websites, you do so at your own risk.</p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Use of Website:</p>
      </Text>

      <p>
        Brakeup Internet Technologies Private Limited is not responsible for any damages resulting
        from use of this website by anyone. You will not use the Website for illegal purposes. You
        will abide by all applicable local, state, national, and international laws and regulations
        in your use of the Website (including laws regarding intellectual property), not interfere
        with or disrupt the use and enjoyment of the Website by other users, not resell material on
        the Website, not engage, directly or indirectly, in transmission of "spam", chain letters,
        junk mail or any other type of unsolicited communication, and not defame, harass, abuse, or
        disrupt other users of the Website.
      </p>
      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>License:</p>
      </Text>

      <p>
        By using this Website, you are granted a limited, non-exclusive, non-transferable right to
        use the content and materials on the Website concerning your normal, non-commercial use of
        the Website. You may not copy, reproduce, transmit, distribute, or create derivative works
        of such content or information without express written authorization from Brakeup Internet
        Technologies Private Limited or the applicable third party (if third party content is at
        issue).
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Blogs:</p>
      </Text>

      <p>
        We may provide various open communication tools on our website, such as blog comments, blog
        posts, public chat, forums, message boards, newsgroups, product ratings and reviews, various
        social media services, etc. You understand that generally we do not pre-screen or monitor
        the content posted by users of these various communication tools, which means that if you
        choose to use these tools to submit any type of content to our website, then it is your
        responsibility to use these tools in a responsible and ethical manner. By posting
        information or otherwise using any open communication tools as mentioned, you agree that you
        will not upload, post, share, or otherwise distribute any content that:
      </p>
      <p>
        Is illegal, threatening, defamatory, abusive, harassing, degrading, intimidating,
        fraudulent, deceptive, invasive, racist, or contains any type of suggestive, inappropriate,
        or explicit language; infringes on any trademark, patent, trade secret, copyright, or other
        proprietary right of any party; contains any type of unauthorized or unsolicited
        advertising; impersonates any person or entity, including any brakeup.in or Brakeup Internet
        Technologies Private Limited employees or representatives. We have the right at our sole
        discretion to remove any content that, we feel in our judgment does not comply with this
        User Agreement, along with any content that we feel is otherwise offensive, harmful,
        objectionable, inaccurate, or violates any 3rd party copyrights or trademarks. We are not
        responsible for any delay or failure in removing such content. If you post content that we
        choose to remove, you hereby consent to such removal, and consent to waive any claim against
        us. We do not assume any liability for any content posted by you or any other 3rd party
        users of our website. However, any content posted by you using any open communication tools
        on our website, provided that it doesn't violate or infringe on any 3rd party copyrights or
        trademarks, becomes the property of Brakeup Internet Technologies Private Limited, and as
        such, gives us a perpetual, irrevocable, worldwide, royalty-free, exclusive license to
        reproduce, modify, adapt, translate, publish, publicly display and or distribute as we see
        fit. This only refers and applies to content posted via open communication tools as
        described, and does not refer to information that is provided as part of the registration
        process, necessary in order to use our Resources. All information provided as part of our
        registration process is covered by our Privacy Policy. You agree to indemnify and hold
        harmless brakeup.in a digital property of Brakeup Internet Technologies Private Limited and
        its parent company and affiliates, and their directors, officers, managers, employees,
        donors, agents, and licensors, from and against all losses, expenses, damages and costs,
        including reasonable attorneys' fees, resulting from any violation of this User Agreement or
        the failure to fulfil any obligations relating to your account incurred by you or any other
        person using your account. We reserve the right to take over the exclusive defence of any
        claim for which we are entitled to indemnification under this User Agreement. In such event,
        you shall provide us with such cooperation as is reasonably requested by us.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Posting:</p>
      </Text>

      <p>
        By posting, storing, or transmitting any content on the Website, you hereby grant Brakeup
        Internet Technologies Private Limited a perpetual, worldwide, non-exclusive, royalty-free,
        assignable, right and license to use, copy, display, perform, create derivative works from,
        distribute, have distributed, transmit and assign such content in any form, in all media now
        known or hereinafter created, anywhere in the world. Brakeup Internet Technologies Private
        Limited does not have the ability to control the nature of the user-generated content
        offered through the Website. You are solely responsible for your interactions with other
        users of the Website and any content you post. Brakeup Internet Technologies Private Limited
        is not liable for any damage or harm resulting from any posts by or interactions between
        users. Brakeup Internet Technologies Private Limited reserves the right, but has no
        obligation, to monitor interactions between and among users of the Website and to remove any
        content Brakeup Internet Technologies Private Limited deems objectionable, in Brakeup
        Internet Technologies Private Limited's sole discretion.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Disclaimer of Warranties:</p>
      </Text>

      <p>
        Your use of this website and or Products and Services are at your sole risk. The website and
        Products and Services are offered on an "as is" and "as available" basis. Brakeup Internet
        Technologies Private Limited expressly disclaims all warranties of any kind, whether express
        or implied, including, but not limited to, implied warranties of merchantability, fitness
        for a particular purpose and non-infringement with respect to the Products and Services or
        website content, or any reliance upon or use of the website content or Products and Services
        (" Products and Services" include trial Products and Services.) Without limiting the
        generality of the foregoing, Brakeup Internet Technologies Private Limited makes no
        warranty:
      </p>
      <p>
        That the information provided on this website is accurate and reliable, complete, and
        timely.
      </p>
      <p>
        That the links to third-party websites are to information that is accurate, reliable,
        complete, or timely. no advice or information, whether oral or written, obtained by you from
        this website will create any warranty not expressly stated herein as to the results that may
        be obtained from the use of the products or that defects in products will be corrected.
        regarding any Products and Services purchased or obtained through the website. Some
        jurisdictions do not allow the exclusion of certain warranties, so some of the above
        exclusions may not apply to you.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Limitation Of Liability</p>
      </Text>

      <p>
        Brakeup Internet Technologies Private Limited’s entire liability, and your exclusive remedy,
        in law, in equity, or otherwise, with respect to the website content or Products and
        Services and or for any breach of this agreement is solely limited to the amount you paid,
        less shipping and handling charges, for Products and Services purchased via this website.
        Brakeup Internet Technologies Private Limited will not be liable for any direct, indirect,
        incidental, special or consequential damages in connection with this agreement and or
        Products and Services in any manner, including liabilities resulting from:
      </p>
      <p>the use or the inability to use the website content or Products and Services;</p>
      <p>the cost of procuring substitute content or Products and Services;</p>
      <p>
        Any information obtained or Products and Services purchased or transactions entered through
        the website;
      </p>
      <p>or any lost profits you allege.</p>
      <p>
        Some jurisdictions do not allow the limitation or exclusion of liability for incidental or
        consequential damages so some of the above limitations may not apply to you. In conjunction
        with the Limitation of Liability as explained above, you expressly understand and agree that
        any claim against us shall be limited to the amount you paid, if any, for use of Products
        and Services. brakeup.in or Brakeup Internet Technologies Private Limited will not be liable
        for any direct, indirect, incidental, consequential or exemplary loss or damages which may
        be incurred by you as a result of using our Resources or Products and Services, or as a
        result of any changes, data loss or corruption, cancellation, loss of access, or downtime to
        the full extent that applicable limitation of liability laws apply.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Indemnification:</p>
      </Text>

      <p>
        You will release, indemnify, defend and hold harmless Brakeup Internet Technologies Private
        Limited, and any of its sellers, contractors, agents, employees, officers, directors,
        shareholders, affiliates and assigns from all liabilities, claims, damages, costs and
        expenses, including reasonable attorneys' fees and expenses, of third parties relating to or
        arising out of this Agreement or the breach of your warranties, representations and
        obligations under this Agreement;
      </p>
      <p>the Website content or your use of the Website content;</p>
      <p>
        the Products and Services or your use of the Products and Services (including Trial Products
        and Services);
      </p>
      <p>any intellectual property or other proprietary right of any person or entity;</p>
      <p>your violation of any provision of this Agreement; or</p>
      <p>any information or data you supplied to Brakeup Internet Technologies Private Limited.</p>
      <p>
        When Brakeup Internet Technologies Private Limited is threatened with suit or sued by a
        third party, Brakeup Internet Technologies Private Limited may seek written assurances from
        you concerning your promise to indemnify Brakeup Internet Technologies Private Limited; your
        failure to provide such assurances may be considered by Brakeup Internet Technologies
        Private Limited to be a material breach of this Agreement. Brakeup Internet Technologies
        Private Limited will have the right to participate in any defence by you of a third party
        claim related to your use of any of the Website content or Products and Services, with the
        counsel of Brakeup Internet Technologies Private Limited’s choice at its expense. Brakeup
        Internet Technologies Private Limited will reasonably cooperate in any defence by you of a
        third-party claim at your request and expense. You will have sole responsibility to defend
        Brakeup Internet Technologies Private Limited against any claim, but you must receive
        Brakeup Internet Technologies Private Limited’s prior written consent regarding any related
        settlement. The terms of this provision will survive any termination or cancellation of this
        Agreement or your use of the Website / Products and Services.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Copyrights / Trademarks</p>
      </Text>
      <p>
        All content and materials available on brakeup.in, including but not limited to text,
        graphics, website name, code, images and logos are the intellectual property of Brakeup
        Internet Technologies Private Limited and are protected by applicable copyright and
        trademark law. Any inappropriate use, including but not limited to the reproduction,
        distribution, display or transmission of any content on this site is strictly prohibited,
        unless specifically authorized by Brakeup Internet Technologies Private Limited.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Agreement To Be Bound</p>
      </Text>
      <Text textAlign='left' mt='20px' mb='10px'>
        <p>
          BY USING THIS WEBSITE OR ORDERING PRODUCTS AND SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE
          READ AND AGREE TO BE BOUND BY THIS AGREEMENT AND ALL TERMS AND CONDITIONS ON THIS WEBSITE.
        </p>
      </Text>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>General Clause:</p>
      </Text>
      <p>
        Force Majeure: Brakeup Internet Technologies Private Limited will not be deemed in default
        hereunder or held responsible for any cessation, interruption or delay in the performance of
        its obligations hereunder due to lockdown, pandemic, earthquake, flood, fire, storm, natural
        disaster, act of God, war, terrorism, armed conflict, labour strike, lockout, or boycott.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Cessation of Operation:</p>
      </Text>
      <p>
        Brakeup Internet Technologies Private Limited may at any time, in its sole discretion and
        without notice to you, cease operation of the Website and distribution of the Products and
        Services.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Entire Agreement:</p>
      </Text>
      <p>
        This Agreement comprises the entire agreement between you and Brakeup Internet Technologies
        Private Limited and supersedes any prior agreements pertaining to the subject matter
        contained herein.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Governing Law:</p>
      </Text>
      <p>
        This website is controlled by Brakeup Internet Technologies Private Limited from our office
        located in the state of Karnataka, India. It can be accessed by most countries around the
        world. As each country has laws that may differ from those of Karnataka, India, by accessing
        our website, you agree that the statutes and laws of Karnataka, India, without regard to its
        conflict of law principles to the contrary and the United Nations Convention on the
        International Sales of Goods, will apply to all matters relating to the use of this website
        and the purchase of any Products and Services through this site.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Effect of Waiver:</p>
      </Text>
      <p>
        The failure of Brakeup Internet Technologies Private Limited to exercise or enforce any
        right or provision of this Agreement will not constitute a waiver of such right or
        provision. If any provision of this Agreement is found by a court of competent jurisdiction
        to be invalid, the parties nevertheless agree that the court should endeavour to give effect
        to the parties' intentions as reflected in the provision and the other provisions of this
        Agreement remain in full force and effect.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Governing Law/Jurisdiction:</p>
      </Text>
      <p>
        This Website originates from the Bangalore, Karnataka, India. This Agreement will be
        governed by the laws of the State of Karnataka, India. It can be accessed by most countries
        around the world. As each country has laws that may differ from those of Karnataka, India
        without regard to its conflict of law principles to the contrary. This Agreement will be
        governed by the laws of the State of Karnataka, India. It can be accessed by most countries
        around the world. As each country has laws that may differ from those of Karnataka, India
        without regard to its conflict of law principles to the contrary. Neither you nor Brakeup
        Internet Technologies Private Limited prosecute any suit, proceeding or claim to enforce the
        provisions of this Agreement, to recover damages for breach of or default of this Agreement,
        or otherwise arising under or by reason of this Agreement, other than in courts located in
        the State of Karnataka, India. By using this Website or ordering Products and Services, you
        consent to the jurisdiction and venue of such courts in connection with any action, suit,
        proceeding or claim arising under or by reason of this Agreement. You hereby waive any right
        to trial by jury arising out of this Agreement and any related documents. This website is
        controlled by Brakeup Internet Technologies Private Limited from our offices located in the
        state of Karnataka, India. It can be accessed by most countries around the world. As each
        country has laws that may differ from those of Karnataka, India, by accessing our website,
        you agree that the statutes and laws of Karnataka, India without regard to the conflict of
        laws and the United Nations Convention on the International Sales of Goods, will apply to
        all matters relating to the use of this website and the purchase of any Products and
        Services through this site. Furthermore, any action to enforce this User Agreement shall be
        brought in the courts having jurisdiction over such issue, located in the Urban District of
        Bangalore, Karnataka, India. You hereby agree to judgement passed by such courts and waive
        any right to jurisdictional, venue, or inconvenient forum objections to such courts.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Statute of Limitation:</p>
      </Text>
      <p>
        You agree that regardless of any statute or law to the contrary, any claim or cause of
        action arising out of or related to use of the Website or Products and Services or this
        Agreement must be filed within six (6) months after such claim or cause of action arose
      </p>
      <p>else be forever barred.</p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='20px'>
        <p>Waiver of Class Action Rights:</p>
      </Text>
      <p>
        BY ENTERING INTO THIS AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN
        CLAIM WITH THOSE OF OTHERS IN THE FORM OF A CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY
        CLAIMS ARISING OUT OF OR RELATING TO OR IN CONNECTION WITH THIS AGREEMENT MUST BE ASSERTED
        INDIVIDUALLY.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='40px' mb='10px'>
        <p>Termination:</p>
      </Text>
      <p>
        Brakeup Internet Technologies Private Limited reserves the right to terminate your access to
        the Website if it reasonably believes, in its sole discretion, that you have breached any of
        the terms and conditions of this Agreement. Following termination, you will not be permitted
        to use the Website and Brakeup Internet Technologies Private Limited may, in its sole
        discretion and without advance notice to you, inactivate your registered account and or
        cancel any outstanding orders for Products and Services. If your access to the Website is
        terminated, Brakeup Internet Technologies Private Limited reserves the right to exercise
        whatever means it deems necessary to prevent unauthorized access of the Website. This
        Agreement will survive indefinitely unless and until Brakeup Internet Technologies Private
        Limited chooses, in its sole discretion and without advance notice to you, to terminate it.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Domestic Use:</p>
      </Text>
      <p>
        Brakeup Internet Technologies Private Limited makes no representation that the Website or
        Products and Services are appropriate or available for use in locations outside India. Users
        who access the Website from outside India do so at their own risk and initiative and must
        bear all responsibility for compliance with any applicable local laws.
      </p>
      <p>Replacement, Cancellation And Refund:</p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Replacement:</p>
      </Text>
      <p>
        A product ordered through Website can be replaced with another in the event where delivered
        product is incompatible and doesn't fit to the vehicle as mentioned in the Website. A
        replacement can only be done in 14 (fourteen) days after delivery and product to be replaced
        is in original packing, unused, unsoiled and in re-saleable condition and with all packaging
        intact. Refund or replacement product will be despatched once the product reaches the seller
        and certifies that all replacement criteria are met.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>PAYMENT:</p>
      </Text>
      <p>
        Orders with a high value may be subject to a change in payment method from Cash on Delivery
        (CoD) to online payment at the company's discretion. In such cases, customers will be
        notified before the processing of the order and given the option to proceed with the online
        payment or cancel the order. The company reserves the right to take this action to protect
        itself from fraudulent activity and ensure the safe and timely delivery of orders.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Cancellation:</p>
      </Text>
      <p>
        Cancellation of an order is not possible once the payment has been made. No refunds will be
        given except in the event of cancellation or non-performance of service by Brakeup Internet
        Technologies Private Limited.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Guarantee:</p>
      </Text>
      <p>
        Unless otherwise expressed, Brakeup Internet Technologies Private Limited and brakeup.in
        expressly disclaims all warranties and conditions of any kind, whether express or implied,
        including, but not limited to the implied warranties and conditions of merchantability,
        fitness of content or Products and Services for a particular purpose and non-infringement.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Assignment:</p>
      </Text>
      <p>
        You may not assign your rights and obligations under this Agreement to anyone. Brakeup
        Internet Technologies Private Limited may assign its rights and obligations under this
        Agreement in its sole discretion and without advance notice to you.
      </p>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>
          BY USING THIS WEBSITE OR ORDERING Products and Services FROM THIS WEBSITE YOU AGREE TO BE
          BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
        </p>
      </Text>

      <Text textTransform='uppercase' textAlign='left' mt='30px' mb='10px'>
        <p>Contact Information:</p>
      </Text>
      <p>
        If you have any questions or comments about our Terms of Service as outlined above, you can
        contact us at:
      </p>
      <p>Brakeup Internet Technologies Private Limited</p>
      <p>42 High Street, 100 Feet Road,</p>
      <p>Indiranagar, Bangalore, Karnataka-560038</p>
      <p>care@brakeup.in</p>
    </Flex>
  );
};
