import React from 'react';
import { useState, useEffect } from 'react';

import '../LoginSignup/LoginSignup.css';
import '../LoginSignup/styleguide.css';

import LineImage from '../../../assets/sarath_IMG/line-18.png';
import { LoginSignup, LoginOtp, FinalPage, ForGotPage, SignUp } from './SamPhone';

export const MobileLoginSignup = () => {
  console.log('MobileLoginSignupMobileLoginSignup');

  const [page, setPage] = useState(1);
  const [otpData, setOtpData] = useState({});

  const loginSingupData = {
    welcomeBack: 'Welcome Back',
    spanText1: 'Sign In',
    spanText2: ' to your account',
    inputType1: 'text',
    inputPlaceholder1: 'Enter Mobile Number / Email',
    inputType2: 'password',
    inputPlaceholder2: 'Enter Password',
    forgot: 'Forgot ?',
    spanText3: "By continuing, you agree to Brakeup's ",
    spanText4: 'Terms of Use ',
    spanText5: ' and ',
    spanText6: 'Privacy Policy',
    logIn: 'Log in',
    line18: LineImage,
    or: 'or',
    requestOtp: 'Request OTP',
    newToBrakeupCrea: 'New to Brakeup? Create an account here',
  };

  const otpNav = (data) => {
    setOtpData(data);
    setPage(2);
  };

  const [phone, setPhone] = useState('');

  const continueToFinalPage = (phone) => {
    setPage(4);
    setPhone(phone);
  };

  // for setting anonymous token

  const anonymous_token = JSON.parse(localStorage.getItem('token') || '{}').data
    ?.credAuthResult;

  // useEffect(() => {
  //   anonymous_token
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    anonymous_token;
  }, []);

  return (
    <>
      {(() => {
        switch (page) {
          case 1:
            return (
              <LoginSignup
                {...loginSingupData}
                otpNav={otpNav}
                setPage={setPage}
                setPhone={setPhone}
                anonymous_token={anonymous_token}
              />
            );
          case 2:
            return (
              <LoginOtp
                {...loginSingupData}
                otpData={otpData}
                setPage={setPage}
                anonymous_token={anonymous_token}
              />
            );
          case 3:
            return (
              <SignUp
                phone={phone}
                setPhone={setPhone}
                {...loginSingupData}
                otpNav={otpNav}
                continueToFinalPage={continueToFinalPage}
                setPage={setPage}
                anonymous_token={anonymous_token}
              />
            );
          case 4:
            return (
              <FinalPage
                {...loginSingupData}
                otpNav={otpNav}
                setPage={setPage}
                phone={phone}
                anonymous_token={anonymous_token}
              />
            );
          case 5:
            return (
              <ForGotPage
                {...loginSingupData}
                otpNav={otpNav}
                setPage={setPage}
                phone={phone}
                anonymous_token={anonymous_token}
              />
            );
          default:
            return null;
        }
      })()}
    </>
  );
};

// export default MobileLoginSignup;
