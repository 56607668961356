import React, { useEffect, useState, useRef } from "react";

import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Select,
  Text,
  useNumberInput,
} from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  CloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { CgClose } from "react-icons/cg";
import { API_URL, get, post } from "../../../services/api";
import { useHistory } from "react-router-dom";

//toast

import {
  FaInfoCircle,
  FaCheckCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Meta, UUID } from "../../../services/Meta-Pixel";
import { Cart_Add2CartGtag } from "../../../services/G-Tag";

export const SavedListMob = (props) => {
  let history = useHistory();

  //Alert Dialog PopUp MOVE TO CART
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [saveItems, setSaveItems] = useState([]);
  const [deleteItem, setDeleteItem] = useState({});
  const cancelRef = React.useRef();

  //Alert Dialog PopUp DELETE
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  //toast
  const Swal = require("sweetalert2");

  const Toast2 = (title, theme, color, iconColor) => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      autofocus: false,
      toast: true,
      animation: true,
      timer: 5000,
      // timerProgressBar: true,
      iconColor: iconColor,
      icon: theme,
      title: title,
      color: color,

      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  };

  const getSaveLater = () => {
    get("/api/v1/products/savelater/")
      .then((res) => {
        setSaveItems(res?.data);
      })
      .catch((err) => {
        setSaveItems([]);
      });
  };
  const removeFromSaveLater = () => {
    let input = {
      vend_id: deleteItem.vend_id,
      prod_id: deleteItem.prod_id,
    };
    post("/api/v1/products/delete/saveditems/", input)
      .then((res) => {
        setDeleteItem({});
        getSaveLater();
        onCloseDelete();

        Toast2("Item Removed !", "error", "#f22020");
      })
      .catch((res) => {});
  };

  useEffect(() => {
    getSaveLater();
  }, []);

  const addToCart = (addToCartItem) => {
    let data = {
      product: [
        {
          vend_id: addToCartItem.vend_id,
          prod_id: addToCartItem.prod_id,
          make_id: addToCartItem?.vehicle_details?.make_id ?? "",
          model_id: addToCartItem?.vehicle_details?.model_id ?? "",
          year: addToCartItem?.vehicle_details?.year ?? "",
          fueltype: addToCartItem?.vehicle_details?.fueltype ?? "",
          version: addToCartItem?.vehicle_details?.version ?? "",
          delivery_date: addToCartItem.delivery_date,
          quantity: addToCartItem.quantity,
        },
      ],
      pincode: "110001",
    };

    const eventID = UUID();

    post("/api/v1/products/addtocart/", data)
      .then((res) => {
        getSaveLater();

        window.fbq(
          "track",
          "AddToCart",
          {
            value: addToCartItem?.order_prod_price,
            currency: "INR",
            contents: [
              {
                id: addToCartItem?.brakeup_part_no,
                quantity: 1,
              },
            ],
          },
          { eventID: eventID }
        );

        let data2 = {
          currency: "INR",
          value: addToCartItem?.order_prod_price,
          contents: [
            {
              id: addToCartItem?.brakeup_part_no,
              quantity: 1,
              delivery_category: "home_delivery",
            },
          ],
        };
        Meta("AddToCart", data2, eventID);
        Cart_Add2CartGtag(addToCartItem);
      })
      .catch((err) => {});
  };

  const SavedProducts = (props) => {
    const { items, index } = props;
    const [value, setValue] = useState(items.quantity);

    //quantity select

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
      useNumberInput({
        step: 1,
        defaultValue: value,
        min: 1,
        max: items.category_quantity,
        onChange: (valueAsNumber) => handleChange(valueAsNumber),
      });

    const inc = getIncrementButtonProps();
    const dec = getDecrementButtonProps();
    const input = getInputProps();

    const handleChange = (quantity) => {
      setValue(quantity);
      changeQuantity(items, quantity);
    };

    const changeQuantity = (prod, quantity) => {
      // console.log(prod, quantity, "changequantity");
      let input = {
        vend_id: prod.vend_id,
        prod_id: prod.prod_id,
        quantity: parseInt(quantity),
      };
      post("/api/v1/products/update/savelater/", input)
        .then((res) => {
          props.getSaveLater();
        })
        .catch((res) => {});
    };

    const ToProductDetailPage = (products) => {
      history.push(
        `/product/${products?.prod_id}${
          products.vehicle_details?.make_id
            ? "/" +
              products.vehicle_details?.make_id +
              "/" +
              products.vehicle_details?.model_id +
              "/" +
              products.vehicle_details?.year +
              (products.vehicle_details?.version
                ? "/" + products.vehicle_details?.version
                : "") +
              (products.vehicle_details?.fueltype
                ? "/" + products.vehicle_details?.fueltype
                : "")
            : ""
        }${
          products.vehicle_details?.type
            ? "/" + products.vehicle_details?.type
            : ""
        }`
      );
    };

    return (
      <Flex className='mob_cart-items_1s' justifyContent='space-between'>
        <Flex className='mob_overlap-1s'>
          <Flex flexDirection='column' alignItems='flex-start'>
            <img
              className='mob_brake-pad-12s'
              src={items?.image[0]}
              onClick={() => {
                ToProductDetailPage(items);
              }}
            />

            <Flex className='mob_quantity-14'>
              <div className='mob_quantity-15 nunitosans-normal-melanzane'>
                Quantity:
              </div>

              <HStack ml='4px' className='mob_group-32001'>
                <Button fontSize='14px' className='mob_rectangle-5404' {...dec}>
                  -
                </Button>
                <Input
                  border='none'
                  fontSize='12px'
                  marginLeft={0}
                  className='mob_rectangle-5244'
                  {...input}
                />
                <Button
                  fontSize='14px'
                  marginLeft={0}
                  className='mob_rectangle-5403'
                  {...inc}
                >
                  +
                </Button>
              </HStack>
            </Flex>
            {/* <Flex className="mob_delivery-by-12s">
                            <div className="mob_delivery-by-13 nunitosans-normal-melanzane">Delivery by:</div>

                            <div className="mob_x15th-september-8s nunitosans-normal-melanzane">
                                <span>{items.delivery_date}</span>
                            </div>
                        </Flex> */}
          </Flex>
          <Flex className='mob_flex-col-21s'>
            <div
              className='mob_brembo-22 nunitosans-black-venus'
              onClick={() => {
                ToProductDetailPage(items);
              }}
            >
              {items.brand}
            </div>
            <div
              className='mob_front-brake-pads-7 nunitosans-normal-melanzane'
              onClick={() => {
                ToProductDetailPage(items);
              }}
            >
              {items.prod_name}{" "}
            </div>
            <Flex className='mob_detailbox' alignItems={"center"}>
              <img
                className='mob_logo-logo-png'
                src={items.vehicle_details.logo}
              />
              <div className='mob_x530ds nunitosans-semi-bold-black-pearl'>
                {items.vehicle_details.model}
              </div>
              <div className='mob_f30s nunitosans-semi-bold-black-pearl'>
                {items.vehicle_details.version}
              </div>
              <div className='mob_x2015s nunitosans-semi-bold-black-pearl'>
                {items.vehicle_details.year}
              </div>
              <div className='mob_diesels nunitosans-semi-bold-black-pearl'>
                {items.vehicle_details.fueltype}
              </div>
            </Flex>
            {/* <Flex className="mob_dispatch-from-7s nunitosans-light-black-pearl">
                            <div className="mob_dispatch-from-8">Dispatch from:</div>
                            <div className="mob_place-30">{items.vendor_city}</div>
                        </Flex> */}
            <Flex alignItems='baseline' mt='5px'>
              {items.stock_status == "instock" ? (
                <div className='mob_in-stock-9 nunitosans-normal-forest-green'>
                  Instock
                </div>
              ) : (
                <div
                  className='mob_out_of_stock nunitosans-normal-forest-green'
                  style={{ color: " var(--orange-red)" }}
                >
                  Out of stock
                </div>
              )}
            </Flex>
            {items.stock_status == "instock" && (
              <Button
                className='mob_add_to_cart nunitosans-normal-melanzane'
                id='add_to_cart'
                height='1.5rem'
                borderRadius='3px'
                bgColor='var(--white)'
                onClick={() => {
                  props.onOpen();
                  props.addToCart(items);
                }}
              >
                {" "}
                Add to Cart
              </Button>
            )}
          </Flex>

          <CgClose
            className='mob_closebtns'
            color='grey'
            style={{ cursor: "pointer", fontSize: "1rem" }}
            onClick={() => {
              props.setDeleteItem(items);
              props.onOpenDelete();
            }}
          />

          <Flex className='mob_flex-col-22s'>
            <Flex className='mob_product-1-price-6'>
              {items.discount_percentage == 0 ? (
                ""
              ) : (
                <>
                  <div className='mob_x30-off-15 manrope-normal-forest-green '>
                    (
                    {items.discount_percentage
                      ? items.discount_percentage.toFixed(0)
                      : ""}
                    % OFF)
                  </div>
                  <Flex>
                    <div
                      style={{ marginRight: "5px" }}
                      className='mob_text-7-2 manrope-medium-venus'
                    >
                      ₹{items.total_mrp}
                    </div>
                    <div
                      style={{ textDecoration: "none" }}
                      className='mob_text-7-2 nunitosans-normal-orange-red-14px'
                    >
                      -{items.discount_price}
                    </div>
                  </Flex>
                </>
              )}
              <div className='mob_rs-191800-11 manrope-medium-black-pearl'>
                Rs. {items.order_prod_price}
              </div>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <Flex
        bgColor='white'
        flexDirection='column'
        alignItems='center'
        minH='77vh'
        width={"100%"}
      >
        <Text className='mob_address_head_font' mt='30px'>
          Saved List
        </Text>

        <Flex flexDirection='column' mt='30px' mb='30px' width='100%'>
          {saveItems.length == 0 ? (
            <Flex
              alignSelf='center'
              flexDirection='column'
              alignItems='center'
              className='flexbox_Empty_State'
            >
              <Box className='savederrorjson'>
                <lottie-player
                  src={API_URL + "/api/v1/json/Brakeup_Empty_State.json"}
                  background='transparent'
                  speed='1'
                  loop
                  autoplay
                ></lottie-player>
              </Box>

              <Flex
                className='textbox_Empty_State'
                flexDir='column'
                alignItems='center'
              >
                <Text
                  className='bold_text_Empty_State nunitosans-bold-shark '
                  whiteSpace='nowrap'
                  textAlign='center'
                >
                  {" "}
                  No items saved for later
                </Text>
                <Text
                  className='text2_Empty_State nunitosans-normal-melanzane'
                  whiteSpace='nowrap'
                  textAlign='center'
                >
                  Please add your items from cart
                </Text>
                <button
                  className='btn_empty_state nunitosans-bold-shark'
                  onClick={() => history.push("/cart")}
                >
                  Cart
                </button>
              </Flex>
            </Flex>
          ) : (
            <>
              {saveItems &&
                saveItems.map((items, index) => {
                  return (
                    <SavedProducts
                      items={items}
                      index={index}
                      getSaveLater={getSaveLater}
                      addToCart={addToCart}
                      onOpen={onOpen}
                      setDeleteItem={setDeleteItem}
                      onOpenDelete={onOpenDelete}
                      removeFromSaveLater={removeFromSaveLater}
                    />
                  );
                })}
            </>
          )}
        </Flex>
      </Flex>

      <AlertDialog
        motionPreset='slideInBottom'
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size='md'
        autoFocus={false}
      >
        <AlertDialogOverlay />

        <AlertDialogContent bgColor='' borderRadius={"3px"}>
          <AlertDialogCloseButton />
          <Flex justifyContent='space-evenly' padding='10px'>
            <Flex width='75px'>
              <lottie-player
                src={API_URL + "/api/v1/json/Cart_Successful.json"}
                background='transparent'
                speed='1'
                autoplay
                loop
              ></lottie-player>
            </Flex>
            <Flex flexDirection='column'>
              <AlertDialogBody padding='20px'>
                <Text
                  textAlign='flex-end'
                  fontWeight='bolder'
                  className='mob_alertfont nunitosans-normal-shark'
                  mb='20px'
                >
                  Item moved to Cart
                </Text>
              </AlertDialogBody>
              <AlertDialogFooter padding={"5px"}>
                <Button
                  className='mob_nunitosans-normal-shark'
                  size={"sm"}
                  onClick={() => {
                    onClose();
                  }}
                  bgColor='var(--white)'
                  borderRadius='3px'
                  boxShadow='0px 3px 6px #00000029'
                >
                  Close
                </Button>

                <Button
                  className='mob_nunitosans-normal-shark'
                  size={"sm"}
                  ml={3}
                  borderRadius='3px'
                  boxShadow={"0px 3px 6px #330d2f29"}
                  bgColor={"var(--white-rock-2)"}
                  onClick={() => {}}
                >
                  View Cart
                </Button>
              </AlertDialogFooter>
            </Flex>
          </Flex>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onCloseDelete}
        isOpen={isOpenDelete}
        isCentered
        size={"md"}
        autoFocus={false}
      >
        <AlertDialogOverlay />

        <AlertDialogContent bgColor='' borderRadius={"3px"}>
          <AlertDialogCloseButton />

          <AlertDialogBody>
            <AlertDialogHeader
              className='mob_alertfont  nunitosans-normal-shark'
              mt='20px'
            >
              Are you sure want to remove this item?
            </AlertDialogHeader>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              className='mob_nunitosans-normal-shark'
              size={"sm"}
              onClick={() => {
                removeFromSaveLater();
              }}
              bgColor='var(--white)'
              borderRadius='3px'
              boxShadow='0px 3px 6px #00000029'
            >
              Delete
            </Button>

            <Button
              className='mob_nunitosans-normal-shark'
              size={"sm"}
              ml={3}
              borderRadius='3px'
              boxShadow={"0px 3px 6px #330d2f29"}
              bgColor={"var(--white-rock-2)"}
              onClick={() => {
                setDeleteItem({});
                onCloseDelete();
              }}
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
