import { Dialog, Transition } from "@headlessui/react";
import { Image } from "@chakra-ui/react";
import { Fragment, useRef } from "react";
import { useEffect } from "react";

import dCross from "../../assets/sarath_IMG/x.svg";

export default function Successmodal({
  isOpen,
  setIsOpen,
  message,
  manualclose,
}) {
  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    if (manualclose) {
      return;
    } else {
      setTimeout(() => {
        closeModal();
      }, 3000);
    }
  }, [isOpen]);

  const completeButtonRef = useRef(null);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeModal}
          initialFocus={completeButtonRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md overflow-hidden rounded-2xl bg-white p-2 text-center align-middle shadow-xl transition-all">
                  <div className="flex justify-end">
                    <button onClick={closeModal} autoFocus={false}>
                      <Image width={25} height={25} src={dCross} alt="" />
                    </button>
                  </div>

                  <div>
                    <div className="flex flex-col items-center">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        <div>
                          <svg className="cne_checkmark" viewBox="0 0 52 52">
                            <circle
                              className="checkmark__circle"
                              cx="26"
                              cy="26"
                              r="25"
                              fill="none"
                            />
                            <path
                              className="checkmark__check"
                              fill="none"
                              d="M14.1 27.2l7.1 7.2 16.7-16.8"
                            />
                          </svg>
                        </div>
                      </Dialog.Title>
                    </div>
                    <div className="my-3 flex justify-center text-base font-bold">
                      <div ref={completeButtonRef}>{message}</div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
