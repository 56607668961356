import React from 'react'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  Spacer,
} from '@chakra-ui/react'
import './css/ItemDisplay.css'
import { FaStar } from 'react-icons/fa'

import REAR from '../../assets/sarath_IMG/REAR_ICON.png'
import FRONT from '../../assets/sarath_IMG/FRONT_ICON.png'

function ItemDisplay(props) {
  let items = []

  props.products &&
    props.products.forEach((element) => {
      items.push(
        <Box
          className="item_box"
          bg="#FFFFFF"
          height="max-content"
          position="relative"
          w={props.width ? props.width : 'auto'}
          m={1}
          p="1rem"
          borderRadius="lg"
          onClick={() => props.onBoxClick(element)}
        >
          {element?.axle && (
            <Flex
              justify="flex-end"
              position="absolute"
              right={0}
              zIndex={1}
              className="desktop_only"
            >
              {element?.axle == 'Front' ? (
                <Image
                  minWidth="40px"
                  width="4vw"
                  maxWidth="70px"
                  src={FRONT}
                />
              ) : element?.axle == 'Rear' ? (
                <Image minWidth="40px" width="4vw" maxWidth="70px" src={REAR} />
              ) : (
                ''
              )}
            </Flex>
          )}

          <Box
            className="item_box_image image_transition"
            p={1}
            justifyContent="center"
            backgroundImage={`url('${element.image[0]}')`}
            backgroundPosition="center"
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            position="relative"
          >
            {element?.axle && (
              <Box className="mobile_only">
                <Flex
                  justify="flex-end"
                  position="absolute"
                  width="100%"
                  right="0px"
                  zIndex={1}
                >
                  {element?.axle == 'Front' ? (
                    <Image
                      minWidth="40px"
                      width="4vw"
                      maxWidth="70px"
                      src={FRONT}
                    />
                  ) : element?.axle == 'Rear' ? (
                    <Image
                      minWidth="40px"
                      width="4vw"
                      maxWidth="70px"
                      src={REAR}
                    />
                  ) : (
                    ''
                  )}
                </Flex>
              </Box>
            )}
          </Box>
          <Box>
            <Spacer />
            <Grid
              visibility={'hidden'}
              className="rating_box font_Nunito"
              justifyItems="flex-end"
            >
              <Flex
                justifyContent="end"
                alignItems="center"
                className="ratingbox"
                bgColor={element.rating > 3.0 ? '#279043' : '#905827'}
              >
                <Box>{element.rating ? element?.rating.toFixed(1) : ''}</Box>
                <Box pl="2px" pr="2px">
                  {' '}
                  <FaStar />
                </Box>
              </Flex>
              <Box className="reviewCount font_Nunito">
                {' '}
                {element.reviewCount} Ratings
              </Box>
            </Grid>
            <Flex alignItems="end" className="companyname font_NunitoUp">
              {element.brand}
            </Flex>

            <Box
              className="title font_Nunito"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {element.prod_name}
            </Box>
            <Flex
              className="item_box_price font_Nunito "
              justifyContent="flex-start"
              flexWrap="nowrap"
              alignItems="center"
              whiteSpace="nowrap"
            >
              <Text
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                className="sellingPrice "
              >
                Rs. {element?.vendor_products?.selling_price}
              </Text>
              {element?.vendor_products?.discount ? (
                <>
                  <Text
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    pl="4px"
                    className="costPrice"
                  >
                    {' '}
                    ₹{element.mrp}
                  </Text>
                  {element?.vendor_products?.discount.toFixed(0) != 0 && (
                    <Text
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      pl="4px"
                      className="discountPercentage"
                    >
                      (
                      {element?.vendor_products?.discount
                        ? element?.vendor_products?.discount.toFixed(0)
                        : ''}
                      % OFF)
                    </Text>
                  )}
                </>
              ) : (
                ''
              )}
            </Flex>
            <Flex className="MobSellingPrice font_Nunito" display="none">
              <Box>Rs. {element?.vendor_products?.selling_price} </Box>
            </Flex>
          </Box>
        </Box>,
      )
    })

  return <>{items}</>
}

export default ItemDisplay
