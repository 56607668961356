import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { API_URL } from "../../../services/api";


export const GiftCoupons = (props) =>{
    return(  
      
      <Flex flexDirection='column' width="100%" alignItems='center'> 
        
        <div className="rectangle-json">
          <lottie-player
            src={API_URL + "/api/v1/json/Gift_Coupons.json"}
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
        <div className="we-do-not-have-gifting-options-right-now-gX6Hov nunitosans-normal-shark">
          We do not have gifting options right now!
        </div>
        <Box height={"15px"} width="100%">
        <div className="if-you-have-a-promoc-YLfheh">If you have a promocode apply in the cart to the offer</div>

        </Box>

    </Flex>
  )
}