import React, { useEffect, useState } from "react";

import { Box, Button, Image, Flex, InputGroup, InputLeftElement, Text, useDisclosure, Radio, RadioGroup, Stack, VStack, useToast, Heading, Textarea, Divider } from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'

import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, AlertDialogFooter, CloseButton } from '@chakra-ui/react';

import { API_URL } from "../../services/api";

import "./Replacement.css"
import { BankAccountDetails, BankAccountInput, RefundedMessage, RefundProceed, RequestMessage } from "./ReplacementModals";
import { get, post } from "../../services/api";
import { useForm } from "react-hook-form";
import { useParams, } from "react-router-dom";
import moment from 'moment'

import { useHistory } from "react-router-dom";


export const ReplacementMob = (props) => {


    let history = useHistory();


    // modal content swapping
    const [modalContent, setModalContent] = useState("");
    let { order_id, prod_id, vendor_id, user_id } = useParams();


    //modal
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const [orderHistory, setOrderHistory] = useState([])
    const getOrderHistory = () => {
        get(`/api/v1/products/buyer/buyer_replace_request/?order_id=${order_id}&prod_id=${prod_id}`).then(res => {
            setOrderHistory(res?.data);
        }).catch(err => {
            setOrderHistory([])
        })
    }
    useEffect(() => {
        getOrderHistory();
    }, [])

    // for styling mobile modals

    const [modalmob, setmodalmob] = useState(false);


    // Refund Proceed popup 

    const {
        isOpen: isOpenRefund,
        onOpen: onOpenRefund,
        onClose: onCloseRefund
    } = useDisclosure()


    const {
        isOpen: isOpenEmailInput,
        onOpen: onOpenEmailInput,
        onClose: onCloseEmailInput
    } = useDisclosure()


    //is_onlineKey

    const [onlineKey, setonlineKey] = useState('');
    const [discountAmount, setdiscountAmount] = useState('');



    // Onsubmit

    const {
        handleSubmit,
        register, errors, control, reset
    } = useForm({ mode: 'onSubmit' });

    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
        setValue: setValue2
    } = useForm({ mode: 'onSubmit' });



    //save email for editing

    const token = JSON.parse(localStorage.getItem("token")).data.credAuthResult
    const [descriptionData, setdescriptionData] = useState('');



    useEffect(() => {
        setTimeout(() => {
            if (Object.keys(token).length > 0) {
                setValue2("email", token?.email);
                // console.log(token?.email, "setEmail");
            }
        }, 1000)
    }, [descriptionData]);


    const validateEmail = (email) => {
        // Email  validations
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const result = pattern.test(email);
        if (result == true) {
            return true
        }
        return false;
    };


    const onSubmit = (des) => {

        setdescriptionData(des);
        onOpenEmailInput();

    }


    const ConfirmEmailAndData = (data) => {
        let input = {
            "user_id": user_id,
            "order_id": order_id,
            "prod_id": prod_id,
            "vendor_id": vendor_id,
            "status": "Request Placed",
            "description": descriptionData.description,
            "email": data.email
        }
        setValue2("email", data?.email);

        post("/api/v1/products/buyer/buyer_replace_request/", input).then(res => {
            onCloseEmailInput();
            onOpen();
            setModalContent(1)
            getOrderHistory();
            reset({
                description: '',
            })
            setmodalmob(true)
        }).catch(err => {

        })

    }



    const checkPaymentMethod = () => {

        let input = {
            "user_id": user_id,
            "order_id": order_id,
            "prod_id": prod_id,
            "refund_process": "true"
        }

        post("/api/v1/products/buyer/refund_process/", input).then(res => {

            if (onlineKey == true) {
                onOpen();
                setModalContent(4)
            }
            else {
                onOpen();
                setModalContent(2);
            }
        }).catch(err => {

        })
    }

    const [accDtls, setAccDtls] = useState({});
    const getAccountDetails = (d) => {
        setAccDtls(d);
        setModalContent(3);
    }


    return (<>
        <Flex bgColor='white'>
            <Flex flexDirection='column' alignItems='center' mr="2vw" ml="2vw" width='100%'>

                <Text mt='20px'>Replacement</Text>

                <Flex mt="20px" mb="20px" bgColor='#F5F5F5' width='100%' justifyContent='center' alignItems='center' borderRadius='5px'>
                    <Text p={4}>Replacement as product delivered is incompatible</Text>
                </Flex>

                <form className="Replacement_form">

                    <textarea className="descriptionbox_mob"
                        placeholder="Type brief description..."
                        name="description"
                        ref={register({
                            required: true,
                        })}
                    />
                    <div style={{ textAlign: "right", height: '30px' }}  >
                        {errors.description && <span style={{ color: "red" }} >This field is required</span>}

                    </div>

                    <button 
                    disabled={orderHistory && orderHistory[0]?.replace_requested == true ? true : false} onClick={handleSubmit((d) => onSubmit(d))} className="submit_btn_replcmnt_mob">Submit</button>

                </form>
                <Box width='100%' mt='20px' mb='20px'>


                    {orderHistory && orderHistory.map(items => {
                        return (
                            <Flex mt="10px" width='100%' boxShadow='0px 0px 3px #00000033' p="10px 10px 40px 30px">
                                <Flex flexDirection='column' alignItems='flex-start' width='30%' >
                                    <Text className="orderdataL_mob">Order ID</Text>
                                    <Text className="orderdataL_mob">Product ID</Text>
                                    <Text className="orderdataL_mob">Date & Time</Text>
                                    <Text className="orderdataLS_mob">Status</Text>
                                </Flex>
                                <Flex flexDirection='column' alignItems='flex-start' width='70%'>
                                    <Text className="orderdataR_mob">{items.order_id}</Text>
                                    <Text className="orderdataR_mob">{items.prod_name}</Text>
                                    <Text className="orderdataR_mob">{moment(items?.order_status_history[0]?.created_at["$date"]).format("DD MMM YYYY hh:mm a")}</Text>
                                    <Flex className="orderdataRR_mob" justifyContent='space-between' width='100%' >
                                        <Text >{items?.order_status_history[0]?.status}</Text>
                                        <button onClick={() => { onOpenRefund(); setonlineKey(items.is_online); setdiscountAmount(items.refund_amount) }} className="btn_in_mob"> Proceed</button>

                                    </Flex>

                                    <Text className="orderdataRRDD_mob">{items?.order_status_history[0]?.description}</Text>
                                </Flex>

                            </Flex>
                        )
                    })}

                    {/* <Flex mt="10px" width='100%' boxShadow='0px 0px 3px #00000033' p="10px 10px 40px 30px">
                        <Flex flexDirection='column' alignItems='flex-start' width='30%' >
                            <Text className="orderdataL_mob">Order ID</Text>
                            <Text className="orderdataL_mob">Product ID</Text>
                            <Text className="orderdataL_mob">Date & Time</Text>
                            <Text className="orderdataLS_mob">Status</Text>

                        </Flex>

                        <Flex flexDirection='column' alignItems='flex-start' width='70%'>
                            <Text className="orderdataR_mob">09-33883883-0999</Text>
                            <Text className="orderdataR_mob">BRQ332233</Text>
                            <Text className="orderdataR_mob">12-01-2022 01:00PM</Text>
                            <Flex className="orderdataRR_mob" justifyContent='space-between' width='100%' >
                                <Text >Request accepted</Text>
                                <button onClick={() => { onOpenRefund(), setmodalmob(true) }} className="btn_in_mob">PROCEED</button>
                            </Flex>

                            <Text className="orderdataRRDD_mob">Request has been cancelled and a refund of Rs 3412 has been initiated to Acc No. xxxxx3233</Text>
                        </Flex>

                    </Flex> */}


                </Box>

                <button onClick={() => history.push("/customercare")} className="btn_cus_care_mob">Contact Customer Care</button>

            </Flex>

            <Modal isOpen={isOpen} onClose={onClose} size={"sm"} isCentered autoFocus={false}>
                <ModalOverlay />
                {(() => {
                    switch (modalContent) {
                        case 1:
                            return <RequestMessage modalmob={modalmob} onClose={onClose} cancelRef={cancelRef} />
                        case 2:
                            return <BankAccountInput modalmob={modalmob} onClose={onClose} cancelRef={cancelRef} setModalContent={setModalContent} getAccountDetails={getAccountDetails} />
                        case 3:
                            return <BankAccountDetails modalmob={modalmob} onClose={onClose} cancelRef={cancelRef} setModalContent={setModalContent} accDtls={accDtls} />
                        case 4:
                            return <RefundedMessage modalmob={modalmob} onClose={onClose} cancelRef={cancelRef} discountAmount={discountAmount} />
                    }
                })()}

            </Modal>


            {/* Refund Proceed popup */}

            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onCloseRefund}
                isOpen={isOpenRefund}
                isCentered
                size={'sm'}
                autoFocus={false}
            >
                <AlertDialogOverlay />

                <AlertDialogContent bgColor='var(--cultured-pearl)'>
                    <AlertDialogCloseButton size="sm" color='var(--mountain-mist)' />

                    <AlertDialogBody mb="10px" display='flex' justifyContent='center'>
                        <Text className="m1font nunitosans-normal-melanzane" mt="40px">Refund amount Rs 3,412 to source of payment?
                        </Text>
                    </AlertDialogBody>
                    <AlertDialogFooter mb="1rem">
                        <Button className=" refundCancelBtn nunitosans-normal-shark"

                            onClick={() => {
                                onCloseRefund();
                            }}
                            size="sm"
                            bgColor="transparent"
                            borderRadius='5px'
                            boxShadow="0px 3px 6px #00000029">

                            CANCEL
                        </Button>

                        <Button className=" refundProceedBtn nunitosans-normal-melanzane"
                            size="sm"
                            ml={3}
                            borderRadius="5px"
                            boxShadow={"0px 3px 6px #330d2f29"}
                            bgColor={"var(--white-rock)"}
                            _hover={{ bgColor: "none" }}
                            onClick={() => { onCloseRefund(); checkPaymentMethod() }}
                        >
                            PROCEED

                        </Button>

                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>

            <AlertDialog
                motionPreset='slideInBottom'
                onClose={onCloseEmailInput}
                isOpen={isOpenEmailInput}
                isCentered
                size={'sm'}
            >
                <AlertDialogOverlay />

                <AlertDialogContent bgColor='var(--cultured-pearl)' alignItems='center'>
                    <AlertDialogCloseButton size="sm" color='var(--mountain-mist)' />
                    <AlertDialogHeader mt="20px" >Confirm Email ?</AlertDialogHeader>

                    <AlertDialogBody width='90%' display='flex' justifyContent='center' flexDirection='column'>
                        <form style={{ width: "100%", height: '71px' }}>

                            <input type="email" name="email" autoFocus={true} ref={register2({ required: true, validate: validateEmail })} className="inputbox_addAccDetails" placeholder='Enter Email' ></input>

                            {errors2.email &&
                                errors2.email.type &&
                                errors2.email.type == "required"
                                ?
                                <span style={{ color: "red" }}>email is required</span> : ""}
                            {errors2.email &&
                                errors2.email.type &&
                                errors2.email.type == "validate"
                                ?
                                <span style={{ color: "red" }}>invalid email</span> : ""}

                        </form>
                    </AlertDialogBody>
                    <AlertDialogFooter mb="1rem">

                        <Button className=" refundProceedBtn nunitosans-normal-melanzane"
                            size="sm"
                            ml={3}
                            borderRadius="5px"
                            boxShadow={"0px 3px 6px #330d2f29"}
                            bgColor={"var(--white-rock)"}
                            _hover={{ bgColor: "none" }}
                            onClick={handleSubmit2((d) => ConfirmEmailAndData(d))}
                        >
                            SUBMIT

                        </Button>

                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </Flex>
    </>)
}