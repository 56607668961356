import { Image } from '@chakra-ui/react';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import tickmark from '../../assets/sarath_IMG/tickmark.svg';
import crossmark from '../../assets/sarath_IMG/crossmark.svg';
import dCross from '../../assets/sarath_IMG/x.svg';
import { useForm } from 'react-hook-form';
import { Toast2 } from '../../views/cart';
import { post } from '../../services/api';

const ValidErrorInputBox = (props) => {
  return (
    <>
      <div className='absolute top-4 right-2.5 flex '>
        {props?.valid && <Image className='mr-2' width={5} height={5} src={tickmark} alt='' />}
        {props?.errors || props?.invalidPin ? (
          <Image className='mr-2' width={4} height={4} src={crossmark} alt='' />
        ) : (
          ''
        )}
      </div>
      <div style={{ minHeight: '28px', width: '100%' }}>
        <span className='ml-3 font-normal opacity-50  text-redB md:text-sm'>
          {props?.errors?.message}
        </span>
      </div>
    </>
  );
};

export default function AddNewAddressModal({
  isOpen,
  setIsOpen,
  message,
  editAddress,
  getAddress,
  setEditAddress,
}) {
  const completeButtonRef = useRef(null);

  const [fullName, setFullName] = useState('');
  const [address, setAddress] = useState('');
  const [streetName, setStreetName] = useState('');
  const [landmark, setLandmark] = useState('');

  const [pinLoader, setPinLoader] = useState(false);
  const [validPin, setValidPin] = useState(false);
  const [invalidPin, setInvalidPin] = useState(false);
  const [validMob, setValidMob] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  function closeModal() {
    setIsOpen(false);
    reset();
    setEditAddress({});
    setPinLoader(false);
    setValidPin(false);
    setInvalidPin(false);
    setValidMob(false);
  }

  //save data for editing

  useEffect(() => {
    // console.log(editAddress, "editAddresseditAddresseditAddress");
    setTimeout(() => {
      if (Object.keys(editAddress).length > 0) {
        setValue('address', editAddress?.address);
        setValue('address2', editAddress?.address2);
        setValue('address_type', editAddress?.address_type);
        setValue('district', editAddress?.district);
        setValue('email', editAddress?.email);
        setValue('landmark', editAddress?.landmark);
        setValue('mobile', editAddress?.mobile);
        setValue('name', editAddress?.name);
        setValue('pincode', editAddress?.pincode);
        setValue('state', editAddress?.state);
      }
    }, 1000);
  }, [editAddress]);

  const onChangePincode = (e) => {
    // console.log(e.target.value, "ewrerwerwe");
    if (e.target.value?.length == 6) {
      setValidPin(false);
      setInvalidPin(false);
      setPinLoader(true);

      fetch(`https://api.postalpincode.in/pincode/${e.target.value}`, {
        method: 'get',
      })
        .then((response) => response.json())
        .then((res) => {
          setPinLoader(false);
          setValidPin(true);
          setInvalidPin(false);
          setValue('district', res[0].PostOffice[0].District);
          setValue('state', res[0].PostOffice[0].State);
          clearErrors(['district', 'state']);
          if (res.Status == 'Error') {
            setValidPin(false);
            setInvalidPin(true);
          }
        })
        .catch((err) => {
          setPinLoader(false);
          setValidPin(false);
          // setInvalidPin(true);
          setValue('district', '');
          setValue('state', '');
        });
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const capitalizedValue = inputValue.replace(/\b\w/g, (char) => char.toUpperCase());
    if (e.target.name === 'name') {
      setValue('name', capitalizedValue);
    } else if (e.target.name === 'address') {
      setValue('address', capitalizedValue);
    } else if (e.target.name === 'address2') {
      setValue('address2', capitalizedValue);
    } else if (e.target.name === 'landmark') {
      setValue('landmark', capitalizedValue);
    } else if (e.target.name === 'district') {
      setValue('district', capitalizedValue);
    } else if (e.target.name === 'state') {
      setValue('state', capitalizedValue);
    }
  };

  const validatePhone = (e) => {
    setValidMob(false);
    // Phone number validations
    const input = e.target.value;
    const re = /^[6-9]\d{9}$/g;
    return setValidMob(re.test(input));
  };

  const onSubmitAddress = async (data) => {
    // console.log(data, "onsubmitttt");
    data.address_type = 'home';
    let value = editAddress?._id?.$oid;
    let url = '/api/v1/products/address/';
    if (value) {
      data.address_id = value;
      url = '/api/v1/products/updateaddress/';
    }

    // console.log(url, data, value, "tdyfguhjgfdtyfugh");
    post(url, data)
      .then((res) => {
        closeModal();
        getAddress();
        Toast2('Address added Successfully !', 'success', '#089520');
        setEditAddress({});
      })
      .catch((err) => {
        if (err?.response?.data?.status == 401) {
          Toast2('Address already exists !', 'error', '#f22020');
        }
      });
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10 p-5 '
          onClose={() => {
            closeModal();
          }}
          initialFocus={completeButtonRef}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto '>
            <div className='flex items-center justify-center min-h-full '>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='w-full max-w-md px-5 py-2 overflow-hidden align-middle transition-all bg-white shadow-xl rounded-2xl'>
                  <div className='flex justify-end'>
                    <button onClick={closeModal} autoFocus={false}>
                      <Image width={25} height={25} src={dCross} alt='' />
                    </button>
                  </div>

                  <div className='gap-3 mt-1 mb-5 '>
                    <div>
                      <Dialog.Title
                        as='h3'
                        className='font-bold leading-6 text-center text-gray-900 add_font16'>
                        {message}
                      </Dialog.Title>
                    </div>
                    <form onSubmit={handleSubmit(onSubmitAddress)}>
                      <div className='relative'>
                        <input
                          className='p-3 font-normal ccr_inputbox drop-shadow-dsInput'
                          placeholder='Pincode'
                          type='number'
                          name='pincode'
                          ref={register({
                            required: 'Pincode is required',
                            pattern: {
                              value: /^[1-9]\d{5}$/g,
                              message: 'Invalid input',
                            },
                          })}
                          onChange={(e) => {
                            if (e.target.value.length > 6)
                              e.target.value = e.target.value.slice(0, 6);
                            else {
                              setValidPin(false);
                              onChangePincode(e);
                            }
                          }}
                          // {...register("pincode", {
                          //   required: 'Pincode is required',
                          //   pattern: { value: /^[1-9]\d{5}$/g, message: 'Invalid input' },
                          //   onChange: (e) => {
                          //     if (e.target.value.length > 6)
                          //       e.target.value = e.target.value.slice(
                          //         0,
                          //         6
                          //       );
                          //     else {
                          //       setValidPin(false)
                          //       onChangePincode(e)
                          //     }
                          //   }
                          // })}
                        />
                        <div className='absolute top-4 right-2.5 flex'>
                          {pinLoader && (
                            <div
                              className='inline-block h-5 w-5 mr-2 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.125em] text-yellowB motion-reduce:animate-[spin_1.5s_linear_infinite]'
                              role='status'></div>
                          )}
                        </div>
                        <ValidErrorInputBox
                          errors={errors.pincode}
                          invalidPin={invalidPin}
                          valid={validPin}
                        />
                      </div>

                      <div className='relative'>
                        <input
                          className='p-3 font-normal ccr_inputbox drop-shadow-dsInput'
                          placeholder='Mobile'
                          type='number'
                          name='mobile'
                          ref={register({
                            required: 'Mobile is required',
                            pattern: {
                              value: /^[6-9]\d{9}$/g,
                              message: 'Invalid input',
                            },
                          })}
                          onChange={(e) => {
                            validatePhone(e);
                          }}
                          // {...register("mobile", {
                          //   required: 'Mobile is required',
                          //   pattern: { value: /^[6-9]\d{9}$/g, message: 'Invalid input' },
                          //   onChange: (e) => validatePhone(e)

                          // })}
                        />
                        <ValidErrorInputBox errors={errors.mobile} valid={validMob} />
                      </div>
                      <div className='relative'>
                        <input
                          className='p-3 font-normal ccr_inputbox drop-shadow-dsInput'
                          placeholder='Full name'
                          type='text'
                          name='name'
                          // onChange={(e) => {
                          // const inputValue = e.target.value;
                          // const words = inputValue.split(" ");
                          // const capitalizedWords = words.map((word) => {
                          //   return (
                          //     word.charAt(0).toUpperCase() + word.slice(1)
                          //   );
                          // });
                          // const capitalizedValue = capitalizedWords.join(" ");

                          // setFullName(capitalizedValue);
                          // }}
                          onChange={handleInputChange}
                          ref={register({
                            required: 'Full name is required',
                          })}
                          // {...register('name', {
                          //   required: 'Full name is required',
                          // })}
                        />

                        <ValidErrorInputBox errors={errors.name} />
                      </div>

                      <div className='relative'>
                        <input
                          className='p-3 font-normal ccr_inputbox drop-shadow-dsInput'
                          placeholder='Address line'
                          type='text'
                          name='address'
                          // onChange={(e) => {
                          //   const inputValue = e.target.value;
                          //   const words = inputValue.split(" ");
                          //   const capitalizedWords = words.map((word) => {
                          //     return (
                          //       word.charAt(0).toUpperCase() + word.slice(1)
                          //     );
                          //   });
                          //   const capitalizedValue = capitalizedWords.join(" ");

                          //   setAddress(capitalizedValue);
                          // }}
                          onChange={handleInputChange}
                          ref={register({
                            required: 'Address line is required',
                          })}
                          // {...register('address', {
                          //   required: 'Address line is required'
                          // })}
                        />
                        <ValidErrorInputBox errors={errors.address} />
                      </div>
                      <div className='relative'>
                        <input
                          className='p-3 font-normal ccr_inputbox drop-shadow-dsInput'
                          placeholder='Street Name'
                          type='text'
                          name='address2'
                          onChange={handleInputChange}
                          ref={register({
                            required: 'Street Name is required',
                          })}
                          // {...register('address2', {
                          //   required: 'Street Name is required',
                          // })}
                        />
                        <ValidErrorInputBox errors={errors.address2} />
                      </div>
                      <div className='relative'>
                        <input
                          className='p-3 font-normal ccr_inputbox drop-shadow-dsInput'
                          placeholder='Landmark'
                          type='text'
                          name='landmark'
                          onChange={handleInputChange}
                          ref={register({
                            required: 'Landmark is required',
                          })}
                          // {...register('landmark', {
                          //   required: 'Landmark is required',
                          // })}
                        />
                        <ValidErrorInputBox errors={errors.landmark} />
                      </div>

                      <div className='relative'>
                        <input
                          className='p-3 font-normal ccr_inputbox drop-shadow-dsInput'
                          placeholder='City'
                          type='text'
                          name='district'
                          onChange={handleInputChange}
                          ref={register({
                            required: 'City is required',
                          })}
                          // {...register('district', {
                          //   required: 'City is required',
                          // })}
                        />
                        <ValidErrorInputBox errors={errors.district} />
                      </div>

                      <div className='relative'>
                        <input
                          className='p-3 font-normal ccr_inputbox drop-shadow-dsInput'
                          placeholder='State'
                          type='text'
                          name='state'
                          onChange={handleInputChange}
                          ref={register({
                            required: 'State is required',
                          })}
                          //                        {...register('state', {
                          //   required: 'State is required',
                          // })}
                        />
                        <ValidErrorInputBox errors={errors.state} />
                      </div>

                      {/* <AddressInputBox placeholder="Full Name" />
                      <AddressInputBox placeholder="Address Line 1" />
                      <AddressInputBox placeholder="Address Line 2" />
                      <AddressInputBox placeholder="Landmark" />
                      <AddressInputBox placeholder="City" />
                      <AddressInputBox placeholder="State" /> */}

                      <div className='text-center add_font12'>
                        <button
                          type='submit'
                          className='py-2 ModalyellowBtn px-9 '
                          // onClick={closeModal}
                        >
                          SAVE
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
