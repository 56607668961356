import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  Image,
  Heading,
  Divider,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { API_URL } from '../../../services/api';
import lastbg from '../../../assets/sarath_IMG/Delete_Account_Bg.jpg';
import OtpInput from 'react-otp-input';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Timer from '../../Login/LoginSignup/timer';
import { get, post } from '../../../services/api';

import { useHistory } from 'react-router-dom';

// to change passsword
export const ToChangePassword = (props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({ mode: 'onChange' });
  return (
    <form>
      <ModalContent bgColor='white'>
        <ModalCloseButton size='md' color='var(--mountain-mist)' />
        <ModalBody alignContent='center' padding='70px 50px'>
          <Text
            textAlign='center'
            className='mob_ pass_heading manrope-bold-shark  '
          >
            {' '}
            CHANGE YOUR PASSWORD
          </Text>
          <Box mt='40px'>
            <Text mb='3px' className='mob_passfont nunitosans-normal-melanzane'>
              {' '}
              New Password
            </Text>
            <input
              className='mob_inputbox_change_pass_profile'
              name='new_password'
              autoFocus
              ref={register({
                required: true,
                minLength: { value: 8 },
              })}
            ></input>
          </Box>
          <Box mt='40px'>
            <Text className='mob_passfont nunitosans-normal-melanzane' mb='3px'>
              Confirm New Password
            </Text>
            <input
              className='mob_inputbox_change_pass_profile'
              name='confirm_password'
              ref={register({
                required: true,
                minLength: { value: 8 },
              })}
            ></input>
          </Box>
        </ModalBody>

        <ModalFooter justifyContent='center' mb='50px'>
          <Button
            className='mob_ confirm_btns nunitosans-normal-shark'
            ref={props.cancelRef}
            width='150px'
            borderRadius='5px'
            boxShadow={'0px 3px 6px #330d2f29'}
            bgColor={'var(--white-rock-2)'}
            onClick={handleSubmit((d) => props.changePassword(d))}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </form>
  );
};

export const PasswordChanged = (props) => {
  return (
    //    password changed succesfully / Error Message

    <ModalContent bgColor='white'>
      <ModalCloseButton size='md' color='var(--mountain-mist)' />
      <ModalBody
        display='flex'
        flexDirection='column'
        alignContent='center'
        padding='100px 40px 20px 40px'
      >
        <Flex className='mob_password_lotties' alignSelf='center'>
          <lottie-player
            src={API_URL + '/api/v1/json/Brakeup_Page_Confirmed_Animation.json'}
            background='transparent'
            speed='1'
            autoplay
            loop
          ></lottie-player>
        </Flex>

        <Text
          textAlign='center'
          className='mob_password_headng manrope-bold-shark'
        >
          {' '}
          YOUR PASSWORD CHANGED SUCCESSFULLY
        </Text>
      </ModalBody>

      <ModalFooter justifyContent='center' mb='20px'>
        <Button
          className='mob_ confirm_btns nunitosans-normal-shark'
          ref={props.cancelRef}
          width='150px'
          borderRadius='5px'
          boxShadow={'0px 3px 6px #330d2f29'}
          bgColor={'var(--white-rock-2)'}
          onClick={() => {
            props.onClose();
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export const PasswordChangeError = (props) => {
  return (
    //    Error Message

    <ModalContent bgColor='white'>
      <ModalCloseButton size='md' color='var(--mountain-mist)' />
      <ModalBody
        display='flex'
        flexDirection='column'
        alignContent='center'
        padding='100px 40px 20px 40px'
      >
        <Flex className='mob_password_error_lotties' alignSelf='center'>
          <lottie-player
            src={API_URL + '/api/v1/json/Error.json'}
            background='transparent'
            speed='1'
            autoplay
            loop
          ></lottie-player>
        </Flex>

        <Text
          textAlign='center'
          className='mob_password_headng manrope-bold-shark  '
        >
          {' '}
          ERROR MESSAGE
        </Text>
      </ModalBody>

      <ModalFooter justifyContent='center' mb='20px'>
        <Button
          className='mob_confirm_btns nunitosans-normal-shark'
          ref={props.cancelRef}
          width='150px'
          borderRadius='5px'
          boxShadow={'0px 3px 6px #330d2f29'}
          bgColor={'var(--white-rock-2)'}
          onClick={() => {
            props.onClose();
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

//Are you certain about this decision to Break Up with us ?

export const Deactivate1AreYou = (props) => {
  return (
    <ModalContent bgColor='white'>
      <ModalCloseButton size='md' color='var(--mountain-mist)' />
      <ModalBody
        display='flex'
        flexDirection='column'
        alignContent='center'
        padding='50px 30px 20px 30px'
      >
        <Text
          textAlign='center'
          className='mob_ deactivate_heading manrope-normal-shark'
        >
          {' '}
          Are you certain about this decision to Break Up with us ?
        </Text>

        <Flex mt='30px' alignSelf='center'>
          <Button
            className='mob_ confirm_btn1s nunitosans-bold-shark'
            bgColor='transparent'
            border='1px solid'
            borderRadius='5px'
            boxShadow='0px 3px 6px #00000029'
            onClick={() => {
              props.setModalContent(5);
            }}
          >
            YES
          </Button>

          <Button
            className='mob_ confirm_btn1s nunitosans-bold-shark'
            ref={props.cancelRef}
            ml='30px'
            borderRadius='5px'
            boxShadow={'0px 3px 6px #330d2f29'}
            bgColor={'var(--white-rock-2)'}
            onClick={() => {
              props.onClose();
            }}
          >
            NO
          </Button>
        </Flex>

        <Flex className='mob_deactivate1_lotties' alignSelf='center'>
          <lottie-player
            src={API_URL + '/api/v1/json/Sad_kid_delete.json'}
            background='transparent'
            speed='1'
            autoplay
            loop
          ></lottie-player>
          <Divider mt='98px' zIndex='-1' position='absolute' width='125px' />
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};

//IS THIS REALLY YOU - OTP
export const Deactivate2OTP = (props) => {
  const [OTP, setOTP] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [status, setStatus] = useState('SEND_OTP');

  const getOtp = () => {
    post('/api/v1/buyer/delete_account/', {})
      .then((res) => {
        setTrigger(true);
        setStatus('RESEND_OTP');
      })
      .catch((err) => {});
  };
  const enableBtn = () => {
    setTrigger(false);
  };
  useEffect(() => {
    getOtp();
  }, []);
  const resendOtp = () => {
    if (OTP.length != 4) {
      return;
    }
    post('/api/v1/buyer/verify_delete_account/', { otp: OTP })
      .then((res) => {
        props.setModalContent(6);
      })
      .catch((err) => {});
  };
  return (
    <ModalContent bgColor='white'>
      <ModalCloseButton size='lg' color='var(--mountain-mist)' />
      <ModalBody
        display='flex'
        flexDirection='column'
        alignContent='center'
        padding='20px 40px 30px 40px'
      >
        <Flex alignSelf='center' className='mob_deactivate2_lotties' mb='1vw'>
          <lottie-player
            src={API_URL + '/api/v1/json/Sad_lady_delete.json'}
            background='transparent'
            speed='1'
            autoplay
            loop
          ></lottie-player>
        </Flex>

        <Text
          textAlign='center'
          className='mob_deactivate_heading2s manrope-bold-shark '
        >
          {' '}
          IS THIS REALLY YOU WHO DOING THIS ?
        </Text>
        <Text
          textAlign='center'
          className='mob_deactivate_heading2s manrope-bold-shark '
        >
          {' '}
          I CAN'T BELEIVE IT. I NEED A TESTAMENT.
        </Text>

        <Flex
          flexDirection='column'
          alignItems='center'
          alignSelf='center'
          mt='20px'
          mb='20px'
          width='100%'
        >
          <Box mb='20px' mt='10px'>
            {trigger == false && (
              <Text
                className='mob_resend_deactivates nunitosans-normal-melanzane'
                onClick={() => getOtp()}
                style={{ cursor: 'pointer' }}
              >
                {' '}
                Resend OTP
              </Text>
            )}
          </Box>
          {status == 'RESEND_OTP' && trigger == true && (
            <Box
              mb='20px'
              mt='10px'
              className='mob_resend_deactivates nunitosans-normal-melanzane'
            >
              Resend OTP
              <Box position='absolute' className='mob_timer_deactivates'>
                <Timer
                  initialSeconds={60}
                  enableBtn={enableBtn}
                  trigger={trigger}
                />
              </Box>
            </Box>
          )}

          <Flex justifyContent='center'>
            <OtpInput
              inputStyle={{
                width: '2rem',
                height: '2rem',
                margin: '5px 10px',
                fontSize: '1rem',
                borderRadius: 4,
                border: '2px solid rgba(0,0,0,0.3)',
              }}
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={4}
              otpType='number'
              disabled={false}
            />
          </Flex>
          <Text className='mob_resend_deactivates nunitosans-normal-melanzane'>
            {' '}
            Enter OTP sent to your mobile
          </Text>
        </Flex>

        <Button
          alignSelf='center'
          className='mob_confirm_btns nunitosans-normal-white'
          ref={props.cancelRef}
          borderRadius='5px'
          boxShadow={'0px 3px 6px #330d2f29'}
          bgColor={'var(--orange-red)'}
          fontSize='0.8rem'
          size='sm'
          onClick={() => {
            resendOtp();
          }}
        >
          DELETE
        </Button>
      </ModalBody>
    </ModalContent>
  );
};

//final - successfully deleted

export const Deactivate3Final = (props) => {
  let history = useHistory();
  return (
    <ModalContent bgColor='white'>
      <ModalCloseButton size='lg' color='var(--mountain-mist)' />
      <Flex
        borderRadius='8px'
        flexDirection='column'
        alignContent='center'
        padding='20px 40px 30px 40px'
      >
        <Flex
          position='absolute'
          className='mob_deactivate3_lottie'
          alignSelf='center'
        >
          <lottie-player
            src={API_URL + '/api/v1/json/Breakup_Love.json'}
            background='transparent'
            speed='1'
            autoplay
            loop
          ></lottie-player>
        </Flex>
        <Flex className='mob_deactivate3_text_sections' flexDirection='column'>
          <Text
            textAlign='center'
            className='mob_ it_takess playball-normal-shark '
          >
            {' '}
            It takes just a few seconds to say hello.
          </Text>
          <Text
            textAlign='center'
            mt='10px'
            fontStyle='italic'
            className='mob_we_cans nunitosans-bold-shark '
          >
            We can still talk this out. Call us 90 2580 2580{' '}
          </Text>

          <Flex
            flexDirection='column'
            alignItems='center'
            alignSelf='center'
            mt='60px'
            width='100%'
          >
            <Text
              textAlign='center'
              className='mob_ your_accnts nunitosans-normal-shark '
            >
              {' '}
              Your account has been successfully deleted hope to see you back
              soon.
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <ModalFooter justifyContent='center' mb='40px'>
        <Button
          alignSelf='center'
          className='mob_confirm_btns nunitosans-normal-white'
          ref={props.cancelRef}
          bgColor='transparent'
          border='1px solid'
          borderRadius='5px'
          boxShadow='0px 3px 6px #00000029'
          color='var(--royal-blue)'
          onClick={() => {
            props.onClose();
            localStorage.removeItem('token');
            history.push('/');
            window.location.reload();
          }}
        >
          Sign Up
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
