import React, { useEffect, useState, useRef } from "react";

import { Box, Button, Flex, Select, Text } from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  CloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { CgClose } from "react-icons/cg";
import { API_URL, get, post } from "../../../services/api";
import { useHistory } from "react-router-dom";

//toast

import {
  FaInfoCircle,
  FaCheckCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Meta, UUID } from "../../../services/Meta-Pixel";
import { Cart_Add2CartGtag } from "../../../services/G-Tag";

export const SavedList = (props) => {
  let history = useHistory();

  //Alert Dialog PopUp MOVE TO CART
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [saveItems, setSaveItems] = useState([]);
  const [deleteItem, setDeleteItem] = useState({});
  const cancelRef = React.useRef();

  //Alert Dialog PopUp DELETE
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  //toast
  const Swal = require("sweetalert2");

  const Toast2 = (title, theme, color, iconColor) => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      autofocus: false,
      toast: true,
      animation: true,
      timer: 5000,
      // timerProgressBar: true,
      iconColor: iconColor,
      icon: theme,
      title: title,
      color: color,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  };

  const getSaveLater = () => {
    get("/api/v1/products/savelater/")
      .then((res) => {
        setSaveItems(res?.data);
      })
      .catch((err) => {
        setSaveItems([]);
      });
  };
  const removeFromSaveLater = () => {
    let input = {
      vend_id: deleteItem.vend_id,
      prod_id: deleteItem.prod_id,
    };
    post("/api/v1/products/delete/saveditems/", input)
      .then((res) => {
        setDeleteItem({});
        getSaveLater();
        onCloseDelete();
        Toast2("Item Removed !", "error", "#f22020");
      })
      .catch((res) => {});
  };
  const addToCart = (addToCartItem) => {
    let data = {
      product: [
        {
          vend_id: addToCartItem.vend_id,
          prod_id: addToCartItem.prod_id,
          make_id: addToCartItem?.vehicle_details?.make_id ?? "",
          model_id: addToCartItem?.vehicle_details?.model_id ?? "",
          year: addToCartItem?.vehicle_details?.year ?? "",
          fueltype: addToCartItem?.vehicle_details?.fueltype ?? "",
          version: addToCartItem?.vehicle_details?.version ?? "",
          delivery_date: addToCartItem.delivery_date,
          quantity: addToCartItem.quantity,
        },
      ],
      pincode: "110001",
    };

    const eventID = UUID();

    post("/api/v1/products/addtocart/", data)
      .then((res) => {
        getSaveLater();

        window.fbq(
          "track",
          "AddToCart",
          {
            value: addToCartItem?.order_prod_price,
            currency: "INR",
            contents: [
              {
                id: addToCartItem?.brakeup_part_no,
                quantity: 1,
              },
            ],
          },
          { eventID: eventID }
        );

        let data2 = {
          currency: "INR",
          value: addToCartItem?.order_prod_price,
          contents: [
            {
              id: addToCartItem?.brakeup_part_no,
              quantity: 1,
              delivery_category: "home_delivery",
            },
          ],
        };
        Meta("AddToCart", data2, eventID);
        Cart_Add2CartGtag(addToCartItem);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getSaveLater();
  }, []);

  const [value, setValue] = useState("");

  const changeQuantity = (prod, quantity) => {
    // console.log(prod, quantity, "changequantity");
    let input = {
      vend_id: prod.vend_id,
      prod_id: prod.prod_id,
      quantity: parseInt(quantity),
    };
    post("/api/v1/products/update/savelater/", input)
      .then((res) => {
        getSaveLater();
      })
      .catch((res) => {});
  };

  const ToProductDetailPage = (products) => {
    // console.log(products,"ToProductDetailPage" );

    history.push(
      `/product/${products?.prod_id}${
        products.vehicle_details?.make_id
          ? "/" +
            products.vehicle_details?.make_id +
            "/" +
            products.vehicle_details?.model_id +
            "/" +
            products.vehicle_details?.year +
            (products.vehicle_details?.version
              ? "/" + products.vehicle_details?.version
              : "") +
            (products.vehicle_details?.fueltype
              ? "/" + products.vehicle_details?.fueltype
              : "")
          : ""
      }${
        products.vehicle_details?.type
          ? "/" + products.vehicle_details?.type
          : ""
      }`
    );
  };

  return (
    <Box className='cart-items'>
      {saveItems.length == 0 ? (
        <Flex
          alignSelf='center'
          flexDirection='column'
          alignItems='center'
          className='flexbox_Empty_State'
        >
          <Box className='savederrorjson'>
            <lottie-player
              src={API_URL + "/api/v1/json/Brakeup_Empty_State.json"}
              background='transparent'
              speed='1'
              loop
              autoplay
            ></lottie-player>
          </Box>

          <Flex
            className='textbox_Empty_State'
            flexDir='column'
            alignItems='center'
          >
            <Text
              className='bold_text_Empty_State nunitosans-bold-shark '
              whiteSpace='nowrap'
              textAlign='center'
            >
              {" "}
              No items saved for later
            </Text>
            <Text
              className='text2_Empty_State nunitosans-normal-melanzane'
              whiteSpace='nowrap'
              textAlign='center'
            >
              Please add your items from cart
            </Text>
            <button
              className='btn_empty_state nunitosans-bold-shark'
              onClick={() => history.push("/cart")}
            >
              Cart
            </button>
          </Flex>
        </Flex>
      ) : (
        <>
          {saveItems &&
            saveItems.map((items, index) => {
              const generateOptions = () => {
                let count = [];
                for (let i = 1; i <= items.category_quantity; i++) {
                  count.push(<option value={i}>{i}</option>);
                }
                return count;
              };
              const handleChange = (event) => {
                setValue(event.target.value);
                changeQuantity(items, event.target.value);
                // console.log(value, "productsindexvalue");
              };

              return (
                <Flex className='cart-items_1' justifyContent='space-between'>
                  <Flex className='overlap-1'>
                    <div className='text-7-1 nunitosans-normal-melanzane'>
                      {index + 1}.
                    </div>
                    <img
                      className='brake-pad-12'
                      src={items?.image[0]}
                      onClick={() => {
                        ToProductDetailPage(items);
                      }}
                    />
                    <Flex className='flex-col-21'>
                      <div
                        onClick={() => {
                          ToProductDetailPage(items);
                        }}
                        className='brembo-22 nunitosans-black-venus'
                      >
                        {items.brand}
                      </div>
                      <div
                        onClick={() => {
                          ToProductDetailPage(items);
                        }}
                        className='front-brake-pads-7 nunitosans-normal-melanzane'
                      >
                        {items?.prod_name}{" "}
                        {items?.vehicle_details?.make ? "for" : ""}{" "}
                        {items?.vehicle_details?.make}{" "}
                        {items?.vehicle_details?.model}{" "}
                        {items?.vehicle_details?.fueltype}{" "}
                        {items?.vehicle_details?.year}
                      </div>

                      <Flex alignItems='baseline' mt='-5px'>
                        {items.stock_status == "instock" ? (
                          <div className='in-stock-9 nunitosans-normal-forest-green'>
                            Instock
                          </div>
                        ) : (
                          <div
                            className='out_of_stock nunitosans-normal-forest-green'
                            style={{ color: " var(--orange-red)" }}
                          >
                            Out of stock
                          </div>
                        )}

                        {/* <Flex className="delivery-by-12">
                                            <div className="delivery-by-13 nunitosans-normal-mountain-mist-1">Delivery by:</div>

                                            <div className="x15th-september-8 nunitosans-normal-mountain-mist-1">
                                                <span>{items.delivery_date}</span>
                                            </div>
                                        </Flex>

                                        <Flex className="dispatch-from-7 nunitosans-normal-mountain-mist-1">
                                            <div className="dispatch-from-8">Dispatch from:</div>
                                            <div className="place-30">{items.vendor_city}</div>
                                        </Flex> */}
                      </Flex>

                      <Flex className='quantity-14'>
                        <div className='quantity-15 nunitosans-normal-melanzane'>
                          Quantity:
                        </div>
                        <div className='overlap-group-61'>
                          <Select
                            value={value}
                            onChange={handleChange}
                            border='none'
                            className='savedlistSelectbox'
                            style={{
                              boxShadow: "0px 0px 2px #00000040",
                              borderRadius: "1px",
                            }}
                            cursor='pointer'
                            iconColor='#ACACAC'
                          >
                            {generateOptions()}
                          </Select>
                        </div>
                        {items.stock_status == "instock" && (
                          <Button
                            className='add_to_cart nunitosans-normal-mountain-mist-1'
                            borderRadius='5px'
                            bgColor='var(--white)'
                            boxShadow='0px 0px 2px #00000040'
                            id='add_to_cart'
                            onClick={() => {
                              addToCart(items);
                              onOpen();
                            }}
                          >
                            {" "}
                            Add to Cart
                          </Button>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex className='flex-col-22'>
                    <CgClose
                      color='grey'
                      style={{ cursor: "pointer", fontSize: "1.5vw" }}
                      onClick={() => {
                        setDeleteItem(items);
                        onOpenDelete();
                      }}
                    />

                    <Flex className='product-1-price-6'>
                      {items.discount_percentage == 0 ? (
                        ""
                      ) : (
                        <>
                          <div className='x30-off-15 manrope-normal-forest-green '>
                            (
                            {items.discount_percentage
                              ? items.discount_percentage.toFixed(0)
                              : ""}
                            % OFF)
                          </div>
                          <Flex>
                            <div
                              style={{ marginRight: "5px" }}
                              className='text-7-2 manrope-medium-venus'
                            >
                              ₹{items.total_mrp}
                            </div>
                            <div
                              style={{ textDecoration: "none" }}
                              className='text-7-2 nunitosans-normal-orange-red-14px'
                            >
                              -{items.discount_price}
                            </div>
                          </Flex>
                        </>
                      )}
                      <div className='rs-191800-11 manrope-medium-black-pearl'>
                        Rs. {items.order_prod_price}
                      </div>
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
        </>
      )}

      <AlertDialog
        motionPreset='slideInBottom'
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size='md'
        autoFocus={false}
      >
        <AlertDialogOverlay />

        <AlertDialogContent bgColor='var(--white-rock-2)'>
          <AlertDialogCloseButton />
          <Flex justifyContent='space-evenly' padding='10px'>
            <Flex width='100px'>
              <lottie-player
                src={API_URL + "/api/v1/json/Cart_Successful.json"}
                background='transparent'
                speed='1'
                autoplay
                loop
              ></lottie-player>
            </Flex>
            <Flex flexDirection='column'>
              <AlertDialogBody padding='20px'>
                <Text
                  textAlign='flex-end'
                  fontWeight='bolder'
                  className='nunitosans-normal-shark-21px'
                  mb='20px'
                >
                  Item moved to Cart
                </Text>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  className='nunitosans-normal-shark-14px'
                  onClick={() => {
                    onClose();
                  }}
                  bgColor='var(--white)'
                  borderRadius='3px'
                  boxShadow='0px 3px 6px #00000029'
                >
                  Close
                </Button>

                <Button
                  className='nunitosans-normal-white-14px'
                  ml={3}
                  borderRadius='3px'
                  boxShadow={"0px 3px 6px #330d2f29"}
                  bgColor={"var(--royal-blue)"}
                  onClick={() => {
                    history.push("/cart");
                  }}
                >
                  View Cart
                </Button>
              </AlertDialogFooter>
            </Flex>
          </Flex>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onCloseDelete}
        isOpen={isOpenDelete}
        isCentered
        size={"md"}
        autoFocus={false}
      >
        <AlertDialogOverlay />

        <AlertDialogContent bgColor='var(--white-rock-2)'>
          <AlertDialogCloseButton />

          <AlertDialogBody>
            <AlertDialogHeader
              className='nunitosans-normal-shark-21px'
              mt='20px'
            >
              Are you sure want to remove ?
            </AlertDialogHeader>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              className='nunitosans-normal-shark-14px'
              onClick={() => {
                removeFromSaveLater();
              }}
              bgColor='var(--white)'
              borderRadius='3px'
              width='70px'
              boxShadow='0px 3px 6px #00000029'
            >
              Delete
            </Button>

            <Button
              className='nunitosans-normal-white-14px'
              ref={cancelRef}
              ml={3}
              borderRadius='3px'
              boxShadow={"0px 3px 6px #330d2f29"}
              bgColor={"var(--royal-blue)"}
              onClick={() => {
                setDeleteItem({});
                onCloseDelete();
              }}
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  );
};
