import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    number: '',
    updateCart: true,
}

export const cartCountSlice = createSlice({
    name: 'count',
    initialState,
    reducers: {
        setCartCount: (state, action) => {
            state.number = action.payload
        },
        setupdateCart: (state, action) => {
            state.updateCart= action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setCartCount, setupdateCart  } = cartCountSlice.actions

export default cartCountSlice.reducer