import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import React, { useEffect } from 'react';
import { dashRoutes } from './routes';
import { ComponentWrapper } from './Admin';
import './global.css';
import { useState } from 'react';
import { get } from './services/api';
import { Loader } from './views/loader';
import { NOTFOUND404 } from './views/NOTFOUND404';
import { useSelector, useDispatch } from 'react-redux';
import {
  setUserName,
  setUserMobile,
  setUserEmail,
  setToken,
  setAccess,
  setUser,
} from './stateManagement/user/userSlice';

// import ReactGA from 'react-ga';

const App = (props) => {
  const [screenSize, setscreenSize] = useState('WL');
  const [isLoad, setIsLoad] = useState(false);
  const [isuser, setIsuser] = useState(false);

  const dispatch = useDispatch();
  const access = useSelector((state) => state.user.access);

  useEffect(() => {
    console.log('accessedaccessedaccessed');
    // if (!access) {
    //     get("/api/v1/user/anonymous/").then(res => {
    //         localStorage.setItem("token", JSON.stringify(res));
    //          dispatch(setToken(res));
    //          console.log(res,"setToken");
    //         setIsLoad(true);
    //     }).catch(err => {

    //     })
    // } else {
    //     setIsLoad(true);
    // }

    const token = JSON.parse(localStorage.getItem('token') || '{}');
    if (token && Object.keys(token).length == 0) {
      get('/api/v1/user/anonymous/')
        .then((res) => {
          localStorage.setItem('token', JSON.stringify(res));
          // dispatch(setToken(res));
          // console.log(res, "setToken");
          setIsLoad(true);
        })
        .catch((err) => {});
    } else {
      setIsLoad(true);
    }
  }, []);

  // const token2 = JSON.parse(localStorage.getItem("token"))

  // useEffect(() => {

  //     console.log("accessss render newtoken");

  //     if (token2 && token2.data?.credAuthResult.type == "Access") {
  //         console.log(token2.data?.credAuthResult?.mobile, "accessed");
  //         dispatch(setUserName(token2.data?.credAuthResult?.name))
  //         dispatch(setUserMobile(token2.data?.credAuthResult?.mobile))
  //         dispatch(setUserEmail(token2.data?.credAuthResult?.email))
  //         dispatch(setToken(token2))
  //         // // dispatch(setUser())
  //         dispatch(setAccess(true))

  //     } else {
  //         console.log(token2 && token2.data?.credAuthResult.type, "notaccessed");
  //         dispatch(setUserName(""))
  //         dispatch(setUserMobile(""))
  //         dispatch(setUserEmail(""))
  //         dispatch(setToken(token2))
  //         // // dispatch(setUser())
  //         dispatch(setAccess(false))
  //     }
  // }, [access]);

  function ReportWindowSize() {
    // console.log(window.innerWidth,);

    switch (true) {
      case window.innerWidth >= 1920:
        setscreenSize('WL');
        break;

      case window.innerWidth >= 1366:
        setscreenSize('WM');
        break;

      case window.innerWidth >= 1280:
        setscreenSize('WS');
        break;

      case window.innerWidth >= 1024:
        setscreenSize('IPAD');
        break;

      case window.innerWidth >= 800:
        setscreenSize('SPAD');
        break;

      case window.innerWidth >= 428:
        setscreenSize('IMOB');
        break;

      case window.innerWidth <= 427:
        setscreenSize('SMOB');
        break;
      default:
        break;
    }
  }
  window.onresize = ReportWindowSize;

  return (
    <React.Fragment>
      <Loader />
      {isLoad && (
        <Router>
          <Switch>
            {dashRoutes.map((route, idx) => {
              return (
                <ComponentWrapper
                  path={route.path}
                  component={route.component}
                  key={idx}
                  exact
                />
              );
            })}
          </Switch>
        </Router>
      )}
    </React.Fragment>
  );
};
export default App;
