import { createSlice } from '@reduxjs/toolkit'


const token = JSON.parse(localStorage.getItem("token"))

const initialState = {
    name: token?.data?.credAuthResult.name,
    mobile: token?.data?.credAuthResult.mobile,
    email: token?.data?.credAuthResult.email,

    token: token,
    access: token?.data?.credAuthResult.type == "Access" ? true : false,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.name = action.payload.name
            state.mobile = action.payload.mobile
            state.email = action.payload.email
            state.token = action.payload.token
        },
        setUserName: (state, action) => {
            state.name = action.payload
        },
        setUserMobile: (state, action) => {
            state.mobile = action.payload
        },
        setUserEmail: (state, action) => {
            state.email = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setAccess: (state, action) => {
            state.access = action.payload
        }

        // increment: (state) => {
        //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //   // doesn't actually mutate the state because it uses the Immer library,
        //   // which detects changes to a "draft state" and produces a brand new
        //   // immutable state based off those changes
        //   state.value += 1
        // },
        // decrement: (state) => {
        //   state.value -= 1
        // },
        // incrementByAmount: (state, action) => {
        //   state.value += action.payload
        // },
    },

})

// Action creators are generated for each case reducer function
export const { setUser, setUserName, setUserEmail, setUserMobile, setToken, setAccess } = userSlice.actions

export default userSlice.reducer