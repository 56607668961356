import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  Box,
  Divider,
  Button,
  Flex,
  Image,
  Text,
  Grid,
  GridItem,
  IconButton,
  Spacer,
  ModalFocusScope,
} from '@chakra-ui/react';
import { API_URL } from '../../services/api';

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  useDisclosure,
} from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import { FiSearch, FiUser } from 'react-icons/fi';
import { BiChevronRightCircle } from 'react-icons/bi';

import CustomSelect from '../FilterPage/CustomSelect';

import Car from '../../assets/sarath_IMG/carBlack1.png';
import Quotebg from '../../assets/sarath_IMG/Quote_background_3_mob.png';
import hpbg from '../../assets/sarath_IMG/BaseofHomepage-4.png';

import Whatsapp_logo from '../../assets/sarath_IMG/Whatsapp_logo.png';
import ReactWhatsapp from 'react-whatsapp';

//SHOP BY BRANDS
import pad from '../../assets/sarath_IMG/PadsB.png';
import rotor from '../../assets/sarath_IMG/RotorB.png';
import fluid from '../../assets/sarath_IMG/FluidB.png';
import other from '../../assets/sarath_IMG/Other-ProductsB.png';

//HIGHLIGHTS
import high1 from '../../assets/sarath_IMG/Find_top_brands.png';
import high2 from '../../assets/sarath_IMG/GenuineProducts-01.png';
import high3 from '../../assets/sarath_IMG/Easy Compatability.svg';
import high4 from '../../assets/sarath_IMG/Fast_FreeDelivery.png';
import high5 from '../../assets/sarath_IMG/DiscountDeals.png';

import p1 from '../../assets/sarath_IMG/brakepad-01-in-product-detail-page-5@1x.png';
import p2 from '../../assets/sarath_IMG/Brake Fluids@2x.png';
import p3 from '../../assets/sarath_IMG/brakepad-02-in-product-detail-page-5@1x.png';
import p4 from '../../assets/sarath_IMG/brakepad-generic-image-in-all-pages-5@1x.png';
import p5 from '../../assets/sarath_IMG/brakepad-main-image-in-product-detail-page-5@1x.png';
import p6 from '../../assets/sarath_IMG/brakepad-03-in-product-detail-page-5@1x.png';

import logo1 from '../../assets/sarath_IMG/BMW_Logo.png';
import logo2 from '../../assets/sarath_IMG/Porsche_Logo.png';
import logo3 from '../../assets/sarath_IMG/Bentley_Logo.png';
import logo4 from '../../assets/sarath_IMG/Lamborghini_Logo.png';
import logo5 from '../../assets/sarath_IMG/Maserati_Logo.png';
import logo6 from '../../assets/sarath_IMG/Jaguar_Logo.png';

//ADVERTISEMENT MOB
// import AdM1 from '../../assets/sarath_IMG/Banner-1-Mob-2.jpg'
// import AdM2 from '../../assets/sarath_IMG/Banner-2-Mob-2.jpg'
// import AdM3 from '../../assets/sarath_IMG/Banner-3-Mob-2.jpg'
import AdM4a from '../../assets/sarath_IMG/Banner-4-Mob2.jpg';
import AdM4b from '../../assets/sarath_IMG/Banner-5-Mob2.jpg';
// import AdM5 from '../../assets/sarath_IMG/Banner-6-Mob2.jpg'
import AdM6a from '../../assets/sarath_IMG/Banner-7-Mob2.jpg';
import AdM6b from '../../assets/sarath_IMG/Banner-8-Mob2.jpg';
// import AdM7 from '../../assets/sarath_IMG/Banner-9-Mob2.jpg'
// import AdM8a from '../../assets/sarath_IMG/Banner-10-Mob2.jpg'
// import AdM8b from '../../assets/sarath_IMG/Banner-11-Web2.jpg'

import { Mobilesearch } from './Mobilesearch';
import { FilterIconsx9Mob } from './FilterIconsx9Mob';
import { TopSellingProducts } from './TopSellingProducts';

//ADVERTISEMENT MOB v3
import AdM1 from '../../assets/sarath_IMG/Banner-1-Mob-21.jpg';
import AdM4 from '../../assets/sarath_IMG/Banner-1-Mob-New-21.jpg';
import AdM5 from '../../assets/sarath_IMG/Banner-5-Mob-21.jpg';
import AdM6 from '../../assets/sarath_IMG/Banner-2-Mob-21.jpg';

// import AdM1 from '../../assets/Ads/Mob/Banner 1.jpg';
import AdM2 from '../../assets/Ads/Mob/Banner 2.jpg';
import AdM3 from '../../assets/Ads/Mob/Banner 3.jpg';
// import AdM4 from '../../assets/Ads/Mob/Banner 4.jpg';
// import AdM5 from '../../assets/Ads/Mob/Banner 5.jpg';
// import AdM6 from '../../assets/Ads/Mob/Banner 6.jpg';
import AdM7 from '../../assets/Ads/Mob/Banner 7.jpg';
import AdM8 from '../../assets/Ads/Mob/Banner 8.jpg';
import AdM8a from '../../assets/Ads/Mob/Banner 9.jpg';
import AdM8b from '../../assets/Ads/Mob/Banner 10.jpg';

import contactUs from '../../assets/sarath_IMG/Customer-care-banner-web-low.jpg';

// TOP BRANDS

import Brembo from '../../assets/ManufacturerLogos/Brembo.png';
import ROWE from '../../assets/ManufacturerLogos/ROWE.png';
import EBC from '../../assets/ManufacturerLogos/EBC.png';
import Bosch from '../../assets/ManufacturerLogos/Bosch.png';
import Hiq from '../../assets/ManufacturerLogos/Hiq.png';
import ICER from '../../assets/ManufacturerLogos/ICER.png';
import Ktek from '../../assets/ManufacturerLogos/Ktek.png';
import Meyle from '../../assets/ManufacturerLogos/Meyle.png';
import ATE from '../../assets/ManufacturerLogos/ATE.png';
import TVSGiriling from '../../assets/ManufacturerLogos/TVS Giriling.png';
import Supergrip from '../../assets/ManufacturerLogos/Supergrip.png';
import Hella from '../../assets/ManufacturerLogos/Hella.png';
import AlliedNippon from '../../assets/ManufacturerLogos/Allied Nippon.png';
import Motherson from '../../assets/ManufacturerLogos/Motherson.png';

//CARS

import Swift from '../../assets/Cars/Maruti Swift.png';
import VWPolo from '../../assets/Cars/VW Polo.png';
import BMW530d from '../../assets/Cars/BMW 530d.png';
import HondaCity from '../../assets/Cars/Honda city.png';
import RenaultDuster from '../../assets/Cars/Renault Duster.png';
import HyundaiElitei20 from '../../assets/Cars/Hyundai Elite i20.png';
import Porsche911 from '../../assets/Cars/Porsche 911.png';
import TataNexon from '../../assets/Cars/Tata Nexon.png';
import FordEcoSport from '../../assets/Cars/Ford Eco Sport.png';

import PadBG from '../../assets/Cars/PadBackground.png';
import RotorBG from '../../assets/Cars/RotorBackground.png';
import { AdsMob } from '../../ZAds/AdsMob';
import { AdsWeb } from '../../ZAds/AdsWeb';

// searchbox

export default function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  const handleScroll = () => {
    if (window.scrollY >= 400) {
      setIsComponentVisible(false);
      // console.log('window.scrollY', window.scrollY);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleClickOutside, true);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

export const HomePageMobile = (props) => {
  let history = useHistory();

  //hide footer when keyboard on

  const [isKeyboardVisible, setKeyboardVisible] = useState(false);

  useEffect(() => {
    window.visualViewport.addEventListener('resize', listener);

    // // toggle isKeyboardVisible on event listener triggered
    // window.visualViewport.addEventListener('resize', () => {
    //   setKeyboardVisible(!isKeyboardVisible);
    //   console.log("resized");
    // });
  }, []);

  const listener = () => {
    const MIN_KEYBOARD_HEIGHT = 300; // N.B.! this might not always be correct
    const isKeyboardOpen =
      window.screen.height - MIN_KEYBOARD_HEIGHT > window.visualViewport.height;
    // console.log("isKeyboardOpen", isKeyboardOpen);
    setKeyboardVisible(isKeyboardOpen);
  };

  //show whatsapp icon

  const showwhatsappref = useRef();
  const onScreen = useOnScreen(showwhatsappref, '-10px');

  function useOnScreen(ref, rootMargin = '0px') {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState(false);
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when observer callback fires
          setIntersecting(entry.isIntersecting);
        },
        {
          rootMargin,
        }
      );
      if (ref.current) {
        observer?.observe(ref.current);
      }
      return () => {
        if (ref.current && observer) {
          observer?.unobserve(ref.current);
        }
      };
    }, [ref, rootMargin]); // Empty array ensures that effect is only run on mount and unmount
    return isIntersecting;
  }

  //alertboxx
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    register,
    unregister,
  } = useForm({
    mode: 'onChange',
  });

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  //FILTER BY CARS

  let FilterbyCarsHeading = 'FILTER BY CARS';
  let FilterbyCarsSearchButtonTitle = 'SEARCH OTHER CARS';
  let FilterbyCarsData = [
    {
      image: Swift,
      searchkey: [
        {
          value: '63087a8278de19ec4482d498',
          name: 'make',
          label: 'Maruti Suzuki',
        },
        { value: '63087a9378de19ec4482d606', name: 'model', label: 'Swift' },
      ],
      title: 'Swift',
    },
    {
      image: VWPolo,
      searchkey: [
        {
          value: '63087a8278de19ec4482d487',
          name: 'make',
          label: 'Volkswagen',
        },
        { value: '63087a9278de19ec4482d520', name: 'model', label: 'Polo' },
      ],
      title: 'Polo',
    },
    {
      image: BMW530d,
      searchkey: [
        { value: '63087a8178de19ec4482d480', name: 'make', label: 'BMW' },
        { value: '63087a9178de19ec4482d4b3', name: 'model', label: '530d' },
        { value: '2007', name: 'year', label: '2007' },
      ],
      title: '530d E90',
    },
    {
      image: HondaCity,
      searchkey: [
        { value: '63087a8278de19ec4482d484', name: 'make', label: 'Honda' },
        { value: '63087a9178de19ec4482d4fa', name: 'model', label: 'City' },
        { value: '2017', name: 'year', label: '2017' },
      ],
      title: 'City VX',
    },
    {
      image: RenaultDuster,
      searchkey: [
        { value: '63087a8278de19ec4482d49a', name: 'make', label: 'Renault' },
        { value: '63087a9378de19ec4482d61c', name: 'model', label: 'Duster' },
      ],
      title: 'Duster',
    },
    {
      image: HyundaiElitei20,
      searchkey: [
        { value: '63087a8278de19ec4482d485', name: 'make', label: 'Hyundai' },
        {
          value: '63087a9178de19ec4482d50a',
          name: 'model',
          label: 'i20 Elite',
        },
      ],
      title: 'i20 Elite',
    },
    {
      image: Porsche911,
      searchkey: [
        { value: '63087a8278de19ec4482d492', name: 'make', label: 'Porsche' },
        { value: '63087a9278de19ec4482d552', name: 'model', label: '911' },
      ],
      title: '911',
    },
    {
      image: TataNexon,
      searchkey: [
        { value: '63087a8278de19ec4482d4a0', name: 'make', label: 'Tata' },
        { value: '63087a9478de19ec4482d679', name: 'model', label: 'Nexon' },
      ],
      title: 'Nexon',
    },
    {
      image: FordEcoSport,
      searchkey: [
        { value: '63087a8278de19ec4482d483', name: 'make', label: 'Ford' },
        { value: '63087a9178de19ec4482d4eb', name: 'model', label: 'Ecosport' },
      ],
      title: 'EcoSport',
    },
  ];

  //TOP MANUFACTURES

  let TopBrandHeading = 'TOP MANUFACTURES';
  let TopBrandSearchButtonTitle = 'SEARCH OTHERS';

  let BrandData = [
    {
      image: Brembo,
      searchkey: { value: 'Brembo', name: 'brand', label: 'Brembo' },
    },
    {
      image: ROWE,
      searchkey: { value: 'ROWE', name: 'brand', label: 'ROWE' },
    },
    {
      image: Bosch,
      searchkey: { value: 'Bosch', name: 'Bosch', label: 'Bosch' },
    },
    {
      image: ATE,
      searchkey: { value: 'ATE', name: 'brand', label: 'ATE' },
    },
    {
      image: ICER,
      searchkey: { value: 'ICER', name: 'brand', label: 'ICER' },
    },
    {
      image: Meyle,
      searchkey: { value: 'Meyle', name: 'brand', label: 'Meyle' },
    },
    {
      image: EBC,
      searchkey: { value: 'EBC', name: 'brand', label: 'EBC' },
    },
    {
      image: Ktek,
      searchkey: { value: 'Ktek', name: 'brand', label: 'Ktek' },
    },
    {
      image: Hiq,
      searchkey: { value: 'Hi-Q', name: 'brand', label: 'Hi-Q' },
    },
    // {
    //   image: TVSGiriling,
    //   searchkey: { value: "TVS Giriling", name: "brand", label: "TVS Giriling" },
    // },
  ];

  //BRAKEPAD for CARS

  let BrakePadsHeading = 'BRAKE PADS FOR YOUR CAR';
  let BrakePadsSearchButtonTitle = 'SEARCH OTHER CARS';
  let BrakePadsData = [
    {
      image: Swift,
      searchkey: [
        {
          value: '63087a8278de19ec4482d498',
          name: 'make',
          label: 'Maruti Suzuki',
        },
        { value: '63087a9378de19ec4482d606', name: 'model', label: 'Swift' },
        { value: 'Brake Pad', name: 'category', label: 'Brake Pad' },
      ],
      title: 'Swift',
    },
    {
      image: VWPolo,
      searchkey: [
        {
          value: '63087a8278de19ec4482d487',
          name: 'make',
          label: 'Volkswagen',
        },
        { value: '63087a9278de19ec4482d520', name: 'model', label: 'Polo' },
        { value: 'Brake Pad', name: 'category', label: 'Brake Pad' },
      ],
      title: 'Polo',
    },
    {
      image: BMW530d,
      searchkey: [
        { value: '63087a8178de19ec4482d480', name: 'make', label: 'BMW' },
        { value: '63087a9178de19ec4482d4b3', name: 'model', label: '530d' },
        { value: '2007', name: 'year', label: '2007' },
        { value: 'Brake Pad', name: 'category', label: 'Brake Pad' },
      ],
      title: '530d E90',
    },
    {
      image: HondaCity,
      searchkey: [
        { value: '63087a8278de19ec4482d484', name: 'make', label: 'Honda' },
        { value: '63087a9178de19ec4482d4fa', name: 'model', label: 'City' },
        { value: '2017', name: 'year', label: '2017' },
        { value: 'Brake Pad', name: 'category', label: 'Brake Pad' },
      ],
      title: 'City VX',
    },
    {
      image: RenaultDuster,
      searchkey: [
        { value: '63087a8278de19ec4482d49a', name: 'make', label: 'Renault' },
        { value: '63087a9378de19ec4482d61c', name: 'model', label: 'Duster' },
        { value: 'Brake Pad', name: 'category', label: 'Brake Pad' },
      ],
      title: 'Duster',
    },
    {
      image: HyundaiElitei20,
      searchkey: [
        { value: '63087a8278de19ec4482d485', name: 'make', label: 'Hyundai' },
        {
          value: '63087a9178de19ec4482d50a',
          name: 'model',
          label: 'i20 Elite',
        },
        { value: 'Brake Pad', name: 'category', label: 'Brake Pad' },
      ],
      title: 'i20 Elite',
    },
    {
      image: Porsche911,
      searchkey: [
        { value: '63087a8278de19ec4482d492', name: 'make', label: 'Porsche' },
        { value: '63087a9278de19ec4482d552', name: 'model', label: '911' },
        { value: 'Brake Pad', name: 'category', label: 'Brake Pad' },
      ],
      title: '911',
    },
    {
      image: TataNexon,
      searchkey: [
        { value: '63087a8278de19ec4482d4a0', name: 'make', label: 'Tata' },
        { value: '63087a9478de19ec4482d679', name: 'model', label: 'Nexon' },
        { value: 'Brake Pad', name: 'category', label: 'Brake Pad' },
      ],
      title: 'Nexon',
    },
    {
      image: FordEcoSport,
      searchkey: [
        { value: '63087a8278de19ec4482d483', name: 'make', label: 'Ford' },
        { value: '63087a9178de19ec4482d4eb', name: 'model', label: 'Ecosport' },
        { value: 'Brake Pad', name: 'category', label: 'Brake Pad' },
      ],
      title: 'EcoSport',
    },
  ];

  //BRAKEROTOR for CARS

  let BrakeRotorsHeading = 'BRAKE ROTORS FOR YOUR CAR';
  let BrakeRotorsSearchButtonTitle = 'SEARCH OTHER CARS';

  let BrakeRotorsData = [
    {
      image: Swift,
      searchkey: [
        {
          value: '63087a8278de19ec4482d498',
          name: 'make',
          label: 'Maruti Suzuki',
        },
        { value: '63087a9378de19ec4482d606', name: 'model', label: 'Swift' },
        { value: 'Brake Rotor', name: 'category', label: 'Brake Rotor' },
      ],
      title: 'Swift',
    },
    {
      image: VWPolo,
      searchkey: [
        {
          value: '63087a8278de19ec4482d487',
          name: 'make',
          label: 'Volkswagen',
        },
        { value: '63087a9278de19ec4482d520', name: 'model', label: 'Polo' },
        { value: 'Brake Rotor', name: 'category', label: 'Brake Rotor' },
      ],
      title: 'Polo',
    },
    {
      image: BMW530d,
      searchkey: [
        { value: '63087a8178de19ec4482d480', name: 'make', label: 'BMW' },
        { value: '63087a9178de19ec4482d4b3', name: 'model', label: '530d' },
        { value: '2007', name: 'year', label: '2007' },
        { value: 'Brake Rotor', name: 'category', label: 'Brake Rotor' },
      ],
      title: '530d E90',
    },
    {
      image: HondaCity,
      searchkey: [
        { value: '63087a8278de19ec4482d484', name: 'make', label: 'Honda' },
        { value: '63087a9178de19ec4482d4fa', name: 'model', label: 'City' },
        { value: '2017', name: 'year', label: '2017' },
        { value: 'Brake Rotor', name: 'category', label: 'Brake Rotor' },
      ],
      title: 'City VX',
    },
    {
      image: RenaultDuster,
      searchkey: [
        { value: '63087a8278de19ec4482d49a', name: 'make', label: 'Renault' },
        { value: '63087a9378de19ec4482d61c', name: 'model', label: 'Duster' },
        { value: 'Brake Rotor', name: 'category', label: 'Brake Rotor' },
      ],
      title: 'Duster',
    },
    {
      image: HyundaiElitei20,
      searchkey: [
        { value: '63087a8278de19ec4482d485', name: 'make', label: 'Hyundai' },
        {
          value: '63087a9178de19ec4482d50a',
          name: 'model',
          label: 'i20 Elite',
        },
        { value: 'Brake Rotor', name: 'category', label: 'Brake Rotor' },
      ],
      title: 'i20 Elite',
    },
    {
      image: Porsche911,
      searchkey: [
        { value: '63087a8278de19ec4482d492', name: 'make', label: 'Porsche' },
        { value: '63087a9278de19ec4482d552', name: 'model', label: '911' },
        { value: 'Brake Rotor', name: 'category', label: 'Brake Rotor' },
      ],
      title: '911',
    },
    {
      image: TataNexon,
      searchkey: [
        { value: '63087a8278de19ec4482d4a0', name: 'make', label: 'Tata' },
        { value: '63087a9478de19ec4482d679', name: 'model', label: 'Nexon' },
        { value: 'Brake Rotor', name: 'category', label: 'Brake Rotor' },
      ],
      title: 'Nexon',
    },
    {
      image: FordEcoSport,
      searchkey: [
        { value: '63087a8278de19ec4482d483', name: 'make', label: 'Ford' },
        { value: '63087a9178de19ec4482d4eb', name: 'model', label: 'Ecosport' },
        { value: 'Brake Rotor', name: 'category', label: 'Brake Rotor' },
      ],
      title: 'EcoSport',
    },
  ];

  return (
    <Box overflowX='hidden'>
      <Flex
        flexDir='column'
        justifyContent='center'
        alignItems='center'
        mt='20px'
        mb='30px'
      >
        <Flex
          flexDir='column'
          width='100%'
          alignItems='center'
          height={'90vh'}
          maxHeight='630px'
          minHeight={'585px'}
        >
          <Text className='hpHeader'>
            {/* Upgrading Your Brake is Easy */}
            The Simple Art of Brake Upgrade
          </Text>
          <Box className='hpHeaderdata'>
            {/* <Text> Welcome to Brakeup.</Text> */}
            {/* <Text> We Proudly Offer a Comprehensive Line of Precisely Shaped and High-Quality Rotors, Pads, Fluids &amp; Other Products. </Text> */}
            {/* <Text>Choose your car here to see the list of all your Favorite Brands</Text> */}
            <Text>
              Buy online Brake Pads, Brake Rotors, Brake Fluids and many more
            </Text>
          </Box>

          {/* <Flex width='80%' justifyContent='space-evenly' mt='-50px' zIndex={1}  >
            <Button className="btnSearchSignin" borderRadius='3xl' boxShadow='0px 2px 6px #330D2F33' bgColor="#FFFFFF" onClick={() => setIsComponentVisible(true)} >
              Search
            </Button>
            {props.isloggedin ? "" :
              <Button className="btnSearchSignin" borderRadius='3xl' boxShadow='0px 2px 6px #330D2F33' bgColor='#ECE9D2' onClick={() => history.push('/loginsignup')} >
                Sign in
              </Button>
            }
          </Flex> */}
          {/* 
          {isComponentVisible && (

            <Flex width='100%' p={3} position='absolute' bgColor='#F5F5F5' flexDirection='column' alignItems='center' ref={ref} zIndex="2"  >
              <Box className="hpSelect">
                <CustomSelect
                  inputStyle={props.inputStyle}
                  defaultValue={null}
                  name={'make'}
                  options={props.make}
                  value={props.filter?.make?.label}
                  onChange={props.onChange}
                  // {...register("make",{ required: true })}
                  control={control}
                  placeholder='Make'
                  size={props.size}
                />
                {props.error ? <span style={{ position: 'absolute', lineHeight: "16px", fontSize: '12px', color: "red" }}>Make is required.</span> : ""}

              </Box>
              <Button isLoading={props.SearchButtonLOader} mt={8} className="btnSearchSignin" borderRadius='3xl' boxShadow='0px 2px 6px #330D2F33' bgColor={props.isSelected3 ? "#FBDE00" : "#f7e996"} onClick={handleSubmit((d) => props.onSearch(d))}>
                Search
              </Button>

            </Flex>
          )} */}

          <Flex
            className='hpSearchBox'
            flexDirection='column'
            alignItems='center'
            minHeight='550px'
            width='100%'
            position={'relative'}
          >
            <Box
              className='hpSearchAnim glassSearchbox'
              // bgColor="#fae10a"
              borderRadius={'10px'}
              padding='3vh'
              height='max-content'
              minWidth='260px'
              maxWidth='375px'
              width='85%'
            >
              <form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  height: '100%',
                }}
              >
                <Box className='hpSelect'>
                  <CustomSelect
                    id='CustomSelectMake'
                    inputStyle={props.inputStyle}
                    name={'make'}
                    options={props.make}
                    value={props.filter?.make?.label}
                    onChange={props.onChange}
                    placeholder='Make'
                    size={props.size}
                    readOnly={true}
                    boxheight={'43vh'}
                    // boxmaxheight={"350px"}
                  />
                  {/* {props.error ? <span className="hpSelectError">Make is required.</span> : ""} */}
                </Box>

                <Box className='hpSelect'>
                  <CustomSelect
                    id='CustomSelectModel'
                    inputStyle={props.inputStyle}
                    name={'model'}
                    options={props.model}
                    value={props.filter?.model?.label}
                    onChange={props.onChange}
                    // {...register("model",{ required: true })}
                    rules={{ required: true }}
                    placeholder='Model'
                    size={props.size}
                    readOnly={true}
                    boxheight={'33vh'}
                    // boxmaxheight={"300px"}
                  />
                  {/* {errors.model && <span style={{ position: 'absolute', fontSize: '12px', color: "red" }}>Model is required.</span>} */}
                </Box>

                <Box className='hpSelect'>
                  <CustomSelect
                    id='CustomSelectYear'
                    inputStyle={props.inputStyle}
                    name={'year'}
                    options={props.year}
                    value={props.filter?.year?.label}
                    onChange={props.onChange}
                    // {...register("year",{ required: true })}
                    rules={{ required: false }}
                    placeholder='Year of manufacture'
                    size={props.size}
                    readOnly={true}
                    boxheight={'30vh'}
                  />
                  {/* {errors.year && <span style={{ position: 'absolute', fontSize: '12px', color: "red" }}>Year is required.</span>} */}
                </Box>

                {props.version.length != 0 && (
                  <Box className='hpSelect'>
                    <CustomSelect
                      inputStyle={props.inputStyle}
                      name={'version'}
                      options={props.version}
                      value={props.filter?.version?.label}
                      onChange={props.onChange}
                      rules={{ required: false }}
                      placeholder='Version'
                      size={props.size}
                      readOnly={true}
                    />
                    {/* {errors.type && <span style={{ color: "red" }}>Version is required.</span>} */}
                  </Box>
                )}

                <Box className='hpSelect'>
                  <CustomSelect
                    inputStyle={props.inputStyle}
                    name={'fuel'}
                    // options={[{ "label": "Petrol", id: "Petrol" },
                    // { "label": "Diesel", id: "Diesel" }]}
                    options={props.fuel}
                    onChange={props.onChange}
                    value={props.filter?.fuel?.label}
                    rules={{ required: false }}
                    placeholder='Fuel type'
                    size={props.size}
                    readOnly={true}
                  />
                  {/* {errors.fuel && <span style={{ color: "red" }}>Fuel is required.</span>} */}
                </Box>

                <Box className='hpSelect'>
                  <CustomSelect
                    inputStyle={props.inputStyle}
                    name={'type'}
                    options={[
                      { label: 'Front', id: 'Front' },
                      { label: 'Rear', id: 'Rear' },
                    ]}
                    onChange={props.onChange}
                    value={props.filter?.type?.label}
                    rules={{ required: false }}
                    placeholder='Front/rear'
                    size={props.size}
                    readOnly={true}
                  />
                  {/* {errors.type && <span style={{ color: "red" }}>Front/rear is required.</span>} */}
                </Box>

                <Flex width='100%' className='hpSelect' justifyContent='center'>
                  <Button
                    isLoading={props.SearchButtonLOader}
                    onClick={handleSubmit((d) => props.onSearch(d))}
                    className='hpbtnsearch'
                    width='60%'
                    borderRadius='5px'
                    color='white'
                    bgColor={
                      props.isSelected3
                        ? '#414bdb'
                        : props.isSelected2
                        ? '#6a71d7'
                        : props.isSelected1
                        ? '#989cd8'
                        : '#b8bada'
                    }
                  >
                    {props.isSelected3
                      ? 'PROCEED'
                      : props.isSelected2
                      ? 'SELECT YEAR'
                      : props.isSelected1
                      ? 'SELECT MODEL'
                      : 'SELECT MAKE'}
                  </Button>
                </Flex>
              </form>
            </Box>

            <Flex
              alignSelf='flex-end'
              position={'absolute'}
              zIndex={-1}
              bottom={0}
            >
              <Image
                className='hpCarImgWeb'
                marginTop='0px'
                minHeight='300px'
                maxHeight='700px'
                height='40vw'
                src={Car}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex width='80%' justifyContent='space-evenly' zIndex={1}>
          {props.isloggedin ? (
            ''
          ) : (
            <Button
              className='btnSearchSignin'
              borderRadius='6px'
              onClick={() => history.push('/loginsignup')}
            >
              <FiUser fontSize='1.5rem' />
              <Text ml={2}>LOGIN</Text>
            </Button>
          )}
          <Button
            className='btnSearchSignin'
            borderRadius='6px'
            onClick={onOpen}
          >
            <FiSearch fontSize='1.5rem' />
            <Text ml={2}>SEARCH</Text>
          </Button>
        </Flex>

        {/* {!isKeyboardVisible &&
          <Box className='mobile_only font_Nunito footerglass' width='100vw' position='fixed' zIndex={4} bottom={0} >
            <Flex justifyContent='space-evenly' height={'53px'}  >
              <Flex
                onClick={() => history.push('/')}
                borderTopLeftRadius='8px' justifyContent='space-between' flexDirection='column' alignItems='center' marginBottom={"5px"}>
                <Box borderBottomLeftRadius={"5px"} borderBottomRightRadius="5px" bgColor="var(--turbo)" height={"4px"} width="60px" />
                <Image width={'20px'} src={logo} />
                <Text fontSize={'11px'}>Home</Text>
              </Flex>
              <Flex
                onClick={onOpen}
                borderTopLeftRadius='8px'
                justifyContent='space-between' flexDirection='column' alignItems='center' marginBottom={"5px"}>
                <Box borderBottomLeftRadius={"5px"} borderBottomRightRadius="5px" bgColor="var(--turbo)" height={"4px"} width="60px" />
                <Image objectFit={'contain'} width={'20px'} src={search} />
                <Text fontSize={'11px'}>Search</Text>
              </Flex>
              <Flex
                onClick={() => history.push("/myprofilemobile/4")}
                borderTopLeftRadius='8px'
                justifyContent='space-between' flexDirection='column' alignItems='center' marginBottom={"5px"}>
                <Box borderBottomLeftRadius={"5px"} borderBottomRightRadius="5px" bgColor="var(--turbo)" height={"4px"} width="60px" />
                <Image objectFit={'contain'} width={'20px'} src={orderbox} />
                <Text fontSize={'11px'}>Orders</Text>
              </Flex>
              <Flex
                onClick={() => history.push('/customercare')}
                borderTopLeftRadius='8px'
                justifyContent='space-between' flexDirection='column' alignItems='center' marginBottom={"5px"}>
                <Box borderBottomLeftRadius={"5px"} borderBottomRightRadius="5px" bgColor="var(--turbo)" height={"4px"} width="60px" />
                <Image objectFit={'contain'} width={'20px'} src={customercare} />
                <Text fontSize={'11px'}>Help</Text>
              </Flex>
            </Flex>
          </Box>
        } */}

        <Box className='HPcategorygrid'>
          <Grid
            templateColumns='repeat(2, 1fr)'
            justifyContent='space-evenly'
            gridGap={'4vw'}
          >
            <GridItem
              className=' hbProbc'
              padding='3vw'
              bgColor='#FBDE00'
              onClick={() =>
                props.onClickCategory({
                  value: 'Brake Pad',
                  name: 'category',
                  label: 'Brake Pad',
                })
              }
            >
              <Image className=' hbProbcImg' src={pad} />
              <Text className=' hbProbcTitles'>BRAKE PADS</Text>
            </GridItem>
            <GridItem
              className=' hbProbc'
              padding='3vw'
              bgColor='#FBDE00'
              onClick={() =>
                props.onClickCategory({
                  value: 'Brake Rotor',
                  name: 'category',
                  label: 'Brake Rotor',
                })
              }
            >
              <Image className=' hbProbcImg' src={rotor} />
              <Text className=' hbProbcTitles'>BRAKE ROTOR</Text>
            </GridItem>
            <GridItem
              className=' hbProbc'
              padding='3vw'
              bgColor='#FBDE00'
              onClick={() =>
                props.onClickCategory({
                  value: 'Brake Fluid',
                  name: 'category',
                  label: 'Brake Fluid',
                })
              }
            >
              <Image className=' hbProbcImg' src={fluid} />
              <Text className=' hbProbcTitles'>BRAKE FLUID</Text>
            </GridItem>
            <GridItem
              className=' hbProbc'
              padding='3vw'
              bgColor='#FBDE00'
              onClick={() =>
                props.onClickCategory({
                  value: 'Other',
                  name: 'category',
                  label: 'Other',
                })
              }
            >
              <Image className=' hbProbcImg' src={other} />
              <Text className=' hbProbcTitles'>OTHER PRODUCTS</Text>
            </GridItem>
          </Grid>

          <AdsMob
            adStyle={{ marginTop: '7vw' }}
            adStyleImg={{
              borderRadius: '16px',
              boxShadow: '0px 1px 1px #00000026',
            }}
          />

          <Flex
            mt='7vw'
            position='relative'
            onClick={() => props.onClickAds([{}])}
          >
            <Image
              borderRadius='16px'
              boxShadow='0px 1px 1px #00000026'
              src={AdM1}
              loading='lazy'
            />
            {/* <Button className="hpbtnAdDarkE" border='1px' borderColor='#000' position='absolute' bottom='12%' right="8%" bgColor='transparent' color='#000' borderRadius='3xl' rightIcon={<BiChevronRightCircle />}
              >Explore Now </Button> */}
          </Flex>

          <Flex
            mt='7vw'
            position='relative'
            onClick={() => history.push('/customercare')}
          >
            <Image
              borderRadius='16px'
              boxShadow='0px 1px 1px #00000026'
              src={contactUs}
              loading='lazy'
            />
          </Flex>

          <FilterIconsx9Mob
            heading={FilterbyCarsHeading}
            backgroundFilterImage={''}
            data={FilterbyCarsData}
            SearchButtonTitle={FilterbyCarsSearchButtonTitle}
            onClickFilter={props.onClickAds}
          />

          <Flex
            mt='7vw'
            position='relative'
            onClick={() => props.onClickAds([{}])}
          >
            <Image
              borderRadius='16px'
              boxShadow='0px 1px 1px #00000026'
              src={AdM2}
              loading='lazy'
            />
            {/* <Button className="hpbtnAdDarkE" border='1px' borderColor='#000' position='absolute' bottom='5%' left="38%" bgColor='transparent' color='#000' borderRadius='3xl' rightIcon={<BiChevronRightCircle />}
               >Explore Now </Button> */}
          </Flex>

          <Flex
            mt='7vw'
            position='relative'
            onClick={() =>
              props.onClickAds([
                { value: 'Brembo', name: 'brand', label: 'Brembo' },
              ])
            }
          >
            <Image
              borderRadius='16px'
              boxShadow='0px 1px 1px #00000026'
              src={AdM3}
              loading='lazy'
            />
            {/* <Button className="hpbtnAdDarkE" border='1px' borderColor='#000' position='absolute' bottom='3%' left="38%" bgColor='transparent' color='#000' borderRadius='3xl' rightIcon={<BiChevronRightCircle />}
              >Explore Now </Button> */}
          </Flex>

          {/* <AdsMob AD2={true} adStyle={{ marginTop: "7vw" }} adStyleImg={{ borderRadius: '16px', boxShadow: "0px 1px 1px #00000026"}} /> */}

          <FilterIconsx9Mob
            heading={TopBrandHeading}
            backgroundFilterImage={''}
            data={BrandData}
            SearchButtonTitle={TopBrandSearchButtonTitle}
            onClickFilter={props.onClickCategory}
          />

          <Flex
            mt='7vw'
            position='relative'
            onClick={() =>
              props.onClickAds([
                { value: 'Brembo', name: 'brand', label: 'Brembo' },
                {
                  value: '63087a8178de19ec4482d480',
                  name: 'make',
                  label: 'BMW',
                },
              ])
            }
          >
            <Image
              borderRadius='16px'
              boxShadow='0px 1px 1px #00000026'
              src={AdM4}
              loading='lazy'
            />
            {/* <Button className="hpbtnAdDarkE" border='1px' borderColor='#000' position='absolute' bottom='12%' right="8%" bgColor='transparent' color='#000' borderRadius='3xl' rightIcon={<BiChevronRightCircle />}
              >Explore Now </Button> */}
          </Flex>

          <Flex
            mt='7vw'
            position='relative'
            onClick={() =>
              props.onClickAds([{ value: 'EBC', name: 'brand', label: 'EBC' }])
            }
          >
            <Image
              borderRadius='16px'
              boxShadow='0px 1px 1px #00000026'
              src={AdM5}
              loading='lazy'
            />
            {/* <Button className="hpbtnAdDarkE" border='1px' borderColor='#000' position='absolute' bottom='12%' right="8%" bgColor='transparent' color='#000' borderRadius='3xl' rightIcon={<BiChevronRightCircle />}
              >Explore Now </Button> */}
          </Flex>

          <FilterIconsx9Mob
            heading={BrakePadsHeading}
            backgroundFilterImage={PadBG}
            data={BrakePadsData}
            SearchButtonTitle={BrakePadsSearchButtonTitle}
            onClickFilter={props.onClickAds}
          />

          {/* <Flex position='relative' onClick={() => props.onClickAds([{ value: "Brembo", name: "brand", label: "Brembo" }])} >
            <Image borderRadius='16px' src={AdM5} loading="lazy" />
            <Button className="hpbtnAdDarkE" border='1px' borderColor='#F22020' position='absolute' top='45%' left="5%" bgColor='transparent' color='#000' borderRadius='3xl' rightIcon={<BiChevronRightCircle />}
            >Explore Now </Button>
          </Flex> */}

          <Flex
            mt='7vw'
            position='relative'
            onClick={() =>
              props.onClickAds([
                {
                  value: 'Brake Rotor',
                  name: 'category',
                  label: 'Brake Rotor',
                },
              ])
            }
          >
            <Image
              borderRadius='16px'
              boxShadow='0px 1px 1px #00000026'
              src={AdM6}
              loading='lazy'
            />
            {/* <Button className="hpbtnAdDarkE" border='1px' borderColor='#000' position='absolute' bottom='5%' left="38%" bgColor='transparent' color='#000' borderRadius='3xl' rightIcon={<BiChevronRightCircle />}
               >Explore Now </Button> */}
          </Flex>

          <Flex
            mt='7vw'
            position='relative'
            onClick={() =>
              props.onClickAds([
                {
                  value: '63087a8278de19ec4482d498',
                  name: 'make',
                  label: 'Maruti Suzuki',
                },
                {
                  value: '63087a9378de19ec4482d5f2',
                  name: 'model',
                  label: 'Alto',
                },
              ])
            }
          >
            <Image borderTopRadius='16px' src={AdM7} loading='lazy' />
            {/* <Button className="hpbtnAdDark" _focus={{ bgColor: "transparent", color: "#fff", border: '2px', borderColor: '#fff' }} position='absolute' bottom='40%' left="4%" bgColor='#000' color='#fff' borderRadius='3xl'
            >Shop Now</Button> */}
          </Flex>

          <Flex>
            <Image src={Quotebg} loading='lazy' />
          </Flex>

          <Flex
            position='relative'
            onClick={() =>
              props.onClickAds([
                { value: 'Bosch', name: 'brand', label: 'Bosch' },
              ])
            }
          >
            <Image borderBottomRadius='16px' src={AdM8} loading='lazy' />
            {/* <Button className="hpbtnAdDark" _focus={{ bgColor: "transparent", color: "#fff", border: '2px', borderColor: '#fff' }} position='absolute' top='37%' right="16%" bgColor='#FBDE00' color='#000' borderRadius='3xl'
            >Shop Now</Button> */}
          </Flex>

          {/* <AdsMob AD3={true} adStyle={{ marginTop: "7vw" }} adStyleImg={{ borderRadius: '16px', boxShadow: "0px 1px 1px #00000026"}} /> */}

          <FilterIconsx9Mob
            heading={BrakeRotorsHeading}
            backgroundFilterImage={RotorBG}
            data={BrakeRotorsData}
            SearchButtonTitle={BrakeRotorsSearchButtonTitle}
            onClickFilter={props.onClickAds}
          />

          <Flex mt='7vw'>
            <Flex
              width={'47%'}
              position='relative'
              onClick={() =>
                props.onClickAds([
                  { value: 'EBC', name: 'brand', label: 'EBC' },
                  {
                    value: '63087a8278de19ec4482d487',
                    name: 'make',
                    label: 'Volkswagen',
                  },
                ])
              }
            >
              <Image
                borderRadius='16px'
                boxShadow='0px 1px 1px #00000026'
                src={AdM8a}
                loading='lazy'
              />
              {/* <Button className="hpbtnAdDark" _focus={{ bgColor: "transparent", color: "#000", border: '2px', borderColor: '#000' }} position='absolute' bottom='3%' left="30%" bgColor='#2772b4' color='#fff' borderRadius='3xl'
              >Shop Now</Button> */}
            </Flex>
            <Flex
              width={'53%'}
              position='relative'
              ml='4vw'
              onClick={() =>
                props.onClickAds([
                  { value: 'ICER', name: 'brand', label: 'ICER' },
                ])
              }
            >
              <Image
                borderRadius='16px'
                boxShadow='0px 1px 1px #00000026'
                src={AdM8b}
                loading='lazy'
              />
              <Box className='hpaAdLottie8b' position='absolute'>
                <lottie-player
                  src={API_URL + '/api/v1/json/Brakeup_Meyle_Ad.json'}
                  background='transparent'
                  speed='1'
                  loop
                  autoplay
                ></lottie-player>
              </Box>
            </Flex>
          </Flex>

          <AdsWeb
            AD3={true}
            adStyle={{ marginTop: '2vw' }}
            adStyleImg={{
              borderRadius: '5px',
              boxShadow: '0px 1px 1px #00000026',
            }}
          />
        </Box>

        <TopSellingProducts
          topselldata={props.topselldata}
          onClickTopSell={props.onClickTopSell}
        />

        <Flex
          mt='7vw'
          width='100%'
          // boxShadow='0px 0px 99px #00000026' bgColor='white'
          justifyContent={'center'}
        >
          <Grid
            templateColumns='repeat(5, 1fr)'
            justifyContent='space-evenly'
            padding='1vw'
            justifyItems='center'
            alignItems='baseline'
            ref={showwhatsappref}
          >
            <GridItem className='highlightSec ' padding='3px'>
              <Image className='highlightsImg' src={high1} />
              <Text className='highlightsfont '>Top Brands</Text>
            </GridItem>
            <GridItem className='highlightSec' padding='3px'>
              <Image className=' highlightsImg' src={high2} />
              <Text className='highlightsfont'>Genuine Products</Text>
            </GridItem>
            <GridItem className='highlightSec' padding='3px'>
              <Image className=' highlightsImg' src={high3} />
              <Text className='highlightsfont'>Compatibility Guaranteed</Text>
            </GridItem>
            <GridItem className='highlightSec' padding='3px'>
              <Image className=' highlightsImg' src={high4} />
              <Text className='highlightsfont'>Fast & Free Delivery</Text>
            </GridItem>
            <GridItem className='highlightSec' padding='3px'>
              <Image className='highlightsImg' src={high5} />
              <Text className='highlightsfont'>Discount Deals</Text>
            </GridItem>
          </Grid>

          {!onScreen && (
            <Box
              height={'60px'}
              borderRadius={'15px'}
              boxShadow={'0px 4px 8px rgba(0, 0, 0, 0.75)'}
              position='fixed'
              zIndex={6}
              bottom={5}
              right={5}
            >
              <ReactWhatsapp number='+917025802580' message='Hi Brakeup...👋'>
                <Image height={'60px'} src={Whatsapp_logo} />
              </ReactWhatsapp>
            </Box>
          )}
        </Flex>
      </Flex>

      {/* global search */}

      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
      >
        <AlertDialogOverlay style={{ backdropFilter: 'blur(3px)' }} />

        <AlertDialogContent m='30px 0px' bgColor='transparent' boxShadow='none'>
          <AlertDialogBody bgColor='none' position='fixed' width='100%'>
            <Mobilesearch onClose={onClose} />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  );
};
