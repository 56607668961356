import { Fragment, useRef, useState, useEffect, useContext } from 'react';
import { Popover, Transition } from '@headlessui/react';

import { useForm } from 'react-hook-form';
import Successmodal from '../Modals/Successmodal';
import { post } from '../../services/api';
import singleroundlogo from '../../assets/footer_img/Squar single rotor brakeup.png';
import closemark from '../../assets/sarath_IMG/x.svg';
import { Box, Flex, Image } from '@chakra-ui/react';

function EnquiryPopover(props) {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onSubmit' });

  const [isOpenSuccess, setIsOpenSuccess] = useState(false);

  const [displayMessage, setdisplayMessage] = useState(false);

  const [description, setDescription] = useState('');
  const handleChange = (event) => {
    setDescription(event.target.value);
  };

  let isanonymous = true;
  const token = JSON.parse(localStorage.getItem('token') || '{}').data?.credAuthResult;

  if (token?.type == 'Access') {
    isanonymous = false;
  } else {
    isanonymous = true;
  }

  const validatePhone = (number) => {
    // Phone number validations
    var re = /^[6-9]\d{9}$/;
    return re.test(number);
  };

  const validateEmail = (mail) => {
    // Email validations
    var re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return re.test(mail.toLowerCase());
  };

  const onSubmit = (data) => {
    data.type = 'customer_enquiry';
    if (!data.desc.trim()) {
      return;
    }
    if (!isanonymous) {
      data.name = token?.name;
      data.mobile = token?.mobile;
      data.email = token?.email;
    }
    post('/api/v1/seller/customer_care/', data)
      .then((res) => {
        setIsOpenSuccess(true);
        buttonRef?.current?.click();
        reset();
      })
      .catch((err) => {});
  };

  const IsEnquiryClosed = localStorage.getItem('enquiryClosed');

  useEffect(() => {
    if (IsEnquiryClosed == 'true') {
      setTimeout(() => {
        localStorage.setItem('enquiryClosed', false);
        setdisplayMessage(true);
      }, 40000);
    } else {
      setdisplayMessage(true);
    }
  }, []);

  const closeMessage = () => {
    localStorage.setItem('enquiryClosed', true);
    setdisplayMessage(false);
  };

  const [IsPopoverOpen, setIsPopoverOpen] = useState(false);

  const buttonRef = useRef(null); // useRef<HTMLButtonElement>(null)

  const togglePopover = () => {
    if (IsPopoverOpen) {
      document.getElementById('popoverbutton').click();
    } else buttonRef?.current?.click();
  };

  return (
    <>
      {displayMessage && (
        <Box
          className={`md:mb-2 flex items-center justify-center font-bold message slide-up`}
          style={{
            position: 'fixed',
            zIndex: 5,
            bottom: 71,
            right: 27,
          }}>
          <div
            className='px-4 py-2 font-bold text-black bg-white border border-gray-300 rounded-lg shadow-md cursor-pointer bg-opacity-30 font-manrope md:text-sm 2xl:text-base enquiryMesssage'
            onClick={togglePopover}>
            Hi! Need any assistance?
          </div>
          <button
            onClick={closeMessage}
            className='enquiryCloseBtn'>
            <Image
              src={closemark}
              alt=''
              layout='responsive'
              className='w-auto h-5 md:w-5'
            />
          </button>
        </Box>
      )}

      <Popover className={'fixed bottom-12 right-0 z-20'}>
        {({ open, close }) => {
          useEffect(() => {
            if (open) {
              setIsPopoverOpen(open);
              closeMessage();
            }
          }, [open]);

          return (
            <>
              <Popover.Button
                ref={buttonRef}
                className={'focus:outline-none '}>
                <div
                  className='rotate-container'
                  style={{
                    position: 'fixed',
                    bottom: '15px',
                    right: '22px',
                    zIndex: '20',
                  }}>
                  <Image
                    src={singleroundlogo}
                    alt='Round PNG'
                    className='rotating-image'
                  />
                </div>
              </Popover.Button>

              <Transition
                show={open}
                as={Fragment}
                enter='transition ease-out duration-200'
                enterFrom='opacity-0 translate-y-1'
                enterTo='opacity-100 translate-y-0'
                leave='transition ease-in duration-150'
                leaveFrom='opacity-100 translate-y-0'
                leaveTo='opacity-0 translate-y-1'>
                <Popover.Panel
                  className='md:p-3 w-80'
                  tabIndex='0'
                  style={{
                    position: 'absolute',
                    bottom: '19px',
                    right: '27px',
                    transition: 'width 0.3s, height 0.3s',
                    overflow: 'hidden',
                    borderRadius: '16px',
                    background: '#E1E4F1',
                  }}>
                  <button
                    onClick={() => close()}
                    id={'popoverbutton'}>
                    <Image
                      src={closemark}
                      alt=''
                      color='greyB5'
                      layout='responsive'
                      className='absolute w-25 md:w-30 h-25 right-2 top-2'
                    />
                  </button>

                  <div
                    color='black'
                    opacity={0.5}
                    style={{ fontSize: '16px', top: '10px', right: '10px' }}
                  />
                  <div style={{ padding: '0' }}>
                    <Box className='flex items-center justify-center font-bold uppercase md:mb-1 popup_font1618_20 whitespace-nowrap'>
                      Need any assistance?
                    </Box>
                    <Box className='flex items-center justify-center md:mb-3.5 mb-2 popup_font16_18'>
                      We’re here to help
                    </Box>
                  </div>

                  <div style={{ padding: '0' }}>
                    <Flex className='md:px-2.5 items-center'>
                      <form className='cuscare_form'>
                        {isanonymous && (
                          <Flex className='flex-col '>
                            <Box>
                              <input
                                className='px-3 py-1 font-normal ccr_inputbox '
                                placeholder='Name*'
                                name='name'
                                ref={register({
                                  required: true,
                                })}
                              />

                              <div className='h-4 ml-1 text-left '>
                                {errors && errors.name && errors.name.type == 'required' && (
                                  <div className='errormsg font-manrope text-xxs md:text-xs'>
                                    Name is required !
                                  </div>
                                )}
                              </div>
                            </Box>
                            <Box>
                              <div className=''>
                                <input
                                  className='px-3 py-1 font-normal ccr_inputbox '
                                  placeholder='Phone*'
                                  name='mobile'
                                  type='number'
                                  ref={register({
                                    required: true,
                                    validate: validatePhone,
                                    valueAsNumber: true,
                                  })}
                                />
                              </div>

                              <div className='h-4 ml-1 text-left'>
                                {errors && errors.mobile && errors.mobile.type == 'required' && (
                                  <div className='errormsg font-manrope text-xxs md:text-xs'>
                                    Mobile is required !
                                  </div>
                                )}
                                {errors && errors.mobile && errors.mobile.type == 'validate' && (
                                  <div className='errormsg font-manrope text-xxs md:text-xs'>
                                    {' '}
                                    Invalid input
                                  </div>
                                )}
                              </div>
                            </Box>
                          </Flex>
                        )}

                        {isanonymous && (
                          <Flex>
                            <Box width={'100%'}>
                              <input
                                className='px-3 py-1 font-normal ccr_inputbox '
                                placeholder='Email*'
                                name='email'
                                ref={register({
                                  required: true,
                                  validate: validateEmail,
                                })}
                              />
                              <div className='h-4 ml-1 text-left '>
                                {errors && errors.email && errors.email.type == 'required' && (
                                  <div className='errormsg font-manrope text-xxs md:text-xs'>
                                    Email is required !
                                  </div>
                                )}
                                {errors && errors.email && errors.email.type == 'validate' && (
                                  <div className='errormsg font-manrope text-xxs md:text-xs'>
                                    {' '}
                                    Invalid input
                                  </div>
                                )}
                              </div>
                            </Box>
                          </Flex>
                        )}

                        <div>
                          <textarea
                            className='p-3 font-normal ccr_inputbox '
                            placeholder='Tell us your concern!'
                            name='desc'
                            onChange={handleChange}
                            ref={register({
                              required: false,
                            })}
                            style={{ minHeight: '150px' }}
                          />
                        </div>
                        <Flex className=' items-center justify-center md:mt-3.5 mt-3'>
                          <button
                            // ref={initialFocusRef}
                            type='submit'
                            onClick={handleSubmit((d) => onSubmit(d))}
                            className=' ModalyellowBtn  px-2 py-1.5 popup_font16_18 font-bold'>
                            Get a call back
                          </button>
                        </Flex>
                      </form>
                    </Flex>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>

      <Successmodal
        isOpen={isOpenSuccess}
        manualclose={true}
        setIsOpen={() => setIsOpenSuccess(false)}
        message={
          <div className='mb-2 font-manrope'>
            <p className='text-xl'>Thank you for your request.</p>
            <p className='font-normal'>We will be in touch soon.</p>
            <p className='text-sm font-normal'>
              Quick help, Call/WhatsApp{' '}
              <a
                href={`https://wa.me/917025802580?text=Hi%20Brakeup...👋%0A%0A${description}`}
                target='_blank'
                className='text-base font-bold text-blueB1 hover:underline'>
                +917025802580
              </a>
            </p>
          </div>
        }
      />
    </>
  );
}

export default EnquiryPopover;
