import React, { useEffect, useState } from "react";

import { Box, Button, ChakraProvider, Flex, Image } from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  CloseButton,
} from "@chakra-ui/react";
import { post } from "../services/api";
import { useForm } from "react-hook-form";
import Successmodal from "../components/Modals/Successmodal";

function EnquiryPopup(props) {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm({ mode: "onSubmit" });

  const [isOpenSuccess, setIsOpenSuccess] = useState(false);

  let isanonymous = true;
  const token = JSON.parse(localStorage.getItem("token") || "{}").data
    ?.credAuthResult;

  if (token?.type == "Access") {
    isanonymous = false;
  } else {
    isanonymous = true;
  }

  const validatePhone = (number) => {
    // Phone number validations
    var re = /^[6-9]\d{9}$/;
    return re.test(number);
  };

  const validateEmail = (mail) => {
    // Email validations
    // var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return re.test(mail.toLowerCase());
  };

  const onSubmit = (data) => {
    // data.email = '';
    data.type = "customer_enquiry";
    console.log(data, "dataaaaa");

    if (!data.desc.trim()) {
      return;
    }

    if (!isanonymous) {
      data.name = token?.name;
      data.mobile = token?.mobile;
      data.email = token?.email;
    }

    // post("/api/v1/seller/customer_care/", data)
    //   .then((res) => {
    setIsOpenSuccess(true);
    props.onClose();
    //   reset();
    // })
    // .catch((err) => {});
  };

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        // isCentered={false}
        autoFocus={false}
        // closeOnOverlayClick={false}
        blockScrollOnMount={true}
        size={
          window.innerWidth >= 800
            ? "xl"
            : window.innerWidth <= 395
            ? "xs"
            : "sm"
        }
      >
        <ModalOverlay />
        <ModalContent
          style={{
            position: "fixed",
            bottom: "96px",
            right: "80px",
          }}
          className="md:p-7 p-6"
          borderRadius={"16px"}
          bg="#E1E4F1"
        >
          <ModalCloseButton
            zIndex={1}
            color="black"
            opacity={0.5}
            className="text-base md:text-lg"
          />

          <Flex className="md:px-2.5 items-center">
            <form className="cuscare_form">
              <Box className="uppercase md:mb-2  flex items-center justify-center font-bold popup_font1618_20">
                Need any assistance?
              </Box>
              <Box className="flex items-center justify-center md:mb-3.5 mb-3 popup_font16_18">
                We’re here to help
              </Box>
              {isanonymous && (
                <Flex className="flex-col ">
                  <Box>
                    <input
                      className="ccr_inputbox md:p-3 py-1 px-3 font-normal drop-shadow-dsInput"
                      placeholder="Name*"
                      name="name"
                      ref={register({
                        required: true,
                      })}
                    />

                    <div className="md:h-6 h-4 text-left ml-1 ">
                      {errors &&
                        errors.name &&
                        errors.name.type == "required" && (
                          <div className="errormsg font-manrope text-xxs md:text-xs">
                            Name is required !
                          </div>
                        )}
                    </div>
                  </Box>
                  <Box>
                    <div className="">
                      {/* <span className='prefix'>+91</span> */}
                      <input
                        className="ccr_inputbox md:p-3 py-1 px-3 font-normal drop-shadow-dsInput"
                        placeholder="Phone*"
                        name="mobile"
                        type="number"
                        ref={register({
                          required: true,
                          validate: validatePhone,
                          valueAsNumber: true,
                        })}
                      />
                    </div>

                    <div className="md:h-6 h-4 text-left ml-1">
                      {errors &&
                        errors.mobile &&
                        errors.mobile.type == "required" && (
                          <div className="errormsg font-manrope text-xxs md:text-xs">
                            Mobile is required !
                          </div>
                        )}
                      {errors &&
                        errors.mobile &&
                        errors.mobile.type == "validate" && (
                          <div className="errormsg font-manrope text-xxs md:text-xs">
                            {" "}
                            Invalid input
                          </div>
                        )}
                    </div>
                  </Box>
                </Flex>
              )}
              {isanonymous && (
                <Flex>
                  <Box width={"100%"}>
                    <input
                      className="ccr_inputbox md:p-3 py-1 px-3 font-normal drop-shadow-dsInput"
                      placeholder="Email*"
                      name="email"
                      ref={register({
                        required: true,
                        validate: validateEmail,
                      })}
                    />
                    <div className="md:h-6 h-4 text-left ml-1">
                      {errors &&
                        errors.email &&
                        errors.email.type == "required" && (
                          <div className="errormsg font-manrope text-xxs md:text-xs">
                            Email is required !
                          </div>
                        )}
                      {errors &&
                        errors.email &&
                        errors.email.type == "validate" && (
                          <div className="errormsg font-manrope text-xxs md:text-xs">
                            {" "}
                            Invalid input
                          </div>
                        )}
                    </div>
                  </Box>
                </Flex>
              )}

              <div>
                <textarea
                  className="ccr_inputbox p-3 font-normal drop-shadow-dsInput"
                  placeholder="Tell us your concern!"
                  name="desc"
                  ref={register({
                    required: false,
                  })}
                  style={{ minHeight: "155px" }}
                />
              </div>
              <Flex className=" items-center justify-center md:mt-3.5 mt-3">
                <button
                  type="submit"
                  onClick={handleSubmit((d) => onSubmit(d))}
                  className=" ModalyellowBtn md:px-4 md:py-2.5 px-2 py-1.5 popup_font16_18 font-bold"
                >
                  Get a call back
                </button>
              </Flex>
            </form>
          </Flex>
        </ModalContent>
      </Modal>

      <Successmodal
        isOpen={isOpenSuccess}
        manualclose={true}
        setIsOpen={() => setIsOpenSuccess(false)}
        message={
          <div className="mb-2 font-manrope">
            <p className="text-xl ">Thank you for your request.</p>
            <p className="font-normal"> We will be in touch soon.</p>
            <p className="font-normal text-sm">
              Quick help, Call/WhatsApp{" "}
              <a
                href="https://wa.me/917025802580?text=Hi%20Brakeup...👋"
                target="_blank"
                className="text-blueB1 hover:underline text-base font-bold"
              >
                +917025802580
              </a>
            </p>
          </div>
        }
      />
    </>
  );
}

export default EnquiryPopup;
