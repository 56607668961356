import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentType: '',
  paymentPriceDetails: {},
  orderDetails: {},
  cartItems: [],
  codDisabled: false,
  FDticket: 0,
};

export const paymentDetails = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setpaymentType: (state, action) => {
      state.paymentType = action.payload;
    },
    setpaymentPriceDetails: (state, action) => {
      state.paymentPriceDetails = action.payload;
    },
    setorderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    setcartItemss: (state, action) => {
      state.cartItems = action.payload;
    },
    setcodDisabled: (state, action) => {
      state.codDisabled = action.payload;
    },
    setFDticket: (state, action) => {
      state.FDticket = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setpaymentType,
  setpaymentPriceDetails,
  setorderDetails,
  setcartItemss,
  setcodDisabled,
  setFDticket,
} = paymentDetails.actions;

export default paymentDetails.reducer;
