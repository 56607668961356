import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Box, Divider, Button, Flex, Image, Text, Grid, GridItem, IconButton, Spacer, InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import { API_URL } from "../../services/api";
import { get, post } from "../../services/api";

import { SearchIcon } from '@chakra-ui/icons';
import { FiSearch } from "react-icons/fi";

import { useHistory } from "react-router-dom";



export const Mobilesearch = (props) => {

    let history = useHistory();


    // const [searchdata, setSearchdata] = useState("");


    // useEffect(() => {

    //     get('/api/v1/search/buyer_search/?q=Homepage_search').then(res => {

    //         setSearchdata(res.data)

    //     }).catch(err => {
    //     })

    //     return () => {
    //     }
    // }, [])


    // const NavSearch = (e) => {

    //     get(`/api/v1/search/buyer_search/?q=Homepage_search&s=${e}`).then(res => {

    //         setSearchdata(res.data)

    //     }).catch(err => {
    //         setSearchdata([])
    //     })
    // }

    // const search2Filter = (data) => {
    //     let filterdata = {}
    //     if (data?.category) {
    //         filterdata["category"] = { "value": data?.category, "name": "category", "label": data?.category }
    //     }
    //     if (data?.brand) {
    //         filterdata["brand"] = { "value": data?.brand, "name": "brand", "label": data?.brand }
    //     }
    //     // if (data?.brand) {
    //     //     filterdata["brand"] = { "value": data?.brand, "name": "brand", "label": data?.brand }
    //     // }

    //     localStorage.setItem("filter", JSON.stringify(filterdata));
    //     history.push(`/filter`);

    // }

    // const [select, setselect] = useState(0);



    // let searchlist = []

    // searchdata && searchdata.forEach((element,index) => {

    //     searchlist.push(
    //         <Flex p={1} pl={4} borderBottom='1px solid' borderColor='#eae6e6' alignItems='center' borderTopRadius='5px' 
    //         bgColor={index == select ? '#ECE9D2' : ''}
    //         // _hover={() => setselect(index)}
    //         onClick={() => { search2Filter(element) }}>
    //             <Image mr={2} ml={2} height={10} width={10} objectFit="contain" src={element.image[0]} />
    //             <Box p={1} textAlign='start'>
    //                 <Flex>
    //                     <Text mr={2} textTransform='uppercase' fontWeight='bold' fontSize="13px">{element.brand}</Text>
    //                     <Text textTransform='capitalize' fontWeight='bold' fontSize="13px">{element.prod_name}</Text>
    //                 </Flex>
    //                 <Text color='#959595' fontWeight='400' fontSize="10px">in {element.category}</Text>
    //             </Box>
    //         </Flex>
    //     )
    // })

    // console.log(select,"sarath");
    // searchdata.length == 0 ?

    //     searchlist.push(
    //         <Flex p={2} pl={4} borderBottom='1px solid' borderColor='#eae6e6' alignItems='center' justifyContent='center'>
    //             <Text mr={2} textTransform='capitalize' fontWeight='bold' fontSize="13px">No results found</Text>
    //         </Flex>
    //     )
    //     : ""



    // const handleKeyEnter = () => {
    //     search2Filter(searchdata[select]);

    // }


    const [searchValue, setsearchValue] = useState('')

    const NavSearch = (e) => {

        get(`/api/v1/search/buyer_searches/?q=Homepage_Search&sq=${e}`).then(res => {

            // setSearchdata(res.data)
            FilteringSearch(res.data, e)
            // console.log(res.data, "NavSearch");

        }).catch(err => {
            // setSearchdata([])
            // console.log(err, "NavSearch");

        })
    }

    const FilteringSearch = (filter, searchvalue) => {
        // console.log(searchvalue, "searchvaluesearchvalue");

        let url = "/filter"

        if (filter.make) {
            url = `${url}?make=${filter.make}&make_id=${filter.make_id}`
        }
        if (filter.make && filter.model) {
            url = `${url}&model=${filter.model}&model_id=${filter.model_id}`
        }
        if (filter.make && filter.model && filter.year) {
            url = `${url}&year=${filter.year}`
        }
        // if (filter.make && filter.model && filter.year && filter.version) {
        //     url = `${url}&version=${filter.version}`
        // }
        if (filter.make && filter.model && filter.year && filter.version && filter.fuel_type) {
            url = `${url}&fuel_type=${filter.fuel_type}`
        }
        if (filter.axle) {
            let seperator = filter.make || filter.model || filter.year || filter.version || filter.fuel_type ? "&" : "?"
            url = `${url}${seperator}axle=${filter.axle}`
        }
        if (filter.product_category) {
            let seperator = filter.make || filter.model || filter.year || filter.version || filter.fuel_type || filter.axle ? "&" : "?"
            url = `${url}${seperator}category=${filter.product_category}`
        }
        if (filter.brand) {
            let seperator = filter.make || filter.model || filter.year || filter.version || filter.fuel_type || filter.axle || filter.product_category ? "&" : "?"
            url = `${url}${seperator}brand=${filter.brand}`
        }
        if (filter.seller_part_no) {
            localStorage.setItem("seller_part_no", JSON.stringify(filter.seller_part_no));
        }
        if (searchvalue != "") {
            props.onClose();

            if (location.pathname.includes("filter")) {
                history.push(url);
                window.location.reload();
                // console.log(url);

            } else {
                // console.log(url);
                history.push(url);
            }
        }
    }

    return (
        <>
            <Flex flexDirection='column' position='sticky' top="20px" >
                <InputGroup className='font_Nunito ' alignItems='center'    >
                    <Input bgColor='#FFFFFF' fontSize='1rem' p={2} pl={4} pr={4} borderRadius='xl'
                        border='none' variant='unstyled'
                        placeholder='Search for Products, Brands and More...'
                        _placeholder={{ fontSize: "14px", whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis", maxwidth: '300px' }}
                        onChange={(e) => {
                            //  NavSearch(e.target.value)
                            setsearchValue(e.target.value)
                        }}
                        // onKeyPress={(e) => { e.key === 'Enter' ? handleKeyEnter() : "" }}
                        onKeyPress={(e) => { e.key === 'Enter' && e.target.value != "" ? NavSearch(searchValue) : "" }}

                    />
                    <InputRightElement
                        children={<FiSearch fontSize='1.5rem' />}
                        onClick={() => {
                            // handleKeyEnter();
                            NavSearch(searchValue)
                        }} />
                </InputGroup>

                {/* <Box pt={0} mt='1rem' bgColor='white'
                boxShadow='0 4px 8px 0 rgba(0, 0, 0, 0.2)' borderRadius={5} maxHeight='80vh' 
                 className="scrollbarhide" 
                >

                    {searchlist}

                </Box> */}
            </Flex>
        </>
    )
}