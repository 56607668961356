import { Flex, Image } from '@chakra-ui/react';

import AdBannerWeb1 from '../assets/Ads/Web/AdBannerWeb1.jpg';
import AdBannerWeb2 from '../assets/Ads/Web/AdBannerWeb2.jpg';
import AdBannerWeb3 from '../assets/Ads/Web/AdBannerWeb3.jpg';
import AdBannerWeb4 from '../assets/Ads/Web/AdBannerWeb4.jpg';

import YearEnd from '../assets/Ads/Year-End-Sale-Banner.jpg';
import NewYear from '../assets/Ads/Newyear-Sale-Banner.jpg';

export const AdsWeb = (props) => {
  const token = JSON.parse(localStorage.getItem('token') || '{}').data
    ?.credAuthResult;

  // if (token && token.new_user_offer == true) {

  const promoAddClicked = () => {
    if (token && token?.type == 'anonymous') {
      document.getElementById('openWebSignPopUp').click();
      onClose();
      setTimeout(() => {
        document.getElementById('createAccountWeb').click();
      }, 100);
    }
  };

  if (token) {
    return (
      <></>
      // <Flex style={props.adStyle}
      // // onClick={promoAddClicked}
      // >
      //     {/* {props.AD2 ?
      //         <Image loading='lazy' src={AdBannerWeb2} />
      //         : props.AD3 ?
      //             <Image loading='lazy' src={AdBannerWeb3} />
      //             : props.AD4 ?
      //                 <Image loading='lazy' src={AdBannerWeb4} />
      //                 :
      //                 <Image loading='lazy' src={AdBannerWeb1} />
      //     } */}
      //     <Image style={props.adStyleImg ?? {}} loading='lazy' src={NewYear} />
      // </Flex>
    );
  } else {
    return <></>;
  }
};
