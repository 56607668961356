import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { API_URL } from "../../../services/api";


export const MyGarage = (props) =>{
    return(  
      
      <Flex flexDirection='column' width="100%" alignItems='center'> 

        <div className="rectangle-6045-qYBBoK">
          <lottie-player
            src={API_URL + "/api/v1/json/My_Garage.json"}
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
        <div className="my-garage-will-be-rolled-out-soon-qYBBoK nunitosans-normal-shark-18px">
          My Garage will be rolled out soon
        </div>

    </Flex>
  )
}