import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  Image,
  Heading,
  Divider,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { API_URL } from '../../../services/api';
import lastbg from '../../../assets/sarath_IMG/Delete_Account_Bg.jpg';
import OtpInput from 'react-otp-input';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Timer from '../../Login/LoginSignup/timer';
import { get, post } from '../../../services/api';

import { useHistory } from 'react-router-dom';

// to change passsword
export const ToChangePassword = (props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({ mode: 'onSubmit' });
  return (
    <form>
      <ModalContent bgColor='var(--white-rock-2)'>
        <ModalCloseButton size='lg' color='var(--mountain-mist)' />
        <ModalBody alignContent='center' padding='70px 50px'>
          <Text
            textAlign='center'
            className=' pass_heading manrope-bold-shark  '
          >
            {' '}
            CHANGE YOUR PASSWORD
          </Text>
          <Box mt='40px'>
            <Text mb='3px' className='passfont nunitosans-normal-melanzane'>
              {' '}
              New Password
            </Text>
            <input
              className='inputbox_change_pass_profile'
              name='new_password'
              placeholder='Enter New Password'
              ref={register({
                required: true,
                minLength: { value: 8 },
              })}
            ></input>
            <div>
              {' '}
              <div style={{ position: 'absolute' }}>
                {errors.new_password &&
                  errors.new_password.type == 'required' && (
                    <span style={{ fontSize: '12px', color: 'red' }}>
                      {' '}
                      Enter new password
                    </span>
                  )}
              </div>
            </div>

            <div>
              {' '}
              <div style={{ position: 'absolute' }}>
                {errors.new_password &&
                  errors.new_password.type == 'minLength' && (
                    <span style={{ fontSize: '12px', color: 'red' }}>
                      {' '}
                      Minimum 8 characters needed
                    </span>
                  )}
              </div>
            </div>
          </Box>
          <Box mt='40px'>
            <Text className='passfont nunitosans-normal-melanzane' mb='3px'>
              Confirm New Password
            </Text>
            <input
              className='inputbox_change_pass_profile'
              name='confirm_password'
              onChange={() => props.seterrorMsg('')}
              placeholder='Confirm New Password'
              ref={register({
                required: true,
              })}
            ></input>
            <div>
              <div style={{ position: 'absolute' }}>
                {props.errorMsg && (
                  <span style={{ fontSize: '12px', color: 'red' }}>
                    {' '}
                    **{props.errorMsg}
                  </span>
                )}
              </div>
            </div>
          </Box>
        </ModalBody>

        <ModalFooter justifyContent='center' mb='50px'>
          <Button
            className=' confirm_btn nunitosans-normal-white'
            width='200px'
            borderRadius='5px'
            boxShadow={'0px 3px 6px #330d2f29'}
            bgColor={'var(--royal-blue)'}
            onClick={handleSubmit((d) => props.changePassword(d))}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </form>
  );
};

export const PasswordChanged = (props) => {
  return (
    //    password changed succesfully / Error Message

    <ModalContent ModalContent bgColor='var(--white-rock-2)'>
      <ModalCloseButton size='lg' color='var(--mountain-mist)' />
      <ModalBody
        display='flex'
        flexDirection='column'
        alignContent='center'
        padding='100px 40px 20px 40px'
      >
        <Flex className='password_lottie' alignSelf='center'>
          <lottie-player
            src={API_URL + '/api/v1/json/Brakeup_Page_Confirmed_Animation.json'}
            background='transparent'
            speed='1'
            autoplay
            loop
          ></lottie-player>
        </Flex>

        <Text textAlign='center' className='password_headng manrope-bold-shark'>
          {' '}
          YOUR PASSWORD CHANGED SUCCESSFULLY
        </Text>
      </ModalBody>

      <ModalFooter justifyContent='center' mb='50px'>
        <Button
          className=' confirm_btn nunitosans-normal-white'
          ref={props.cancelRef}
          width='200px'
          borderRadius='5px'
          boxShadow={'0px 3px 6px #330d2f29'}
          bgColor={'var(--royal-blue)'}
          onClick={() => {
            props.onClose();
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export const PasswordChangeError = (props) => {
  return (
    //    Error Message

    <ModalContent ModalContent bgColor='var(--white-rock-2)'>
      <ModalCloseButton size='lg' color='var(--mountain-mist)' />
      <ModalBody
        display='flex'
        flexDirection='column'
        alignContent='center'
        padding='100px 40px 20px 40px'
      >
        <Flex className='password_error_lottie' alignSelf='center'>
          <lottie-player
            src={API_URL + '/api/v1/json/Error.json'}
            background='transparent'
            speed='1'
            autoplay
            loop
          ></lottie-player>
        </Flex>

        <Text
          textAlign='center'
          className='password_headng manrope-bold-shark  '
        >
          {' '}
          ERROR MESSAGE
        </Text>
      </ModalBody>

      <ModalFooter justifyContent='center' mb='50px'>
        <Button
          className='confirm_btn nunitosans-normal-white'
          ref={props.cancelRef}
          width='200px'
          borderRadius='5px'
          boxShadow={'0px 3px 6px #330d2f29'}
          bgColor={'var(--royal-blue)'}
          onClick={() => {
            props.onClose();
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

//Are you certain about this decision to Break Up with us ?

export const Deactivate1AreYou = (props) => {
  return (
    <ModalContent bgColor='var(--white-rock-2)'>
      <ModalCloseButton size='lg' color='var(--mountain-mist)' />
      <ModalBody
        display='flex'
        flexDirection='column'
        alignContent='center'
        padding='100px 40px 20px 40px'
      >
        <Text
          textAlign='center'
          className=' deactivate_heading manrope-normal-shark'
        >
          {' '}
          Are you certain about this decision to Break Up with us ?
        </Text>

        <Flex mt='30px' alignSelf='center'>
          <Button
            className=' confirm_btn1 nunitosans-bold-shark'
            bgColor='transparent'
            border='2px solid'
            borderRadius='5px'
            boxShadow='0px 3px 6px #00000029'
            onClick={() => {
              props.setModalContent(5);
            }}
          >
            YES
          </Button>

          <Button
            className=' confirm_btn1 nunitosans-bold-white'
            ref={props.cancelRef}
            ml='30px'
            borderRadius='5px'
            boxShadow={'0px 3px 6px #330d2f29'}
            bgColor={'var(--royal-blue)'}
            onClick={() => {
              props.onClose();
            }}
          >
            NO
          </Button>
        </Flex>

        <Flex className='deactivate1_lottie' alignSelf='center'>
          <lottie-player
            src={API_URL + '/api/v1/json/Sad_kid_delete.json'}
            background='transparent'
            speed='1'
            autoplay
            loop
          ></lottie-player>
          <Divider mt='129px' zIndex='-1' position='absolute' width='125px' />
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};

//IS THIS REALLY YOU - OTP
export const Deactivate2OTP = (props) => {
  const [OTP, setOTP] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [status, setStatus] = useState('SEND_OTP');

  const getOtp = () => {
    post('/api/v1/buyer/delete_account/', {})
      .then((res) => {
        setTrigger(true);
        setStatus('RESEND_OTP');
      })
      .catch((err) => {});
  };
  const enableBtn = () => {
    setTrigger(false);
  };
  useEffect(() => {
    getOtp();
  }, []);
  const resendOtp = () => {
    if (OTP.length != 4) {
      return;
    }
    post('/api/v1/buyer/verify_delete_account/', { otp: OTP })
      .then((res) => {
        props.setModalContent(6);
      })
      .catch((err) => {});
  };
  return (
    <ModalContent bgColor='var(--white-rock-2)'>
      <ModalCloseButton size='lg' color='var(--mountain-mist)' />
      <ModalBody
        display='flex'
        flexDirection='column'
        alignContent='center'
        padding='20px 40px 30px 40px'
      >
        <Flex
          alignSelf='center'
          className='deactivate2_lottie'
          height='250px'
          width='250px'
          mb='1vw'
        >
          <lottie-player
            src={API_URL + '/api/v1/json/Sad_lady_delete.json'}
            background='transparent'
            speed='1'
            autoplay
            loop
          ></lottie-player>
        </Flex>

        <Text
          textAlign='center'
          className='deactivate_heading2 manrope-bold-shark '
        >
          {' '}
          IS THIS REALLY YOU WHO DOING THIS ?
        </Text>
        <Text
          textAlign='center'
          className='deactivate_heading2 manrope-bold-shark '
        >
          {' '}
          I CAN'T BELEIVE IT. I NEED A TESTAMENT.
        </Text>

        <Flex
          flexDirection='column'
          alignItems='center'
          alignSelf='center'
          mt='20px'
          mb='30px'
          width='100%'
        >
          {trigger == false && (
            <Box>
              <Text
                className='resend_deactivate nunitosans-normal-melanzane'
                onClick={() => getOtp()}
                style={{ cursor: 'pointer' }}
              >
                {' '}
                Resend OTP
              </Text>
            </Box>
          )}
          {status == 'RESEND_OTP' && trigger == true && (
            <Box className='resend_deactivate nunitosans-normal-melanzane'>
              Resend OTP
              <Box position='absolute' className='timer_deactivate'>
                <Timer
                  initialSeconds={60}
                  enableBtn={enableBtn}
                  trigger={trigger}
                />
              </Box>
            </Box>
          )}

          <Flex justifyContent='center' mt='20px'>
            <OtpInput
              inputStyle={{
                width: '2.5rem',
                height: '2.5rem',
                margin: '5px 10px',
                fontSize: '1.5rem',
                borderRadius: 4,
                border: '2px solid rgba(0,0,0,0.3)',
              }}
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={4}
              otpType='number'
              disabled={false}
            />
          </Flex>
          <Text className='resend_deactivate nunitosans-normal-melanzane'>
            {' '}
            Enter OTP sent to your mobile
          </Text>
        </Flex>

        <Button
          alignSelf='center'
          className='confirm_btn nunitosans-normal-white'
          ref={props.cancelRef}
          size='sm'
          width='100px'
          borderRadius='5px'
          boxShadow={'0px 3px 6px #330d2f29'}
          bgColor={'var(--orange-red)'}
          onClick={() => {
            resendOtp();
          }}
        >
          DELETE
        </Button>
      </ModalBody>
    </ModalContent>
  );
};

//final - successfully deleted

export const Deactivate3Final = (props) => {
  let history = useHistory();
  return (
    <ModalContent
      bgImage={lastbg}
      bgPosition='center'
      bgSize='cover'
      bgRepeat='no-repeat'
    >
      <ModalCloseButton size='lg' color='var(--mountain-mist)' />
      <Flex
        borderRadius='8px'
        flexDirection='column'
        alignContent='center'
        padding='20px 40px 30px 40px'
      >
        <Flex
          position='absolute'
          className='deactivate3_lottie'
          alignSelf='center'
        >
          <lottie-player
            src={API_URL + '/api/v1/json/Breakup_Love.json'}
            background='transparent'
            speed='1'
            autoplay
            loop
          ></lottie-player>
        </Flex>
        <Flex className='deactivate3_text_section' flexDirection='column'>
          <Text textAlign='center' className=' it_takes playball-normal-shark '>
            {' '}
            It takes just a few seconds to say hello.
          </Text>
          <Text
            textAlign='center'
            mt='10px'
            fontStyle='italic'
            className='we_can nunitosans-bold-shark '
          >
            We can still talk this out. Call us 90 2580 2580{' '}
          </Text>

          <Flex
            flexDirection='column'
            alignItems='center'
            alignSelf='center'
            mt='30px'
            width='100%'
          >
            <Text
              textAlign='center'
              className=' your_accnt nunitosans-normal-shark '
            >
              {' '}
              Your account has been successfully deleted hope to see you back
              soon.
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <ModalFooter justifyContent='center' mb='50px'>
        <Button
          alignSelf='center'
          className='confirm_btn nunitosans-normal-white'
          ref={props.cancelRef}
          bgColor='transparent'
          border='2px solid'
          borderRadius='5px'
          boxShadow='0px 3px 6px #00000029'
          color='var(--royal-blue)'
          onClick={() => {
            props.onClose();
            localStorage.removeItem('token');
            history.push('/');
            window.location.reload();
          }}
        >
          Sign Up
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
