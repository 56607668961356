import { Dialog, Transition } from '@headlessui/react';
import { Image } from '@chakra-ui/react';
import { Fragment, useRef } from 'react';

import dCross from '../../assets/sarath_IMG/x.svg';

export default function Removemodal(props) {
  const { isOpen, setIsOpen, message, remove, saveForLater, type } = props;

  function closeModal() {
    setIsOpen(false);
  }

  const completeButtonRef = useRef(null);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          onClose={closeModal}
          initialFocus={completeButtonRef}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md overflow-hidden rounded-2xl bg-white p-2 text-center align-middle shadow-xl transition-all'>
                  <div className='flex justify-end'>
                    <button onClick={closeModal} autoFocus={false}>
                      <Image width={25} height={25} src={dCross} alt='' />
                    </button>
                  </div>

                  <div>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-medium leading-6 text-gray-900'
                    >
                      {message}
                    </Dialog.Title>

                    <div className='my-8 flex items-center justify-center gap-2.5'>
                      <button
                        type='button'
                        className='ModalyellowBtnBorder px-7 py-2'
                        onClick={remove}
                      >
                        REMOVE
                      </button>

                      {type == 'CART' ? (
                        <button
                          type='button'
                          className='ModalyellowBtn px-4 py-2'
                          id='save_for_later'
                          style={{ cursor: 'pointer' }}
                          onClick={saveForLater}
                        >
                          SAVE FOR LATER
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='ModalyellowBtn px-4 py-2'
                          onClick={closeModal}
                        >
                          CANCEL
                        </button>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
