import React, { useState, useEffect } from 'react';
import { Box, Divider, Flex, Grid, GridItem, Image, Spacer, Text } from '@chakra-ui/react';
import './css/ItemDisplay.css';
import { FaStar } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';

import REAR from '../../assets/sarath_IMG/REAR_ICON.png';
import FRONT from '../../assets/sarath_IMG/FRONT_ICON.png';
// import { AdsMob } from '../../ZAds/AdsMob';

import { get, post } from '../../services/api';
import { setupdateCart } from '../../stateManagement/user/cartCountSlice';
import { useSelector, useDispatch } from 'react-redux';

import NewYear2 from '../../assets/Ads/Newyear Sale Mobile Banner 2.jpg';
import { Meta, UUID } from '../../services/Meta-Pixel';
import { Add2CartGtag, Save4LaterGtag } from '../../services/G-Tag';

function ItemDisplayMob3(props) {
  const [hide, sethide] = useState(true);
  const [pincode, setpincode] = useState('');

  const dispatch = useDispatch();
  const updateCart = useSelector((state) => state.count.updateCart);

  useEffect(() => {
    const tokenpin = JSON.parse(localStorage.getItem('pincode'));
    if (tokenpin != null) {
      setpincode(tokenpin.toString());
    } else {
      get('api/v1/products/pincode/address/')
        .then((res) => {
          if (res.address_pincode.pincode != []) {
            setpincode(res.address_pincode.pincode.toString());
          } else if (res.order_pincode.pincode != []) {
            setpincode(res.order_pincode.pincode.toString());
          }
        })
        .catch((err) => {
          setpincode('');
        });
    }
  }, []);

  //Toast

  const Swal = require('sweetalert2');

  const Toast2 = (title, theme, color, iconColor) => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      autofocus: false,
      toast: true,
      animation: true,
      timer: 5000,
      // timerProgressBar: true,
      iconColor: iconColor,
      icon: theme,
      title: title,
      color: color,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  };

  const Add2Cart = (element) => {
    // console.log(element, props.filter);

    let productdata = {
      product: [
        {
          vend_id: element?.vendor_products?.vend_id,
          prod_id: element?._id['$oid'],
          make_id: props.filter?.make_id ? props.filter?.make_id[0] : '',
          model_id: props.filter?.model_id ? props.filter?.model_id[0] : '',
          year: props.filter?.year ? props.filter?.year[0] : '',
          fueltype: props.filter?.fuel ? props.filter?.fuel[0] : '',
          version: props.filter?.version ? props.filter?.version[0] : '',
          delivery_date: '',
          quantity: 1,
        },
      ],
      pincode: pincode ? pincode : '110001',
    };

    const eventID = UUID();

    try {
      post('/api/v1/products/addtocart/', productdata)
        .then((res) => {
          Toast2('Item added to Cart !', 'success', '#089520');
          dispatch(setupdateCart(!updateCart));

          window.fbq(
            'track',
            'AddToCart',
            {
              value: element?.vendor_products?.selling_price,
              currency: 'INR',
              contents: [
                {
                  id: element?.brakeup_part_no,
                  quantity: 1,
                },
              ],
            },
            { eventID: eventID }
          );
          let data = {
            currency: 'INR',
            value: element?.vendor_products?.selling_price,
            contents: [
              {
                id: element?.brakeup_part_no,
                quantity: 1,
                delivery_category: 'home_delivery',
              },
            ],
          };
          Meta('AddToCart', data, eventID);
          Add2CartGtag(element);
        })
        .catch((err) => {
          Toast2(err?.response?.data.message, 'error', '#f22020');
        });
    } catch (error) {}
  };

  const Save4Later = (element) => {
    let productdata = {
      product: [
        {
          vend_id: element?.vendor_products?.vend_id,
          prod_id: element?.prod_id,
          make_id: element?.vehicle_details?.make_id ?? '',
          model_id: element?.vehicle_details?.model_id ?? '',
          year: element?.vehicle_details?.year ?? '',
          fueltype: element?.vehicle_details?.fueltype ?? '',
          version: element?.vehicle_details?.version ?? '',
          quantity: 1,
        },
      ],
    };
    const eventID = UUID();

    try {
      post('/api/v1/products/savelater/productdetail/', productdata)
        .then((res) => {
          Toast2('Item added to Save for later!', 'success', '#089520');

          let data = {
            currency: 'INR',
            value: element?.vendor_products?.selling_price,
            contents: [
              {
                id: element?.brakeup_part_no,
                quantity: 1,
                delivery_category: 'home_delivery',
              },
            ],
          };

          let make1 = element?.vehicle_details?.make ? element?.vehicle_details?.make : '';

          let model1 = element?.vehicle_details?.model ? element?.vehicle_details?.model : '';

          let year1 = element?.vehicle_details?.year ? element?.vehicle_details?.year : '';

          let vehicle = make1 + ' ' + model1 + ' ' + year1;

          Meta('AddToWishlist', data, eventID);
          Save4LaterGtag(element, vehicle);
          window.fbq(
            'track',
            'AddToWishlist',
            {
              value: element?.mrp,
              currency: 'INR',
              contents: [
                {
                  id: element?.brakeup_part_no,
                  quantity: 1,
                },
              ],
            },
            { eventID: eventID }
          );
        })
        .catch((err) => {
          Toast2(err?.response?.data.message, 'error', '#f22020');
        });
    } catch (error) {}
  };

  let items = [];

  props.products &&
    props.products.forEach((element, index) => {
      // console.log(index, "element.index")
      if (index == 3 && props.products.length >= 5) {
        items
          .push
          // <AdsMob AD2={true} adStyle={{ padding: "1rem 0" }} adStyleImg={{ borderRadius: '16px', boxShadow: "0px 1px 1px #00000026" }} />

          // <Flex style={{ padding: "1rem 0" }} >
          //     <Image style={{ borderRadius: '5px', boxShadow: "0px 1px 1px #00000026" }} loading='lazy' src={NewYear2} />
          // </Flex>
          ();
      }

      // if (index == 9 && props.products.length >= 11) {
      //     items.push(
      //         <AdsMob adStyle={{ padding: "1rem 0" }} adStyleImg={{ borderRadius: '16px', boxShadow: "0px 1px 1px #00000026" }} />
      //     )
      // }

      items.push(
        <div className='relative'>
          {/* <GridItem d='flex' className=''
                    bg='#FFFFFF'
                    height='max-content'
                    position='relative'
                    w={props.width ? props.width : "auto"}

                    p='1rem 0'
                    onClick={() => props.onBoxClick(element)}>
                    <Box className=''
                        minWidth='130px'
                        maxWidth='200px'
                        width='40vw'
                        p={1}
                        justifyContent='center'
                        position='relative'
                        boxShadow='0px 0px 2px rgb(39 41 46 / 20%);'
                        borderRadius='lg' >
                        <Image src={element.image[0]}
                            justifyContent='center' width='40vw'
                        />

                        {element?.axle &&
                            <Flex justify='flex-end' position='absolute' width='100%' right='0px' top='5px' zIndex={1}
                            >
                                {element?.axle == "Front" ?
                                    <Image minWidth='40px' width='4vw' maxWidth='70px' src={FRONT} />
                                    : element?.axle == "Rear" ?
                                        <Image minWidth='40px' width='4vw' maxWidth='70px' src={REAR} />
                                        : ""}
                            </Flex>
                        }
                    </Box>

                    <Flex pl={4} height="auto" flexDirection='column' justifyContent='space-evenly'>
                        <Grid visibility={'hidden'} className='rating_box font_Nunito' justifyItems='flex-end' >
                            <Flex justifyContent='end' alignItems='center' className='ratingbox' bgColor={element.rating > 3.0 ? "#279043" : "#905827"}>
                                <Box >{element.rating ? element?.rating.toFixed(1) : ""}</Box>
                                <Box pl='2px' pr='2px' > <FaStar /></Box>
                            </Flex>
                            <Box className='reviewCount font_Nunito'> {element.reviewCount} Ratings
                            </Box>
                        </Grid>
                        <Text alignItems='end' className='FPcompanyname font_NunitoUp'>
                            {element.brand}
                        </Text>

                        <Text className='FPProductname font_Nunito' maxWidth={'46vw'} textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden'>
                            {element.prod_name}
                        </Text>
                        {element?.vendor_products?.discount ? element?.vendor_products?.discount.toFixed(0) == 0 ? "" :
                            <Flex justifyContent='flex-start' flexWrap='nowrap' alignItems='center' >
                                <Text textOverflow='ellipsis' whiteSpace='nowrap' className='FPcostPrice font_Nunito'> ₹{element.mrp}
                                </Text>
                                <Text textOverflow='ellipsis' whiteSpace='nowrap' pl="8px" className='FPdiscountPercentage font_Nunito' >({element?.vendor_products?.discount ? element?.vendor_products?.discount.toFixed(0) : ""}% OFF)
                                </Text>
                            </Flex>
                            : ""}

                        <Flex className='FPsellingPrice font_Nunito'>
                            <Text >Rs. {element?.vendor_products?.selling_price} </Text>
                        </Flex>
                    </Flex>
                </GridItem> */}

          {/* <Divider color='#eaeaea' border='0.6px solid' /> */}

          <div
            key={index}
            className={`relative mt-4 sm:mt-6 md:mt-8 flex font-manrope rounded-brBs p-3 shadow-bsCard2 first-of-type:mt-0 last-of-type:mb-4 
                    ${
                      !props.isSelected3 && element.category != 'Brake Fluid'
                        ? 'opacity-20 pointer-events-none '
                        : ' '
                    }`}
            onClick={() => props.onBoxClick(element)}>
            <div className='w-3/5'>
              <Image
                src={element.image[0]}
                alt=''
              />
            </div>
            <div className='flex flex-col w-full h-auto ml-2 sm:ml-5 md:ml-10'>
              <div className='flex flex-col h-full gap-2 text-sm sm:text-base md:text-lg'>
                <p className='font-bold uppercase sm:mt-2 md:mt-4'>{element.brand}</p>
                <p>{element.prod_name}</p>
                {element?.vendor_products?.discount ? (
                  <div className='flex items-center text-sm font-normal sm:text-base md:text-lg'>
                    <p className='mr-2 line-through text-redB'>Rs. {element.mrp}</p>
                    {element?.vendor_products?.discount.toFixed(0) != 0 && (
                      <p className='text-xs sm:text-sm md:text-base text-greenB'>
                        {element?.vendor_products?.discount
                          ? element?.vendor_products?.discount.toFixed(0)
                          : ''}
                        % OFF
                      </p>
                    )}
                  </div>
                ) : (
                  ''
                )}
                <p className='text-base font-bold sm:text-lg md:text-xl'>
                  Rs. {element?.vendor_products?.selling_price}
                </p>
                {element?.axle == 'Front' ? (
                  <Image
                    className='absolute right-0 top-4'
                    minWidth='40px'
                    width='10vw'
                    maxWidth='80px'
                    src={FRONT}
                    alt=''
                  />
                ) : element?.axle == 'Rear' ? (
                  <Image
                    className='absolute right-0 top-4'
                    minWidth='40px'
                    width='10vw'
                    maxWidth='80px'
                    src={REAR}
                    alt=''
                  />
                ) : (
                  ''
                )}
              </div>

              {element?.total_stock >= 1 ? (
                <button
                  className='flex items-center self-end rounded-brBs border border-yellowB p-3 py-0.5 text-xs sm:text-base md:text-lg  font-bold uppercase'
                  onClick={(e) => {
                    e.stopPropagation(), Add2Cart(element);
                  }}
                  id='add_to_cart'>
                  <FiPlus className='text-sm font-bold sm:text-base md:text-lg' />
                  <p className='ml-1'>cart</p>
                </button>
              ) : (
                <button
                  className='flex items-center self-end rounded-brBs border border-yellowB p-3 py-0.5 text-xs sm:text-base md:text-lg  font-bold uppercase'
                  onClick={(e) => {
                    e.stopPropagation(), Save4Later(element);
                  }}
                  id='save_for_later'>
                  <FiPlus className='text-sm font-bold sm:text-base md:text-lg' />
                  <p className='ml-1'>save</p>
                </button>
              )}
            </div>
          </div>
          {!props.isSelected3 && element.category != 'Brake Fluid' && (
            <div className='absolute top-[calc(50%-16px)] left-[calc(50%-70px)]'>
              <button
                className='fpyellowBtnBorder bg-white whitespace-nowrap my-1 mx-6 px-3 py-1.5 sm:text-base md:text-lg'
                onClick={() => props.OnDrawerOpen('selectcar')}>
                {props.isSelected2
                  ? 'SELECT YEAR'
                  : props.isSelected1
                  ? 'SELECT MODEL'
                  : 'SELECT VEHICLE'}
              </button>
            </div>
          )}
        </div>
      );
    });

  return <Grid>{items}</Grid>;
}

export default ItemDisplayMob3;
