import React, { useEffect, useState, useRef } from "react";

import { Box, Button, Flex, Select } from "@chakra-ui/react";

import "./css/MyProfileLayout.css";
import "./css//myprofilestyleguide.css";

import { VscAccount } from "react-icons/vsc";
import { IoBookmarkOutline } from "react-icons/io5";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { FiGift } from "react-icons/fi";
import { VscHistory } from "react-icons/vsc";
import { GiHomeGarage } from "react-icons/gi";
import { FiLogOut } from "react-icons/fi";

import { CgClose } from "react-icons/cg";

import BestFitForItems from '../../BestFitForItems/BestFitForItems';
import RecentView from "../../FilterPage/RecentView";



export const MyProfileLayout = (props) => {


    var active_child = props.children[props.activeSection]

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    useEffect(() => {
        let new_label = props.activeSection
        window.history.pushState(null, '', `/myprofile/${new_label}`);
    }, [props.activeSection]);


    return (
        <Box width={"100%"} height={"max-content"} bgColor="#F5F5F5">

            <Box marginTop="10%" marginLeft="5vw" className="my_profile">MY PROFILE</ Box>

            <Flex marginTop="1%" marginLeft="5vw" marginRight="5vw" >

                <Flex className="my_profile_section">


                    <Button className="saved-list-6mmUWi" width="100%" height={"3vw"} borderRadius={"5px"} p={0}
                        alignItems="center" justifyContent='flex-start'
                        bgColor={props.activeSection == 0 ? "var(--white-rock-2)" : "var(--white)"}
                        _hover={{ bgColor: "var(--white-rock-2)" }}
                        onClick={() => props.setactiveSection(0)} >
                        <IoBookmarkOutline style={{ fontSize: '1.6vw', marginLeft: "2vw" }} />
                        <div className="saved-list-uFa4FX">Saved List</div>

                    </Button>

                    <Button className="saved-list-6mmUWi" width="100%" height={"3vw"} borderRadius={"5px"} p={0}
                        alignItems="center" justifyContent='flex-start'
                        bgColor={props.activeSection == 1 ? "var(--white-rock-2)" : "var(--white)"}
                        _hover={{ bgColor: "var(--white-rock-2)" }}
                        onClick={() => props.setactiveSection(1)} >
                        <IoLocationOutline style={{ fontSize: '1.6vw', marginLeft: "2vw" }} />
                        <div className="saved-list-uFa4FX">Address Book</div>

                    </Button>
                    <Button className="saved-list-6mmUWi" width="100%" height={"3vw"} borderRadius={"5px"} p={0}
                        alignItems="center" justifyContent='flex-start'
                        bgColor={props.activeSection == 2 ? "var(--white-rock-2)" : "var(--white)"}
                        _hover={{ bgColor: "var(--white-rock-2)" }}
                        onClick={(e) => (props.setactiveSection(2))}>
                        <MdOutlineNotificationsActive style={{ fontSize: '1.6vw', marginLeft: "2vw" }} />
                        <div className="saved-list-uFa4FX"> Notifications</div>

                    </Button>

                    <Button className="saved-list-6mmUWi" width="100%" height={"3vw"} borderRadius={"5px"} p={0}
                        alignItems="center" justifyContent='flex-start'
                        bgColor={props.activeSection == 3 ? "var(--white-rock-2)" : "var(--white)"}
                        _hover={{ bgColor: "var(--white-rock-2)" }}
                        onClick={() => props.setactiveSection(3)} >
                        <FiGift style={{ fontSize: '1.6vw', marginLeft: "2vw" }} />
                        <div className="saved-list-uFa4FX">Gift &amp; Coupons </div>

                    </Button>

                    <Button className="saved-list-6mmUWi" width="100%" height={"3vw"} borderRadius={"5px"} p={0}
                        alignItems="center" justifyContent='flex-start'
                        bgColor={props.activeSection == 4 ? "var(--white-rock-2)" : "var(--white)"}
                        _hover={{ bgColor: "var(--white-rock-2)" }}
                        onClick={() => props.setactiveSection(4)} >
                        <VscHistory style={{ fontSize: '1.6vw', marginLeft: "2vw" }} />
                        <div className="saved-list-uFa4FX">Order History</div>

                    </Button>

                    <Button className="saved-list-6mmUWi" width="100%" height={"3vw"} borderRadius={"5px"} p={0}
                        alignItems="center" justifyContent='flex-start'
                        bgColor={props.activeSection == 5 ? "var(--white-rock-2)" : "var(--white)"}
                        _hover={{ bgColor: "var(--white-rock-2)" }}
                        onClick={() => props.setactiveSection(5)} >
                        <GiHomeGarage style={{ fontSize: '1.6vw', marginLeft: "2vw" }} />
                        <div className="saved-list-uFa4FX"> My Garage</div>

                    </Button>

                    <Button className="saved-list-6mmUWi" width="100%" height={"3vw"} borderRadius={"5px"} p={0}
                        alignItems="center" justifyContent='flex-start'
                        bgColor={props.activeSection == 6 ? "var(--white-rock-2)" : "var(--white)"}
                        _hover={{ bgColor: "var(--white-rock-2)" }}
                        onClick={() => props.setactiveSection(6)} >
                        <VscAccount style={{ fontSize: '1.6vw', marginLeft: "2vw" }} />
                        <div className="saved-list-uFa4FX">Profile</div>

                    </Button>


                </Flex>

                <Flex marginLeft={"4vw"} marginTop="15px" width='75%' bgColor='white' minHeight='30%' height='max-content' borderRadius='10px' >


                    {active_child}


                </Flex>
            </Flex>

            <Flex mb='4vh' mt='20px' className='desktop_only' gridArea='recent_items' justifyContent='center'>

                <BestFitForItems />

            </Flex >


            <Flex mb='6vh' className='recent' alignSelf='center' gridArea='recent_items' justifyContent='center' >

                <RecentView width="80%" reViewed={props.reViewed} onRectClick={props.onRectClick} />

            </Flex >



        </Box>







    );
}