import React, { useEffect, useState, useRef } from 'react';
import './css/HomePage.css';

import { get } from '../../services/api';
import { useHistory } from 'react-router-dom';
import { SignIn } from '../Login/LoginSignup';

import { HomePageWeb } from './HomePageWeb';
import { HomePageMobile } from './HomePageMobile';
import { useMediaQuery } from '@chakra-ui/react';
import loadFacebookPixels from '../../services/loadFacebookPixel';

export const HomePage = () => {
  let history = useHistory();
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [version, setVersion] = useState([]);
  const [year, setYear] = useState([]);
  const [filter, setFilter] = useState({});
  const [error, seterror] = useState('');
  const [modelID, setmodelID] = useState('');
  const [fuel, setFuel] = useState([]);
  const [mobileScreen, setmobileScreen] = useState(false);
  const [isSelected3, setisSelected3] = useState(false);
  const [isSelected2, setisSelected2] = useState(false);
  const [isSelected1, setisSelected1] = useState(false);
  const [SearchButtonLOader, setSearchButtonLOader] = useState(false);

  const [topselldata, settopselldata] = useState([]);

  loadFacebookPixels();

  const onSearch = (value) => {
    // console.log(filter, Object.keys(filter).length, "onsearchhhhh");

    function isEmptyObject(obj) {
      for (var name in obj) {
        return false;
      }
      return true;
    }
    if (isEmptyObject(filter)) {
      // seterror(true);
      document.getElementById('CustomSelectMake').click();
    } else if (!isSelected3) {
      if (isSelected2) {
        document.getElementById('CustomSelectYear').click();
      } else {
        document.getElementById('CustomSelectModel').click();
      }
      // seterror(true)
    } else {
      let paramurl = '/filter';

      if (filter.make) {
        paramurl = `${paramurl}?make=${filter.make.label}&make_id=${filter.make.value}`;
      }
      if (filter.model) {
        paramurl = `${paramurl}&model=${filter.model.label}&model_id=${
          filter.model.value ? filter.model.value : filter.model.id
        }`;
      }
      if (filter.year) {
        paramurl = `${paramurl}&year=${filter.year.label}`;
      }
      if (filter.version) {
        paramurl = `${paramurl}&version=${
          filter.version.id ? filter.version.id : filter.version.value
        }`;
      }
      if (filter.fuel) {
        paramurl = `${paramurl}&fuel_type=${
          filter.fuel.id ? filter.fuel.id : filter.fuel.value
        }`;
      }
      if (filter.type) {
        paramurl = `${paramurl}&axle=${filter.type.value}`;
      }

      // console.log(paramurl, "filterfilterfilter");

      // localStorage.setItem("filter", JSON.stringify(filter));
      history.push(paramurl);
    }
  };

  useEffect(() => {
    get('/api/v1/vehiclemake/')
      .then((res) => {
        let make = [];
        res &&
          res.data &&
          res.data.map((items) => {
            make.push({ label: items.make, id: items._id['$oid'] });
          });
        setMake(make);
      })
      .catch((err) => {
        setMake([]);
      });

    getTopSellingProducts();
  }, []);

  const getMake = (value) => {
    let model = [];
    get(`/api/v1/vehiclemodel/?id=${value}`)
      .then((res) => {
        res &&
          res.data &&
          res.data.map((items) => {
            model.push({ label: items.model, id: items._id['$oid'] });
          });
        setModel(model);
        if (model && model.length == 1) {
          // let newFilter = { ...filter };
          // newFilter.model = model[0];
          // setFilter(newFilter);
          setFilter((filter) => ({ ...filter, model: model[0] }));
          getModel(model[0].id);
        }
      })
      .catch((err) => {
        setModel([]);
        setVersion([]);
        setYear([]);
      });
  };

  const getModel = (value) => {
    // console.log("modelvalue", value);
    get(`/api/v1/make_details/?id=${value}`)
      .then((res) => {
        let year = [];
        res &&
          res.year &&
          res.year.map((items) => {
            year.push({ label: items, id: items });
          });
        setYear(year);
        setmodelID(value);
        if (year && year.length == 1) {
          // let newFilter = { ...filter };
          // newFilter.year = year[0];
          // setFilter(newFilter);
          setFilter((filter) => ({ ...filter, year: year[0] }));
          getYear(year[0].id);
        }
      })
      .catch((err) => {
        setYear([]);
        setVersion([]);
      });
  };

  const getYear = (value) => {
    get(`/api/v1/veh_version/?model_id=${modelID}&year=${value}`)
      .then((res) => {
        let version = [];
        let fuel = [];
        // console.log(res, "getyearrr");

        res &&
          res.version.map((items) => {
            if (!items.includes('Version')) {
              version.push({ label: items, id: items });
            }
          });
        setVersion(version);
        res &&
          res.fuel_type &&
          res.fuel_type.map((items) => {
            fuel.push({ label: items, id: items });
            // console.log(items.fuel_type,"getyearrr");
          });
        setFuel(fuel);

        if ((version && version.length == 1) || (fuel && fuel.length == 1)) {
          // let newFilter = { ...filter };
          // if (version.length == 1) {
          //     newFilter.version = version[0];
          // }
          // if (fuel.length == 1) {
          //     newFilter.fuel = fuel[0];
          // }
          // setFilter(newFilter);

          if (version.length == 1) {
            setFilter((filter) => ({ ...filter, version: version[0] }));
          }
          if (fuel.length == 1) {
            setFilter((filter) => ({ ...filter, fuel: fuel[0] }));
          }
        }
      })
      .catch((err) => {
        setVersion([]);
      });
  };

  const onChange = (value, name, label) => {
    let newFilter = { ...filter };

    newFilter = { ...filter };
    if (name == 'make') {
      getMake(value);
      delete newFilter['model'];
      delete newFilter['year'];
      delete newFilter['version'];
      setisSelected1(true);
      setisSelected2(false);
      setisSelected3(false);
    } else if (name == 'model') {
      getModel(value);
      delete newFilter['year'];
      delete newFilter['version'];
      setisSelected2(true);
      setisSelected3(false);
    } else if (name == 'year') {
      getYear(value);
      delete newFilter['version'];
      delete newFilter['fuel'];
      delete newFilter['type'];
      setisSelected3(true);
      // seterror(false)
    } else if (name == 'version') {
      delete newFilter['fuel'];
      delete newFilter['type'];
    } else if (name == 'fuel') {
      delete newFilter['type'];
    }

    newFilter[name] = { value, name, label };
    setFilter(newFilter);
  };
  useEffect(() => {
    if (isSelected3) {
      setSearchButtonLOader(true);
      setTimeout(() => {
        setSearchButtonLOader(false);
      }, 1100);
    }
  }, [isSelected3]);

  const onClickCategory = (value, label, name) => {
    let data = {};
    if (value.name == 'category') {
      // data = {
      //     "category": value, label, name
      // }
      history.push(`/filter?category=${value.value}`);
      // localStorage.setItem("filter", JSON.stringify(data));
    } else {
      // data = {
      //     "brand": value, label, name
      // }
      // localStorage.setItem("filter", JSON.stringify(data));
      history.push(`/filter?brand=${value.value}`);
    }
  };

  let data = [];
  const onClickAds = (datas) => {
    datas.map((element) => {
      if (element.name == 'make') {
        data.make = element;
      }
      if (element.name == 'model') {
        data.model = element;
      }
      if (element.name == 'year') {
        data.year = element;
      }
      if (element.name == 'category') {
        data.category = element;
      }
      if (element.name == 'brand') {
        data.brand = element;
      }
      // history.push(`/filter?category=${value.value}`);
      // localStorage.setItem("filter", JSON.stringify(data));
      // history.push(`/filter`);
    });
    createURL(data);
  };

  const createURL = (data) => {
    // console.log(data , data.length, "onClickAdsonClickAds");
    let url = '/filter';

    if (data.make) {
      url = `${url}?make=${data.make.label}&make_id=${data.make.value}`;
    }
    if (data.model) {
      url = `${url}&model=${data.model.label}&model_id=${data.model.value}`;
    }
    if (data.year) {
      url = `${url}&year=${data.year.value}`;
    }
    if (data.category) {
      let seperator = data.make ? '&' : '?';
      url = `${url}${seperator}category=${data.category.value}`;
    }
    if (data.brand) {
      let seperator = !data.make && !data.category ? '?' : '&';
      url = `${url}${seperator}brand=${data.brand.value}`;
    }

    // console.log(url,"onClickAdsonClickAds");

    history.push(url);
  };

  ///islogged in

  let isloggedin = false;
  const token = JSON.parse(localStorage.getItem('token') || '{}').data
    ?.credAuthResult;

  try {
    if (token.type == 'Access') {
      isloggedin = true;
    }
  } catch (error) {
    isloggedin = false;
  }

  let inputStyle = {};
  // let inputStyleMobileS = { color: "#000", fontSize: "1rem", borderRadius: '5px', padding: '15px', width: "100%", }

  let inputStyleMobileS = { color: '#000', borderRadius: '3px', width: '100%' };

  useEffect(() => {
    ReportWindowSize();
  }, []);
  function ReportWindowSize() {
    // console.log(window.innerWidth,);

    switch (true) {
      case window.innerWidth <= 799:
        setmobileScreen(true);
        break;

      default:
        setmobileScreen(false);
        break;
    }
  }
  window.onresize = ReportWindowSize;

  const getTopSellingProducts = () => {
    get('/api/v1/product/best_selling/')
      .then((res) => {
        // console.log(res.data, "getTopSellingProductsgetTopSellingProducts");
        settopselldata(res.data);
      })
      .catch((err) => {
        settopselldata([]);
      });
  };

  const onClickTopSell = (element) => {
    history.push(
      `/product/${element.prod_details?.prod_id}${
        element.vehicle_details?.make_id
          ? '/' +
            element.vehicle_details?.make_id +
            '/' +
            element.vehicle_details?.model_id +
            '/' +
            element.vehicle_details?.year +
            (element.vehicle_details?.version
              ? '/' + element.vehicle_details?.version
              : '') +
            (element.vehicle_details?.fueltype
              ? '/' + element.vehicle_details?.fueltype
              : '')
          : ''
      }${
        element.vehicle_details?.type ? '/' + element.vehicle_details?.type : ''
      }`
    );
  };

  return (
    <>
      {mobileScreen ? (
        <HomePageMobile
          inputStyle={inputStyleMobileS}
          onSearch={onSearch}
          make={make}
          onChange={onChange}
          model={model}
          size={'md'}
          filter={filter}
          year={year}
          version={version}
          fuel={fuel}
          onClickCategory={onClickCategory}
          isloggedin={isloggedin}
          onClickAds={onClickAds}
          isSelected1={isSelected1}
          isSelected2={isSelected2}
          isSelected3={isSelected3}
          SearchButtonLOader={SearchButtonLOader}
          topselldata={topselldata}
          onClickTopSell={onClickTopSell}
        />
      ) : (
        <HomePageWeb
          inputStyle={inputStyle}
          onSearch={onSearch}
          make={make}
          onChange={onChange}
          filter={filter}
          model={model}
          size={'md'}
          year={year}
          version={version}
          fuel={fuel}
          onClickCategory={onClickCategory}
          onClickAds={onClickAds}
          isSelected1={isSelected1}
          isSelected2={isSelected2}
          isSelected3={isSelected3}
          SearchButtonLOader={SearchButtonLOader}
          topselldata={topselldata}
          onClickTopSell={onClickTopSell}
        />
      )}
    </>
  );
};
