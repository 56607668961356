import { Box, Divider, Flex, Text, Grid, GridItem, Image, Spacer } from '@chakra-ui/react';
import React from 'react';

import { FaStar } from "react-icons/fa";


export const TopSellingProducts = (props) => {

    let items = []
    props.topselldata && props.topselldata.map((item) => {

        items.push(

            <GridItem className='hpTSGridItem' padding='1vw'
                boxShadow='0px 0px 2px rgb(39 41 46 / 20%);'
                borderRadius='lg' onClick={() => props.onClickTopSell(item)} >
                <Flex
                    flexDir='column'
                    alignItems='center'
                    bg='#FFFFFF'
                    height='max-content'
                    width='100%'
                    p='0.5rem'
                    borderRadius='lg'>

                    <Image src={item?.prod_details?.prod_image && item?.prod_details?.prod_image[0]}
                        justifyContent='center'
                        className='hpTopSellingImage'
                    />

                    <Spacer />
                    <Grid className='rating_box' justifyContent='flex-end' >
                        <Flex width='auto' justifyContent='end' alignItems='center' className='ratingbox' bgColor={3.0 ? "#279043" : "#905827"}>
                            <Text >3.4</Text>
                            <Text pl='2px' pr='2px'> <FaStar /></Text>
                        </Flex>
                        <Text className='reviewCount'> 300 Ratings
                        </Text>
                    </Grid>

                    <Flex className='font_Manrope hpTSdatabox' flexDir='column' alignItems='center' justifyContent='space-between'>

                        <Text className='hpTSBrandName' textTransform={"uppercase"}>{item?.prod_details?.brand}</Text>
                        <Text className='hpTSProductName'>{item?.prod_details?.prod_name}</Text>
                        {item?.vendor_details?.discount ? item?.vendor_details?.discount.toFixed(0) == 0 ? "" :
                            <Flex alignItems='center'>
                                <Text className='hpTScostPrice'>₹ {item?.prod_details?.mrp}</Text>
                                <Text pl={2} className='hpTSdiscountPercentage'>{item?.vendor_details?.discount ? item?.vendor_details?.discount.toFixed(0) : ""}% OFF</Text>
                            </Flex>
                            : ""}
                        <Text className='hpTSsellingPrice'>Rs. {item?.vendor_details?.selling_price} </Text>

                        <Flex alignItems='center' h="30px" overflow='hidden'>
                            <Image className='recentlogo' src={item?.vehicle_details?.make_image[0]} />
                            <Text ml={2} className="hpTSProductTags" > {item?.vehicle_details?.model}  {item?.vehicle_details?.year} </Text>
                        </Flex>

                    </Flex>
                </Flex>
            </GridItem>
        )
    })

    return (

        <Box ml='4vw' mt="3vw" alignSelf={'flex-start'} width='100%'>
            <Text marginLeft='15px' textAlign='left' className="hpsubheads" mb="1.5vw"> TOP SELLING</Text>
            <Divider color="#FBDE00" border='2px solid' mb="3vw" width='91%' />

            <Grid gridAutoFlow='column' padding='5px' overflowX={"scroll"} overflowY='none'>

                {items}

            </Grid>
        </Box>
    )
}