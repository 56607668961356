import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Container, Flex, Image, Spacer, Text, Grid, Radio, RadioGroup, useDisclosure } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import topAd from '../../assets/sarath_IMG/Mobile Advertisment 3.png'
import bottomAd from '../../assets/sarath_IMG/Mobile Advertisment 1.png'
import { BsArrowDownUp } from "react-icons/bs";
import { FaSort, FaArrowUp, FaFilter } from "react-icons/fa";
import { HiChevronDoubleUp } from "react-icons/hi";



import ItemDisplay from './ItemDisplay';
import RecentView from './RecentView';
import SortFilter from './SortFilter';
import { get } from "../../services/api";
import { useHistory, useParams } from "react-router-dom";
import { API_URL } from "../../services/api";

import BFY from '../../assets/sarath_IMG/Brake Fluid Yellow.png'
import BF from '../../assets/sarath_IMG/Brake fluid.png'
import BRY from '../../assets/sarath_IMG/Brake Rotors Yellow.png'
import BR from '../../assets/sarath_IMG/Brake Rotors.png'
import BPY from '../../assets/sarath_IMG/Brakepads yellow.png'
import BP from '../../assets/sarath_IMG/Brakepads.png'
import OPY from '../../assets/sarath_IMG/Other Products Yellow.png'
import OP from '../../assets/sarath_IMG/Other Products.png'

import { FilterCheckbox } from './FilterCheckbox';
import { useQuery } from '../../utilities';


export const FilterPageLayoutMobile = () => {

    let history = useHistory();
    const [products, setProducts] = useState([]);
    const [filter, setFilter] = useState({});
    const [mobfilter, setMobFilter] = useState({});

    const [sortTitle, setsortTitle] = useState('New Arrivals');
    const [sortedOption, setsortedOption] = useState();
    const [make, setMake] = useState([]);
    const [model, setModel] = useState([]);
    const [version, setVersion] = useState([]);
    const [year, setYear] = useState([]);
    const [apiURL, setApiURL] = useState('');
    const [drawerType, setdrawerType] = useState('filter');
    const [fuel, setFuel] = useState([]);
    const [type, setType] = useState([]);
    const [reViewed, setReViewed] = useState([]);
    const [brand, setBrandDts] = useState([]);
    const [showShowMore, setShowShowMore] = useState(false);
    const [showBacktoTop, setshowBacktoTop] = useState(false);
    const [spinner, setspinner] = useState(false);
    const [hideVersion, sethideVersion] = useState(true);





    const token = JSON.parse(localStorage.getItem("token")).data.credAuthResult

    let query = useQuery();

    const getQueries = () => {
        const categoryQUERY = query.getAll("category")
        const brandQUERY = query.getAll("brand")
        const makeQUERY = query.get("make")
        const makeidQUERY = query.get("make_id")
        const modelQUERY = query.get("model")
        const modelidQUERY = query.get("model_id")
        const yearQUERY = query.get("year")
        const versionQUERY = query.get("version")
        const fuelQUERY = query.get("fuel_type")
        const typeQUERY = query.get("axle")

        let newFilter = { ...filter }
        let categoryarray = newFilter.category || []
        let brandarray = newFilter.brand || []



        if (makeQUERY) {
            newFilter.make = [makeQUERY]
        }
        if (makeidQUERY) {
            newFilter.make_id = [makeidQUERY]
            getModel(makeidQUERY)
        }
        if (modelQUERY) {
            newFilter.model = [modelQUERY]
        }
        if (modelidQUERY) {
            newFilter.model_id = [modelidQUERY]
            getYear(modelidQUERY)
        }
        if (yearQUERY) {
            newFilter.year = [yearQUERY]
            getVersionFuel(yearQUERY, modelidQUERY)
        }
        if (versionQUERY) {
            newFilter.version = [versionQUERY]
        }
        if (fuelQUERY) {
            newFilter.fuel = [fuelQUERY]
        }
        if (typeQUERY) {
            newFilter.type = [typeQUERY]
        }
        if (categoryQUERY.length != 0) {
            newFilter.category = categoryQUERY
        }
        if (brandQUERY.length != 0) {
            newFilter.brand = brandQUERY
        }

        // setFilter(newFilter);
        getFilter(newFilter);
    }



    useEffect(() => {
        getQueries()

        const filter = JSON.parse(localStorage.getItem("mobfilter") || "{}");
        // const url = getFilter(filter);
        // getProducts(url, true);
        // getFilter(filter)
        getMake()
        getBrands()
        let recentUrl = `/api/v1/product/recently_viewed/?id=${token.id}`;
        recentlyViewed(recentUrl);
    }, [])



    const recentlyViewed = (url) => {
        get(url).then(res => {
            let data = [...res.data, ...reViewed];
            setReViewed(data);

        }).catch(err => {
            setReViewed([]);

        })
    }

    const getBrands = () => {
        get('/api/v1/products/add_brand/').then(res => {
            let brand = [];
            res && res.data && res.data.map(items => {
                brand.push({ value: items.brand_name });
            })
            setBrandDts(brand);
        }).catch(err => {
            setBrandDts([]);
        })

    }

    const getMake = () => {

        get('/api/v1/vehiclemake/').then(res => {
            let make = [];
            res && res.data && res.data.map(items => {
                make.push({ value: items.make, id: items._id["$oid"] });
            })
            setMake(make);

        }).catch(err => {
            setMake([]);
        })
    }

    const getModel = (value, make, name) => {
        setspinner(true)
        // console.log(value, name, "getModelgetModel");

        // let newFilter = { ...filter };
        // newFilter.make = [name];
        // newFilter.make_id = [value];
        // getFilter(newFilter);

        let model = [];
        get(`/api/v1/vehiclemodel/?id=${value}`).then(res => {
            res && res.data && res.data.map(items => {
                model.push({ value: items.model, id: items._id["$oid"] });
            })
            setModel(model);
            setspinner(false)


        }).catch(err => {
            setModel([]);
            setVersion([]);
            setYear([]);
        })
    }
    const getYear = (value, model, name) => {
        setspinner(true)

        // let newFilter = { ...filter };
        // newFilter.model = [name];
        // newFilter.model_id = [value];
        // getFilter(newFilter);
        get(`/api/v1/make_details/?id=${value}`).then(res => {

            let year = [];
            res && res.year && res.year.map(items => {
                year.push({ value: items });
            })
            setYear(year);
            let checkIfVersion = []

            res && res.version.map(items => {
                if (!items.includes("Version")) {
                    checkIfVersion.push({ value: items });
                    if (checkIfVersion.length != 0) {
                        sethideVersion(false)
                    }
                } else {
                    sethideVersion(true)
                }
            })



            setspinner(false)
        }).catch(err => {
            setYear([]);
        })
    }


    const getVersionFuel = (value, model_id) => {

        setspinner(true)


        // let newFilter = { ...filter };
        // newFilter.year = [value];
        // getFilter(newFilter);

        get(`/api/v1/veh_version/?model_id=${model_id}&year=${value}`).then(res => {
            let version = [];
            let fuel = [];

            res && res.version.map(items => {
                if (!items.includes("Version")) {
                    version.push({ value: items });
                }
            })
            setVersion(version);
            res && res.fuel_type && res.fuel_type.map(items => {
                fuel.push({ value: items });
            })
            setFuel(fuel);
            setType([{ value: "Front" }, { value: "Rear" }])
            setspinner(true)
        }).catch(err => {
            setVersion([]);
            setFuel([])
        })
    }


    const onChange = (id, value, name) => {

        // console.log(id, value, name, "onChangevsluesfilert");

        let newFilter = { ...filter }
        if (value == "make") {

            newFilter.make = [name];
            newFilter.make_id = [id];

            delete newFilter["model"];
            delete newFilter["year"];
            delete newFilter["version"];
            delete newFilter["fuel"];
            delete newFilter["type"];

            getModel(id, value, name)
            setModel([]);
            setYear([]);
            setVersion([]);
            setFuel([]);
            setType([]);


        } else if (value == "model") {

            newFilter.model = [name];
            newFilter.model_id = [id];

            delete newFilter["year"];
            delete newFilter["version"];
            delete newFilter["fuel"];
            delete newFilter["type"];

            getYear(id, value, name);

            setYear([]);
            setVersion([]);
            setFuel([])
            setType([]);


        }
        else if (value == "year") {

            newFilter.year = [id];

            delete newFilter["version"];
            delete newFilter["fuel"];
            delete newFilter["type"];
            seterrorFlexboxstyle(false)

            getVersionFuel(id, newFilter.model_id);


            setVersion([]);
            setFuel([])
            setType([]);



        }
        else if (value == "version") {

            newFilter.version = [id];
            delete newFilter["fuel"];
            delete newFilter["type"];
            // getFilter(newFilter);

            // setFuel([])

        }
        else if (value == "fuel") {
            newFilter.fuel = [id];
            delete newFilter["type"];
            // getFilter(newFilter);

        }
        else if (value == "type") {
            newFilter.type = [id];
            // getFilter(newFilter);
        }

        getFilter(newFilter);

    }

    let paramurl = '/filter'

    const getFilter = (filter) => {
        setFilter(filter);
        //     console.trace("getFiltergetFilter")
        let url = '/api/v1/search/buyer_search/?q=Homepage_filter';
        if (filter.make) {
            // todo add seperator
            url = `${url}&make_id=${filter.make_id}`
            // getModel(filter.make_id, "make", filter.make);
            paramurl = `${paramurl}?make=${filter.make}&make_id=${filter.make_id}`
        }
        if (filter.model) {
            url = `${url}&model_id=${filter.model_id}`
            // getYear(filter.model_id, "model". filter.model);
            paramurl = `${paramurl}&model=${filter.model}&model_id=${filter.model_id}`
        }
        if (filter.year) {
            url = `${url}&year=${filter.year}`
            // getVersionFuel(filter.year);
            paramurl = `${paramurl}&year=${filter.year}`
        }
        if (filter.version) {
            url = `${url}&version=${filter.version}`
            // getVersionFuel(filter.year);
            paramurl = `${paramurl}&version=${filter.version}`
        }
        if (filter.fuel) {
            url = `${url}&fuel_type=${filter.fuel}`
            // getVersionFuel(filter.year, filter);
            paramurl = `${paramurl}&fuel_type=${filter.fuel}`
        }
        if (filter.type) {
            url = `${url}&axle=${filter.type}`
            paramurl = `${paramurl}&axle=${filter.type}`
        }
        if (filter.category) {
            // console.log(Object.keys(filter), "filter.category");
            let seperator = '&'
            if (Object.keys(filter).length == 1) {
                seperator = "?"
            } else if (Object.keys(filter).length == 2 && Object.keys(filter).includes('brand')) {
                seperator = "?"
            }
            let categoryurl = `category=`
            filter.category.forEach((elem, index) => {
                categoryurl += elem
                if (index != filter.category.length - 1) {
                    categoryurl += "&category="
                }
            })
            if (filter?.category?.length == 0) {
                categoryurl = " "
            }
            url = `${url}&${categoryurl}`
            paramurl = `${paramurl}${seperator}${categoryurl}`
        }
        if (filter.brand) {
            // console.log(Object.keys(filter).length, "filter.lengthfilter.length");
            let seperator = Object.keys(filter).length == 1 ? "?" : "&"
            let brandurl = `brand=`
            filter.brand.forEach((elem, index) => {
                brandurl += elem
                if (index != filter.brand.length - 1) {
                    brandurl += "&brand="
                }
            })
            if (filter?.brand?.length == 0) {
                brandurl = " "
            }
            url = `${url}&${brandurl}`
            paramurl = `${paramurl}${seperator}${brandurl}`

        }
        window.history.pushState(null, '', `${paramurl}`);


        // console.log(url, "filterfilterfilter");
        setApiURL(url)

        getProducts(url, true)
    }

    const getProducts = (url, load) => {
        // console.log(url, filter, "filter mobile");
        let sorturl = `${url}&&sort_by=${sortedOption ? sortedOption.sort_by : "mrp"}&order=${sortedOption ? sortedOption.order : "-1"}`
        get(sorturl).then(res => {
            let data = [];
            if (load) {
                data = [...res.data];
            } else {
                data = [...products, ...res.data];
            }
            setProducts(data);

            if (data?.length >= 24 && res?.data?.length != 0) {
                // console.log(data.length, res.data.length, "items.length");
                setShowShowMore(true)
            } else {
                setShowShowMore(false)
            }
        }).catch(err => {
            setProducts([]);
        })
    }

    //scroll to top & Back to Top

    const ScrolltoTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setshowBacktoTop(false)
    }


    const [scrolling, setscrolling] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);


    const handleScroll = () => {
        setscrolling(true);
        // console.log('window.scrollY22', window.scrollY);
        if (window.scrollY >= 2000) {
            // console.log('window.scrollY', window.scrollY);
            setshowBacktoTop(true)
        } else {
            setshowBacktoTop(false)
        }
        // clearTimeout()

        // setTimeout(() => {
        //     setscrolling(false);
        // }, 3000);

        const timer = setTimeout(() =>  setscrolling(false), 3000);
        return () => clearTimeout(timer);
    }



    const onNext = () => {
        let url = apiURL
        if (products) {
            const lastItem = products[products.length - 1];
            url = `${url}&previousId=${lastItem["_id"]["$oid"]}`;
            url = `${url}&limit=12`;
            url = `${url}&order=1`;
        }
        getProducts(url, false);
    }

    const onRectClick = () => {
        let url = `/api/v1/product/recently_viewed/?id=${token.id}`;
        if (reViewed) {
            const lastItem = reViewed[reViewed.length - 1];
            url = `${url}&previousId=${lastItem["_id"]["$oid"]}`;
            url = `${url}&limit=10`;
            url = `${url}&order=1`;
        }
        recentlyViewed(url);
    }

    // S O R T

    const Sort = (element) => {
        setsortedOption(element)
        getProducts(apiURL, true);
    }

    let sortdata = [{
        title: "New Arrivals",
        value: "1",
        // sort_by: "created_at",
        sort_by: "mrp",
        order: "-1"
    },
    {
        title: "Price Low to High",
        value: "2",
        sort_by: "vendor_products.selling_price",
        order: "1"
    },
    {
        title: "Price High to Low",
        value: "3",
        sort_by: "vendor_products.selling_price",
        order: "-1"
    },
    {
        title: "Recommended",
        value: "4",
        sort_by: "",
        order: "-1"
    },
    {
        title: "Discounts",
        value: "5",
        sort_by: "vendor_products.discount",
        order: "-1"
    }]


    let sortoptions = []
    sortdata.forEach((element) => {
        sortoptions.push(
            <MenuItem onClick={() => { setsortTitle(element.title), Sort(element) }}
                bgColor={element.title == sortTitle ? '#ECE9D2' : "none"}
                _last={{ borderBottomRadius: 'md' }} _first={{ borderTopRadius: 'md' }}
                _hover={{ bgColor: '#ECE9D2' }} _focus={{ bgColor: 'none', boxShadow: 'none' }}>
                <Radio border='2px' borderColor='black' width='100%'
                    colorScheme='white' _before={{ color: "black" }}
                    value={element.value}>
                    {element.title}
                </Radio>
            </MenuItem>
        )
    })

    const onCloseFilter = (id) => {
        let newFilter = { ...filter };
        delete newFilter[id];
        // console.log("onclose", id);

        if (id == "make") {
            delete newFilter["make_id"];
            delete newFilter["model"];
            delete newFilter["model_id"];
            delete newFilter["year"];
            delete newFilter["version"];
            delete newFilter["fuel"];
            delete newFilter["type"];
            setModel([]);
            setYear([]);
            setVersion([]);
            setFuel([]);
            setType([])



        } else if (id == "model") {
            delete newFilter["model_id"];
            delete newFilter["year"];
            delete newFilter["version"];
            delete newFilter["fuel"];
            delete newFilter["type"];

            setYear([]);
            setVersion([]);
            setFuel([]);
            setType([])


        }
        else if (id == "year") {
            delete newFilter["version"];
            delete newFilter["fuel"];
            delete newFilter["type"];
            setVersion([]);
            setFuel([])
            setType([]);

        }
        else if (id == "version") {
            delete newFilter["fuel"];
            delete newFilter["type"];
            setFuel([])
            setType([]);


        }
        else if (id == "fuel") {
            delete newFilter["type"];
        }
        getFilter(newFilter)

    }

    const onClear = (type) => {
        let newFilter = { ...filter };
        if (type == 'all') {
            delete newFilter['make'];
            delete newFilter['make_id'];
            delete newFilter['model'];
            delete newFilter['model_id'];
            delete newFilter['version'];
            delete newFilter['year'];
            delete newFilter['type'];
            delete newFilter['fuel'];
            setModel([]);
            setYear([]);
            setVersion([]);
            setFuel([])
            setType([])

        }
        getFilter(newFilter)

    }


    const [errorFlexboxstyle, seterrorFlexboxstyle] = useState(false);


    const onBoxClick = (element) => {

        if (filter.hasOwnProperty("make") == false ||
            filter.hasOwnProperty("model") == false ||
            filter.hasOwnProperty("year") == false) {
            seterrorFlexboxstyle(true);
            OnDrawerOpen("select_car")
        } else {

            history.push(`/product/${element["_id"]["$oid"]}/${filter.make_id[0]}/${filter.model_id[0]}/${filter.year[0]}/${filter.version ? filter.version[0] : "null"}/${filter.fuel ? filter.fuel[0] : "null"}/${filter.type ? filter.type[0] : "null"}`);

        }
    }

    //got mobile drawer open

    const { isOpen, onOpen, onClose } = useDisclosure()

    const OnDrawerOpen = (type) => {
        setdrawerType(type)
        onOpen()
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const onChangeCategory = (event) => {
        let newFilter = { ...filter }

        // console.log(newFilter, "filteronchangecat");
        let paramurl = ''
        let categoryarray = filter.category || []
        if (event.target.checked) {
            console.log(event.target.value, event.target.name, "eventsssss");
            if (event.target.name == "category") {
                newFilter.category = [...categoryarray, event.target.value]
            }

        } else {
            if (event.target.name == "category") {
                console.log('⛔️ Checkbox is NOT checked');
                console.log(event.target.value, event.target.name, "eventsssss");
                console.log(location.href);

                paramurl = "?category="
                newFilter.category = categoryarray.filter(item => item !== event.target.value);
                newFilter.category.forEach((elem, index) => {
                    paramurl += elem
                    if (index != newFilter.category.length - 1) {
                        paramurl += "&category="
                    }
                })
                if (newFilter.category.length == 0) {
                    delete newFilter["category"]
                }
            }
        }
        getFilter(newFilter)
    };


    const [brandArray, setbrandArray] = useState([]);

    const onChangeBrand = (event) => {
        setbrandArray(filter.brand || [])
        if (event.target.checked) {
            if (event.target.name == "brand") {
                setbrandArray([...brandArray, event.target.value]);
            }
        }
        else {
            if (event.target.name == "brand") {
                let brandarrayy = brandArray.filter(item => item !== event.target.value)
                setbrandArray(brandarrayy)
            }
        }
    };

    const onSubmitBrand = (type) => {

        let newFilter = { ...filter }
        newFilter.brand = brandArray

        if (type == "clear") {
            setbrandArray([])
            newFilter.brand = []
            delete newFilter["brand"]
        }
        if (newFilter?.brand?.length == 0) {
            delete newFilter["brand"]
        }
        getFilter(newFilter)

    }

    return (


        <Container className='layout_bg' maxW='100%' m={0} p={0} backgroundColor='#F5F5F5' overflowX='hidden' >
            <Box mt='2rem' >
                <Flex justifyContent='space-evenly'>
                    <form style={{ display: "flex", justifyContent: 'space-evenly', width: "100%" }}
                    >
                        <FilterCheckbox checked={filter.category?.includes("Brake Pad")} selectedImage={BPY} image={BP} label={"PADS"} value={"Brake Pad"} name={"category"} onChange={onChangeCategory} />
                        <FilterCheckbox checked={filter.category?.includes("Brake Rotor")} selectedImage={BRY} image={BR} label={"ROTORS"} value={"Brake Rotor"} name={"category"} onChange={onChangeCategory} />
                        <FilterCheckbox checked={filter.category?.includes("Brake Fluid")} selectedImage={BFY} image={BF} label={"FLUIDS"} value={"Brake Fluid"} name={"category"} onChange={onChangeCategory} />
                        <FilterCheckbox checked={filter.category?.includes("Other")} selectedImage={OPY} image={OP} label={"OTHERS"} value={"Other"} name={"category"} onChange={onChangeCategory} />

                    </form>
                </Flex>

                <Flex m='2rem 10vw 0' p={1} alignItems='center' justifyContent='space-between'>
                    <Text className='font_Manrope' mb={0} fontWeight='500' fontSize='18px'>{sortTitle}</Text>

                    <Flex>
                        <Box>
                            <Menu bg='#0000001A' >
                                <MenuButton

                                    textAlign='left'
                                    className='font_NunitoA'
                                    bg="#FFFFFF"
                                    w='max-content'
                                    _active={{ border: '1px', borderColor: 'gray.100' }}
                                    border='1px' borderColor='gray.300'
                                    borderRadius='6px'
                                    as={Button}
                                >
                                    <Flex alignItems='center'>
                                        <FaSort fontSize='1.1rem' />
                                        <Text fontSize='1.1rem' fontWeight='600' >SORT </Text>
                                    </Flex>

                                </MenuButton>
                                <MenuList transitionDelay='.3s' pt={0} pb={0} width="90vw" boxShadow='base' zIndex={3}>
                                    <RadioGroup defaultValue='1' >
                                        {sortoptions}
                                    </RadioGroup>

                                </MenuList>
                            </Menu>
                        </Box>
                    </Flex>
                </Flex>

            </Box>

            <Grid className='layout_grid' justifyItems='center' >

                <Box className='filter_itemDisplay' width='100%' >

                    {!products.length ?
                        <Flex className='item_grid' gridArea='item_grid' p='2rem 10vw 4rem' bg='' alignItems='center' flexDirection='column' justifyItems='center' >

                            <Box width='30vw' height='500px' className='emptylottiebox' maxW='500px'>
                                <lottie-player
                                    src={API_URL + "/api/v1/json/Filter_no_item.json"}
                                    background="transparent"
                                    speed="1"
                                    loop
                                    autoplay
                                ></lottie-player>
                            </Box>
                            <Box pb='40px' className='font_Manrope' fontWeight='600' marginTop="-5vw">
                                <Text className="sorry" fontSize='30px' textAlign='center'>
                                    Sorry, no result found!
                                </Text>
                                <Text className="sorry2" fontSize='16px' textAlign='center'>
                                    Please try with a different filter
                                </Text>
                            </Box>

                        </Flex>
                        :
                        <Box className='item_grid' gridArea='item_grid' p='2rem' pt='2rem' borderRadius='lg'>

                            <Grid className='items_grid' gap={3} pt='1.6rem' justifyItems='center'>
                                <ItemDisplay justifyContent='center' products={products} onBoxClick={onBoxClick} />
                            </Grid>

                            {showShowMore &&
                                <Flex justifyContent='center' textAlign='center'>
                                    <Box className='font_Nunito'
                                        fontWeight='600'
                                        // w='8rem' p={3}
                                        p={'0.25rem 0.5rem'}
                                        fontSize='1rem'
                                        // borderRadius='.1rem'
                                        bgColor='white'
                                        mt='6vh' mb='10vh'
                                        borderRadius='4px'
                                        textAlign='center'
                                        boxShadow='0px 3px 10px #00000040'
                                        _active={{ bgColor: '#FFFFFF', boxShadow: '0px 3px 10px #00000040' }}
                                        onClick={() => onNext()}>
                                        <Text> Show More </Text>
                                    </Box>
                                </Flex>}

                        </Box>
                    }


                </Box>

                <Flex mb='6vh' className='recent' gridArea='recent_items' justifyContent='center'>

                    <RecentView reViewed={reViewed} onRectClick={onRectClick} />

                </Flex >

                <Box
                    mt='2vh' >
                    <Image width='100%' shadow='2xl' objectFit='contain' src={topAd} />
                </Box>

                <Box alignSelf='center' mt='5vh' mb='7vh'>
                    <Image src={bottomAd} shadow='2xl' width="100%" objectFit='contain' />
                </Box>

            </Grid>

            {showBacktoTop &&
                <Box className='font_NunitoA'
                    fontWeight='600'
                    p={3}
                    style={scrolling ? { opacity: "0.1" } : {}}
                    _disabled={scrolling ? true : false}
                    // borderRadius='.1rem'
                    bgColor='white'
                    position='fixed'
                    bottom='10%'
                    right='4'
                    borderRadius='full'
                    boxShadow='0px 3px 10px #00000040'
                    zIndex={10}
                    // _active={{ bgColor: '#FFFFFF', boxShadow: '0px 3px 10px #00000040' }}
                    onClick={ScrolltoTop}>
                    <Flex flexDir='column' fontSize='1rem' width='2.3rem' alignItems='center' textAlign='center'
                    >
                        <HiChevronDoubleUp fontSize='2rem' />
                        <Text overflowWrap='break-word' whiteSpace='pre-wrap' lineHeight='2rem'>
                            Top
                            {/* Back to top  */}
                            {/* <span> Back </span>
                            <span> to </span>
                            <span> top </span> */}
                        </Text>
                    </Flex>

                </Box>
            }
            <Box >

                <SortFilter
                    filter={filter} onCloseFilter={onCloseFilter}
                    onClear={onClear} onChange={onChange}
                    filters={{ make, model, year, version, fuel, type }}
                    make={make} model={model}
                    year={year} version={version}
                    fuel={fuel} type={type}
                    brandDts={brand}
                    drawerType={drawerType}
                    OnDrawerOpen={OnDrawerOpen}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                    onChangeBrand={onChangeBrand}
                    onSubmitBrand={onSubmitBrand}
                    spinner={spinner}
                    setspinner={setspinner}
                    hideVersion={hideVersion}
                />
            </Box>

        </Container>

    );
}
