import React from "react";
import { Box, Flex,Text } from "@chakra-ui/react";
import { API_URL } from "../../../services/api";


export const GiftCouponsMob = (props) =>{
    return(  
      
      <Flex flexDirection='column' width="100%" alignItems='center'> 
              <Text className="mob_address_head_font" mt="30px">Gifts & Coupons</Text>

        <div className="mob_rectangle-json">
          <lottie-player
            src={API_URL + "/api/v1/json/Gift_Coupons.json"}
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
        <Box marginTop='50px' marginBottom={'100px'} className="mob_we-do-not-have-gifting-options-right-now-gX6Hov nunitosans-normal-shark">
          We do not have gifting options right now!
        </Box>
        <Box marginTop='100px' marginBottom={'200px'}  height={"15px"} width="100%">
        <div className="mob_if-you-have-a-promoc-YLfhehs">If you have a promocode apply in the cart to the offer</div>

        </Box>

    </Flex>
  )
}