import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  Image,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import BestFitForItemDisplay from "./BestFitForItemDisplay";

import NoBestfitAdWeb from "../../assets/sarath_IMG/Bestfit items Adweb.jpg";
import NoBestfitAdMob from "../../assets/sarath_IMG/Bestfit items Admob.jpg";
import { useHistory } from "react-router-dom";
import { get, post } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { setupdateCart } from "../../stateManagement/user/cartCountSlice";

//toast

import {
  FaInfoCircle,
  FaCheckCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Meta, UUID } from "../../services/Meta-Pixel";
import { Add2CartGtag } from "../../services/G-Tag";

function BestFitForItems(props) {
  const [bestItems, setBestItems] = useState([]);
  const [ShowMoreYellowIcon, setShowMoreYellowIcon] = useState(false);
  const [bestfitCount, setbestfitCount] = useState(false);

  let history = useHistory();
  const dispatch = useDispatch();
  const updateCart = useSelector((state) => state.count.updateCart);

  const [pincode, setpincode] = useState("");

  useEffect(() => {
    let url = "/api/v1/products/cart/bestfit/";
    getBestItems(url);
  }, [props.bestItemsKey]);

  const getBestItems = (url) => {
    // console.log('bestfit')

    get(url)
      .then((res) => {
        if (bestItems.length != res.total_count) {
          // let data = [...bestItems, ...res.data];
          setBestItems(res.data);
          // console.log(data, 'setBestItems')
        }
      })
      .catch((err) => {
        setBestItems([]);
      });
  };

  const ShowMoreIcon = () => {
    if (window.innerWidth >= 1920) {
      if (bestItems.length >= 7) {
        setShowMoreYellowIcon(true);
      }
    }
    if (window.innerWidth >= 1366 && window.innerWidth <= 1919) {
      if (bestItems.length >= 4) {
        setShowMoreYellowIcon(true);
      }
    }
    if (window.innerWidth >= 1280 && window.innerWidth <= 1365) {
      if (bestItems.length >= 4) {
        setShowMoreYellowIcon(true);
      }
    }
    if (window.innerWidth >= 1024 && window.innerWidth <= 1279) {
      if (bestItems.length >= 3) {
        setShowMoreYellowIcon(true);
      }
    }
    if (window.innerWidth >= 800 && window.innerWidth <= 1023) {
      if (bestItems.length >= 3) {
        setShowMoreYellowIcon(true);
      }
    }
  };

  let data = [];
  const onClickAds = (datas) => {
    datas.map((element) => {
      if (element.name == "make") {
        data.make = element;
      }
      if (element.name == "model") {
        data.model = element;
      }
      if (element.name == "category") {
        data.category = element;
      }
      if (element.name == "brand") {
        data.brand = element;
      }
      // history.push(`/filter?category=${value.value}`);
      // localStorage.setItem("filter", JSON.stringify(data));
      // history.push(`/filter`);
    });
    createURL(data);
  };

  const createURL = (data) => {
    // console.log(data , data.length, "onClickAdsonClickAds");
    let url = "/filter";

    if (data.make) {
      url = `${url}?make=${data.make.label}&make_id=${data.make.value}`;
    }
    if (data.model) {
      url = `${url}&model=${data.model.label}&model_id=${data.model.value}`;
    }
    if (data.category) {
      let seperator = data.make ? "&" : "?";
      url = `${url}${seperator}category=${data.category.value}`;
    }
    if (data.brand) {
      let seperator = !data.make && !data.category ? "?" : "&";
      url = `${url}${seperator}brand=${data.brand.value}`;
    }

    // console.log(url,"onClickAdsonClickAds");

    history.push(url);
  };

  const BestfitItemsCount = () => {
    if (bestItems.length == 0) {
      setbestfitCount(true);
    } else {
      setbestfitCount(false);
    }
  };

  useEffect(() => {
    ShowMoreIcon();
    BestfitItemsCount();
  }, [bestItems]);

  const ref = useRef("");
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const onBoxClick = (element) => {
    history.push(
      `/product/${element["_id"]["$oid"]}${
        element.vehicle_details?.make_id
          ? "/" +
            element.vehicle_details?.make_id +
            "/" +
            element.vehicle_details?.model_id +
            "/" +
            element.vehicle_details?.year +
            (element.vehicle_details?.version
              ? "/" + element.vehicle_details?.version
              : "") +
            (element.vehicle_details?.fueltype
              ? "/" + element.vehicle_details?.fueltype
              : "")
          : ""
      }${
        element.vehicle_details?.type ? "/" + element.vehicle_details?.type : ""
      }`
    );
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const tokenpin = JSON.parse(localStorage.getItem("pincode"));
    if (tokenpin != null) {
      setpincode(tokenpin.toString());
    } else {
      get("api/v1/products/pincode/address/")
        .then((res) => {
          if (res.address_pincode.pincode != []) {
            setpincode(res.address_pincode.pincode.toString());
          } else if (res.order_pincode.pincode != []) {
            setpincode(res.order_pincode.pincode.toString());
          }
        })
        .catch((err) => {
          setpincode("");
        });
    }
  }, []);

  //Toast

  const Swal = require("sweetalert2");

  const Toast2 = (title, theme, color, iconColor) => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      autofocus: false,
      toast: true,
      animation: true,
      timer: 5000,
      icon: theme,
      title: title,
      color: color,
      iconColor: iconColor,

      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  };

  const Add2Cart = (element) => {
    let productdata = {
      product: [
        {
          vend_id: element?.vendor_products?.vend_id,
          prod_id: element?._id["$oid"],
          make_id: element?.vehicle_details?.make_id ?? "",
          model_id: element?.vehicle_details?.model_id ?? "",
          year: element?.vehicle_details?.year ?? "",
          fueltype: element?.vehicle_details?.fueltype ?? "",
          version: element?.vehicle_details?.version ?? "",
          delivery_date: "",
          quantity: 1,
        },
      ],
      pincode: pincode ? pincode : "110001",
    };
    const eventID = UUID();

    try {
      post("/api/v1/products/addtocart/", productdata)
        .then((res) => {
          let url = "/api/v1/products/cart/bestfit/";
          Toast2("Item added to Cart !", "success", "#089520");
          dispatch(setupdateCart(!updateCart));
          // setTimeout(() => {
          getBestItems(url);
          // }, 1000);
          window.fbq(
            "track",
            "AddToCart",
            {
              value: element?.vendor_products?.selling_price,
              currency: "INR",
              contents: [
                {
                  id: element?.brakeup_part_no,
                  quantity: 1,
                },
              ],
            },
            { eventID: eventID }
          );

          let data = {
            currency: "INR",
            value: element?.vendor_products?.selling_price,
            contents: [
              {
                id: element?.brakeup_part_no,
                quantity: 1,
                delivery_category: "home_delivery",
              },
            ],
          };
          Meta("AddToCart", data, eventID);
          Add2CartGtag(element);
        })
        .catch((err) => {
          Toast2(err?.response?.data.message, "error", "#f22020");
        });
    } catch (error) {}
  };

  const onRectClick = () => {
    let url = "/api/v1/products/cart/bestfit/";
    if (bestItems) {
      const lastItem = bestItems[bestItems.length - 1];
      url = `${url}?previousId=${lastItem["_id"]["$oid"]}`;
      url = `${url}&limit=10`;
      url = `${url}&order=1`;
    }
    getBestItems(url);
  };

  return (
    <Box className='recent_section_box' pt={10} width='95vw'>
      {bestfitCount ? (
        <>
          <Box
            className='desktop_only'
            mt='5vw'
            mb='5vw'
            width='95%'
            boxShadow='0px 0px 99px #00000026'
            position={"relative"}
          >
            <Image src={NoBestfitAdWeb} loading='lazy' />
            <Button
              className='hpbtnAdDark'
              _hover={{
                bgColor: "transparent",
                color: "#000000",
                border: "2px",
                borderColor: "#000000",
              }}
              position='absolute'
              bottom='8%'
              right='38%'
              bgColor='#283890'
              color='#fff'
              borderRadius='3xl'
              onClick={() =>
                onClickAds([{ value: "Hi-Q", name: "brand", label: "Hi-Q" }])
              }
            >
              Shop Now
            </Button>
          </Box>
          <Box
            className='mobile_only'
            mt='5vw'
            mb='5vw'
            boxShadow='0px 0px 99px #00000026'
          >
            <Image src={NoBestfitAdMob} loading='lazy' />
          </Box>
        </>
      ) : (
        <div>
          <Text
            className='font_Manrope recent_font'
            textTransform='capitalize'
            fontWeight='bold'
          >
            Best fit for Items in Cart & Save for Later
            {/* <span className='desktop_only'>items</span> */}
          </Text>

          <Divider
            className='recent_divider desktop_only'
            mt='3vh'
            borderColor='#c5c1c1'
          />
          <Flex
            className='recent_scrollbox'
            justifyContent='space-between'
            alignItems='center'
          >
            <Flex
              flexFlow='row'
              width='100%'
              height='max-content'
              flexWrap='nowrap'
              overflowX='scroll'
              className='bestfitscroll'
            >
              <BestFitForItemDisplay
                bestItems={bestItems}
                onBoxClick={onBoxClick}
                Add2Cart={Add2Cart}
              />
            </Flex>

            {ShowMoreYellowIcon ? (
              <Box
                className='desktop_only'
                p={3}
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                <FaChevronRight
                  fontSize='50px'
                  color='#F9E107'
                  onClick={() => onRectClick()}
                />
              </Box>
            ) : (
              ""
            )}
          </Flex>
        </div>
      )}
    </Box>
  );
}

export default BestFitForItems;
