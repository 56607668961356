import { Dialog, Transition } from '@headlessui/react';
import { Image } from '@chakra-ui/react';
import { Fragment, useRef } from 'react';

export default function Notifymodal({
  isOpen,
  setIsOpen,
  message,
}) {
  function closeModal() {
    setIsOpen(false);
  }

  const completeButtonRef = useRef(null);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeModal}
          initialFocus={completeButtonRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md overflow-hidden rounded-2xl bg-white p-2 text-center align-middle shadow-xl transition-all">
                  <div className="flex justify-end">
                    <button onClick={closeModal} autoFocus={false}>
                      <Image
                        width={25}
                        height={25}
                        src="/assets/Common/x.svg"
                        alt=""
                      />
                    </button>
                  </div>

                  <div className="my-8 flex flex-col items-center gap-3 px-3">
                    <div>
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-bold leading-6 text-gray-900"
                      >
                        {message}
                      </Dialog.Title>
                    </div>
                    <div className=" flex w-full flex-row justify-end">
                      <input
                        className="ccr_inputbox mx-6 p-3 font-normal drop-shadow-dsInput"
                        name="name"
                        type="text"
                        placeholder="Enter Email"
                      />

                      <div className="absolute bottom-28 mr-6 flex items-center ">
                        <img src="/assets/Common/tickmark.svg" alt="" />
                        <img
                          src="/assets/Common/crossmark.svg"
                          alt=""
                          className="mr-4"
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      className="ModalyellowBtn px-4 py-2"
                      onClick={closeModal}
                    >
                      CONFIRM
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
