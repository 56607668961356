import { Box, Button, Flex, Spacer, Text, Image } from '@chakra-ui/react';
import React, { useState, } from 'react';
import CloseIcon from '../../assets/sarath_IMG/Group 31317.svg'
import CustomSelect from './CustomSelect';
import './css/filterbox.css'



const Filterbox = (props) => {
    // console.log(props.filter, "filterprops");
    let filteredoptions = []
    props.filter &&
        Object.keys(props.filter).map((element) => {
            if (element && props.filter[element]) {

                filteredoptions.push(
                    <Flex m={1} p={1} borderRadius='4px' fontWeight='semibold' className='font_Manrope customfilterfont' bgColor='#ECE9D2'>
                        <Text textTransform={props.filter?.fuel ? "capitalize" : "none"}  >{props.filter[element].label}</Text>
                        <Image pl={2} src={CloseIcon} onClick={() => props.onclose(element)} />
                    </Flex >
                )
            }
        })

    let inputStyle = { height: '1.9rem', textColor: '#27292E', borderRadius: '5px', padding: '10px', width: "100%" }
    let flexboxStyle = { height: "2.2rem", marginTop: "1rem", }
    let inputStylecaps = { textTransform: "capitalize", height: '1.9rem', textColor: '#27292E', borderRadius: '5px', padding: '10px', width: "100%" }


    return (

        <Box p='0.5rem 1rem 1rem' className='filter_box desktop_only'
            // gridArea='filter_box'
            w='20vw' mt='1rem'
            minW='280px' maxW="350px"
            h='max-content'
            borderRadius='lg' bg='white'
            position="sticky" top="3%"  >
            <Text className='font_Manrope' fontWeight='bold' fontSize='21px' >FILTERS</Text>

            <Flex
                // pt={1}
                flexFlow='wrap'>

                {filteredoptions}

            </Flex>

            <Box mt='10px' p='1rem' className='vehicleList font_Nunito' textTransform='capitalize'>
                <Flex>
                    <Text className='font_NunitoUp' fontSize='18px' >Vehicle</Text>
                    <Spacer />
                    {filteredoptions.length != 0 ?
                        <Button className='font_Nunito'
                            borderRadius={2} boxShadow='xs'
                            bg="#ECECEC" textColor='#56585c'
                            fontWeight='none'
                            fontSize='14px' h='1.5rem' w='3rem'
                            onClick={() => { props.onClear("all"); }}>
                            Clear
                        </Button> : ""}
                </Flex>

                <CustomSelect errorFlexboxstyle={props.errorFlexboxstyle1} inputStyle={inputStyle} flexboxStyle={flexboxStyle} placeholder='Make' options={props.make} value={props.filter.make?.label} onChange={props.onChange} name={"make"} filter={props.filter} />

                <CustomSelect errorFlexboxstyle={props.errorFlexboxstyle2} inputStyle={inputStyle} flexboxStyle={flexboxStyle} placeholder='Model' options={props.model} value={props.filter.model?.label} onChange={props.onChange} name={"model"} filter={props.filter} />

                <CustomSelect errorFlexboxstyle={props.errorFlexboxstyle3} inputStyle={inputStyle} flexboxStyle={flexboxStyle} placeholder='Year' options={props.year} value={props.filter.year?.label} onChange={props.onChange} name={"year"} filter={props.filter} />

                {props.version && props.version.length != 0 &&
                    <CustomSelect inputStyle={inputStyle} flexboxStyle={flexboxStyle} placeholder='Version' options={props.version} value={props.filter.version?.label} onChange={props.onChange} name={"version"} filter={props.filter} />
                }
                <CustomSelect inputStyle={inputStylecaps} flexboxStyle={flexboxStyle} placeholder='Fuel Type' options={props.fuel} value={props.filter.fuel?.label} onChange={props.onChange} name={"fuel"} filter={props.filter} />

                <CustomSelect inputStyle={inputStyle} flexboxStyle={flexboxStyle} placeholder='Front / Rear' options={[{ "label": "Front", id: "Front" }, { "label": "Rear", id: "Rear" }]} value={props.filter.type?.label} onChange={props.onChange} name={"type"} filter={props.filter} />

            </Box>

            <Box mt={4} p={4} className='productList font_Nunito'>

                <Flex>
                    <Text className='font_NunitoUp' fontSize='18px' >Product</Text>
                    <Spacer />
                    {/* <Button className='font_Nunito' borderRadius={2} boxShadow='xs' bg="#ECECEC" textColor='#56585c' fontWeight='none' fontSize='14px' h='1.5rem' w='3rem'
                        onClick={() => props.onClear("sub")}>
                        Clear
                    </Button> */}
                </Flex>

                <CustomSelect inputStyle={inputStyle} flexboxStyle={flexboxStyle} placeholder='Category' options={props.cat} value={props.filter?.category?.label} onChange={props.onChange} name={"category"} filter={props.filter} />

                <CustomSelect inputStyle={inputStyle} flexboxStyle={flexboxStyle} placeholder='Brand' options={props.brandDts} value={props.filter.brand?.label} onChange={props.onChange} name={"brand"} filter={props.filter} />

            </Box>




        </Box>
    );
}

export default Filterbox;